import data from "./data.json";
export const getCountriesStatesCities = (): any => {
  let CountriesStatesCitiesArr: {}[] = [];
  data.forEach((item: any) => {
    CountriesStatesCitiesArr.push({
      id: item.id,
      name: item.name,
      // region: item.region,
      state: item.states,
    });
  });
  console.log("CountriesStatesCitiesArr", CountriesStatesCitiesArr);

  return CountriesStatesCitiesArr;
};

// export const getStates = (country: string): any => {
//   //  let StatesArr: {}[] = [];
//   data.forEach((item: any) => {
//     if (item.name.toLowerCase() === country.toLowerCase()) {
//       // StatesArr.push({
//       //   state: ,
//       // });
//       console.log("StatesArr", item.states);
//       return item.states;
//     }
//   });
// };
