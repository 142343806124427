import { Line } from "@ant-design/plots";
import { Card } from "antd";

export const MultiLinePlotAnimation = (config: any) => {
  return (
    <Card title={config.title ? config.title : " "} bordered={false}>
      <Line {...config} />
    </Card>
  );
};
