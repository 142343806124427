// @ts-nocheck

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Select, Row, Image } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate, useCustom } from "@refinedev/core";
import { env } from "@env";

const { Option } = Select;

interface SelectLocationProps {
  items: any[];
}

export const SelectLocation: React.FC<SelectLocationProps> = ({ items }) => {
  const dispatch = useDispatch();
  const { locationsReducer, common } = useSelector((state: any) => ({
    ...state,
  }));

  const t = useTranslate();
  console.log({ items });

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isSelectedLocation, setIsSelectedLocation] = useState(false);

  const { data: locationsData, refetch } = useCustom({
    url: `${env.REACT_APP_API_URL}/parks`,
    method: "get",
  });

  console.log("locationsData", locationsData?.data?.data);

  useEffect(() => {
    if (
      locationsData &&
      locationsData?.data &&
      isSelectedLocation === false
      // locationsData !== undefined &&
      // locationsData?.data !== undefined &&
    ) {
      dispatch({
        type: "SET_LOCATIONS",
        payload: locationsData?.data?.data,
      });
      dispatch({
        type: "SELECTED_LOCATION",
        payload: locationsData?.data?.data[0],
      });
      setSelectedLocation(locationsData?.data?.data[0].id);
    }
  }, [locationsData, isSelectedLocation]);

  useEffect(() => {
    refetch();
  }, [locationsReducer?.locations]);

  const handleSelectLocation = (value) => {
    // dispatch({
    //   type: "SELECTED_LOCATION_UUID",
    //   payload: value,
    // });
    dispatch({
      type: "IS_LOADED_DATA",
      payload: true,
    });
    refetch();
    setIsSelectedLocation(true);
    dispatch({
      type: "SELECTED_LOCATION",
      payload: locationsData?.data?.data.filter((x) => x.id === value)[0],
    });
    setSelectedLocation(value);
  };

  return (
    <>
      <>
        {/* <Col span={8}>
          <Image
            src="/images/wind.png"
            preview={false}
            width={100}
            height={50}
          />
        </Col> */}
        {/* <Row>
          <Col span={24}>
            
          </Col>
        </Row> */}
        <h4 style={{ color: "#fff", marginBottom: 20, marginLeft: 65 }}>
          {t("header.selectLocation")}
        </h4>
        <Row style={{ marginTop: -40 }} className="selectLocation">
          <Image
            src={`/images/${
              locationsReducer.selectedLocation !== null &&
              locationsReducer?.selectedLocation?.locationType === "wind"
                ? "wind"
                : "solar"
            }.png`}
            preview={false}
            width={50}
            height={30}
            style={{ marginTop: -35 }}
            className="selectLocationImg"
          />
          <Select
            style={{ width: 250, marginLeft: 10 }}
            placeholder={"Locations"} // "Markets"
            onChange={handleSelectLocation}
            value={selectedLocation !== 0 && selectedLocation}
            className="selectLocationSelection"
            dropdownStyle={{
              backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
            }}
          >
            {items !== undefined &&
              items.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.locationName}
                </Option>
              ))}
          </Select>
        </Row>
      </>
    </>
  );
};
