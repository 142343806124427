import React from "react";
import { createRoot } from "react-dom/client";

import { ReactKeycloakProvider } from "@react-keycloak/web";
import Keycloak from "keycloak-js";

import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { env } from "@env";
import { ConfigProvider } from "antd";

import { createStore } from "redux";
import { Provider, useSelector } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reducer from "./store/reducer";

const store = createStore(reducer, composeWithDevTools());

const keycloak = new Keycloak({
  url: env.REACT_APP_AUTH_URL,
  realm: env.REACT_APP_AUTH_REALM,
  clientId: env.REACT_APP_AUTH_CLIENT_ID,
});

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

console.log("env.REACT_APP_AUTH_URL", env.REACT_APP_AUTH_URL);

const ChangeColors: React.FC<any> = ({ children }) => {
  const { common } = useSelector((state: any) => ({ ...state }));
  // useEffect(() => {
  //   common.isDarkMode
  // }, [])
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgLayout: common.isDarkMode === true ? "#000c17" : "#f9f9f9",
          colorBgContainer: common.isDarkMode === true ? "#001529" : "#fff",
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

root.render(
  <React.Suspense fallback="loading">
    <ReactKeycloakProvider authClient={keycloak}>
      <Provider store={store}>
        <ChangeColors>
          <App />
        </ChangeColors>
      </Provider>
    </ReactKeycloakProvider>
  </React.Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
