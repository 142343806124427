import React, { useState, useEffect } from "react";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, Row, Col } from "antd";
import { IRenewableEnergy } from "@/interfaces/RenewableEnergy";
import { ICompany } from "@/interfaces/company";

import { RENEWABLE_ENERGY } from "../../interfaces/enum/renewableEnergyENUM";
import { EXTERNAL_ID } from "../../interfaces/enum/externalIdENUM";

import { Map } from "../../components/map";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "@refinedev/core";
import { TimeZone } from "../../components/timeZones";
import { Countries } from "../../components/countries";
import { States } from "../../components/states";
import { Cities } from "../../components/cities";

import { getCountriesStatesCities } from "../../Mocks/countries/index";
import { EuroOutlined } from "@ant-design/icons";

export const EditRenewableEnergy: React.FC = () => {
  const t = useTranslate();
  const { formProps, saveButtonProps, formLoading } =
    useForm<IRenewableEnergy>();
  const { common } = useSelector((state: any) => ({ ...state }));
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [years, setYears] = useState([]);
  const dispatch = useDispatch();

  console.log("formProps  edit", formProps);

  useEffect(() => {
    if (formProps?.initialValues !== undefined) {
      setLat(formProps?.initialValues?.address?.lat);
      setLong(formProps?.initialValues?.address?.long);
      dispatch({
        type: "SET_LNGLAT",
        payload: {
          lat: formProps?.initialValues?.address?.lat,
          long: formProps?.initialValues?.address?.long,
        },
      });
    }
  }, [formProps?.initialValues, dispatch]);

  useEffect(() => {
    for (let index = 1; index <= 30; index++) {
      let year = `${index} ${index === 1 ? "Year" : "Years"}`;
      setYears((prev: any): any => {
        return [...prev, year];
      });
    }
  }, []);

  const {
    selectProps: companiesSelectProps,
    queryResult: queryResultCompanies,
  } = useSelect<ICompany>({
    resource: "companies",
    optionValue: "id",
    optionLabel: "name",
  });

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      title={t("renewableEnergy.editRenewableEnergy")}
      isLoading={formLoading}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          /* ToDo: Should not be needed to modify this field before submission... */
          return formProps.onFinish?.({
            ...values,
            company: values.company.id,
            installedCapacity: parseFloat(values.installedCapacity),
            operationalCost: parseFloat(values.operationalCost),
            planetCost: parseFloat(values.planetCost),
            address: {
              street: values.address.street,
              country: values.address.country,
              state: values.address.state,
              city: values.address.city,
              lat,
              long,
            },
          });
        }}
      >
        <Form.Item
          label={t("renewableEnergy.locationName")}
          name="locationName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("renewableEnergy.externalId")}
          name="externalId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            dropdownStyle={{
              backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
            }}
            options={[
              {
                label: EXTERNAL_ID.UP_BRTHNRGNMV_4,
                value: EXTERNAL_ID.UP_BRTHNRGNMV_4,
              },
              {
                label: EXTERNAL_ID.UP_FERRANTI_1,
                value: EXTERNAL_ID.UP_FERRANTI_1,
              },
              {
                label: EXTERNAL_ID.UP_FRANZI_1,
                value: EXTERNAL_ID.UP_FRANZI_1,
              },
              {
                label: EXTERNAL_ID.UP_SANBASILIO_1,
                value: EXTERNAL_ID.UP_SANBASILIO_1,
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label={t("renewableEnergy.locationType")}
          name="locationType"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            dropdownStyle={{
              backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
            }}
            options={[
              {
                label: RENEWABLE_ENERGY.SOLAR,
                value: RENEWABLE_ENERGY.SOLAR,
              },
              {
                label: RENEWABLE_ENERGY.WIND,
                value: RENEWABLE_ENERGY.WIND,
              },
              {
                label: RENEWABLE_ENERGY.HUDRO,
                value: RENEWABLE_ENERGY.HUDRO,
              },
            ]}
          />
        </Form.Item>
        <Map
          isDataArray={false}
          iconURL="/images/mapMarker.png"
          editableLocation={true}
        />
        <Row
          justify={"space-around"}
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={t("renewableEnergy.lat")}
              //  name="lat"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                value={lat}
                onChange={(e) => {
                  setLat(parseFloat(e.target.value));
                }}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              label={t("renewableEnergy.long")}
              // name="long"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                value={long}
                onChange={(e) => {
                  setLong(parseFloat(e.target.value));
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={t("renewableEnergy.street")}
          name={["address", "street"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Row
          justify={"space-around"}
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col span={8} className="gutter-row">
            <Form.Item
              label={t("renewableEnergy.country")}
              name={["address", "country"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Countries
                onChange={(value) => {
                  setCountry(value);
                  console.log("value country", value);
                }}

                // data={getCountriesStatesCities()}
              />
            </Form.Item>
          </Col>
          <Col span={8} className="gutter-row">
            <Form.Item
              label={t("renewableEnergy.state")}
              name={["address", "state"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <States
                onChange={(value) => {
                  //   setStates(getStates(value));
                  setState(value);
                  console.log("value state", value);
                }}
                data={
                  country.length > 0
                    ? getCountriesStatesCities().filter(
                        (x: any) => x.name === country
                      )
                    : []
                }
              />
            </Form.Item>
          </Col>
          <Col span={8} className="gutter-row">
            <Form.Item
              label={t("renewableEnergy.city")}
              name={["address", "city"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Cities
                onChange={(value) => {
                  //   setStates(getStates(value));
                  console.log("value state", value);
                }}
                data={
                  state.length > 0
                    ? getCountriesStatesCities()
                        .filter((x: any) => x.name === country)[0]
                        .state.filter((x: any) => x.name === state)
                    : []
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={t("renewableEnergy.timeZone")}
          name="timeZone"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TimeZone
            onChange={(value) => {
              console.log("timeZone value", value);
            }}
          />
          {/* <Select
            options={[
              {
                label: "Rome/Italy",
                value: "Rome/Italy",
              },
              {
                label: "Cairo/Egypt",
                value: "Cairo/Egypt",
              },
            ]}
          /> */}
        </Form.Item>
        <Row
          justify={"space-around"}
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col span={12} className="gutter-row">
            <Form.Item
              label={t("renewableEnergy.installedCapacity")}
              name="installedCapacity"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={12} className="gutter-row">
            <Form.Item
              label={t("renewableEnergy.unit")}
              name="unit"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                dropdownStyle={{
                  backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
                }}
                options={[
                  {
                    label: "kWp",
                    value: "kWp",
                  },
                  {
                    label: "MWp",
                    value: "MWp",
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row
          justify={"space-around"}
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col span={12} className="gutter-row">
            <Form.Item
              label={t("renewableEnergy.operationalCost")}
              name="operationalCost"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                type="number"
                prefix={<EuroOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
          </Col>
          <Col span={12} className="gutter-row">
            <Form.Item
              label={t("renewableEnergy.planetCost")}
              name="planetCost"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                type="number"
                prefix={<EuroOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
          </Col>
          <Col span={24} className="gutter-row">
            <Form.Item
              label={t("renewableEnergy.yearsOfDepreciation")}
              name="yearsOfDepreciation"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                dropdownStyle={{
                  backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
                }}
                options={years.map((year: string) => ({
                  label: year,
                  value: year,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={24} className="gutter-row">
            <Form.Item
              label={t("renewableEnergy.company")}
              name={["company", "id"]}
            >
              <Select
                dropdownStyle={{
                  backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
                }}
                {...companiesSelectProps}
                options={queryResultCompanies?.data?.data
                  ?.filter((r: any) => r.archived === false)
                  .map((el: any) => ({
                    label: el.name,
                    value: el.id,
                  }))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
