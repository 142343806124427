import { Select } from "antd";
import { useTranslate } from "@refinedev/core";
import { useSelector } from "react-redux";

interface CitiesProps {
  onChange: (value: string) => any;
  value?: string;
  data: any;
}

const { Option } = Select;

export const Cities = ({ onChange, value, data }: CitiesProps) => {
  // const options: SelectProps["options"] = [];
  console.log("city city", data);
  const { common } = useSelector((state: any) => ({ ...state }));

  const t = useTranslate();
  // const onChange = (value: string) => {
  //   console.log(`selected ${value}`);
  // };

  //   const onSearch = (value: string) => {
  //     console.log("search:", value);
  //   };

  //   useEffect(() => {
  //     value = "";
  //   }, [data]);

  return (
    <Select
      showSearch
      value={value}
      placeholder={t("renewableEnergy.city")}
      optionFilterProp="children"
      onChange={onChange}
      dropdownStyle={{
        backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
      }}

      //   onSearch={onSearch}
      //   filterOption={(input, option) =>
      //     (option?.label ?? "")
      //       .toString()
      //       .toLowerCase()
      //       .includes(input.toLowerCase())
      //   }
      //   options={options}
    >
      {data !== undefined &&
        data.map((item: any) => {
          return (
            <>
              {item.cities.map((state: any) => {
                if (
                  state.name
                    .toLowerCase()
                    .includes(value?.toString().toLowerCase())
                ) {
                  return (
                    <Option key={state.id} value={state.name}>
                      {state.name}
                    </Option>
                  );
                } else {
                  return (
                    <Option key={state.id} value={state.name}>
                      {state.name}
                    </Option>
                  );
                }
              })}
            </>
          );
        })}
    </Select>
  );
};
