import { AxiosInstance } from "axios";
import { stringify } from "query-string";
import { DataProvider as DataProviderType } from "@refinedev/core";
import {
  axiosInstance,
  generateSort,
  // generateFilter,
} from "../../providers/data/utils";

import { useKeycloak } from "@react-keycloak/web";
import {
  getAllRenewableEnergy,
  getOneRenewableEnergy,
  addRenewableEnergy,
  deleteRenewableEnergy,
  editRenewableEnergy,
} from "../RenewableEnergy";
import {
  addCompany,
  deleteCompany,
  editCompany,
  getAllCompanies,
  getOneCompany,
} from "../companies";
import {
  addUser,
  deleteUser,
  editUser,
  getAllUsers,
  getOneUser,
} from "../users";
import { getForecastData } from "../forecast";
import { getHistoricalData } from "../historical";
import { getModels } from "../models";
import { getPriceData } from "../EnergyMarketPricingMocks";

const DataProviderMock = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance
): Omit<
  Required<DataProviderType>,
  "createMany" | "updateMany" | "deleteMany"
> => {
  const { keycloak } = useKeycloak();
  console.log("mock mock");
  const setToken = () => {
    const { token } = keycloak;
    console.log("token", token);
    httpClient.defaults.headers.common["Authorization"] = "Bearer " + token;
  };

  return {
    getList: async ({ resource, pagination, filters, sorters }) => {
      //  const url = `${apiUrl}/${resource}`;
      setToken();

      // const { token } = keycloak;

      const { current = 1, pageSize = 10, mode = "server" } = pagination ?? {};

      // const queryFilters = generateFilter(filters);

      const query: {
        _start?: number;
        _end?: number;
        _sort?: string;
        _order?: string;
      } = {};

      if (mode === "server") {
        query._start = (current - 1) * pageSize;
        query._end = current * pageSize;
      }

      const generatedSort = generateSort(sorters);
      if (generatedSort) {
        const { _sort, _order } = generatedSort;
        query._sort = _sort.join(",");
        query._order = _order.join(",");
      }

      // const { data, headers } = await httpClient.get(
      //   `${url}?${stringify(query)}&${stringify(queryFilters)}`,
      //   {
      //     headers: {
      //       Authorization: "Bearer " + token, //the token is a variable which holds the token
      //     },
      //   }
      // );

      //  const total = +headers["x-total-count"];

      console.log("resource", resource);
      switch (resource) {
        case "renewable-energy":
          const data = getAllRenewableEnergy();
          return {
            data: data,
            total: data.length,
          };
        case "settings":
          const companies = getAllCompanies();
          return {
            data: companies,
            total: companies.length,
          };
        case "users":
          const users = getAllUsers();
          return {
            data: users,
            total: users.length,
          };
        default: {
          const data: [] = [];
          return {
            data: data,
            total: data.length,
          };
        }
      }
    },

    getMany: async ({ resource, ids }) => {
      setToken();
      const { data } = await httpClient.get(
        `${apiUrl}/${resource}?${stringify({ id: ids })}`
      );

      return {
        data,
      };
    },

    create: async ({ resource, variables }) => {
      console.log("create data provider", resource, variables);

      switch (resource) {
        case "renewable-energy":
          const add = addRenewableEnergy(variables);
          return {
            data: add,
          };
        case "settings":
          const company = addCompany(variables);
          return {
            data: company,
          };
        case "users":
          const user = addUser(variables);
          return {
            data: user,
          };
        default:
          const data: any = {};
          return { data };
      }
    },

    update: async ({ resource, id, variables }) => {
      console.log("edit data provider", resource, variables);

      switch (resource) {
        case "renewable-energy":
          const edit = editRenewableEnergy(variables, id);
          return {
            data: edit,
          };
        case "settings":
          const company = editCompany(variables, id);
          return {
            data: company,
          };
        case "users":
          const user = editUser(variables, id);
          return {
            data: user,
          };
        default:
          const data: any = {};
          return { data };
      }
    },

    getOne: async ({ resource, id }) => {
      // const url = `${apiUrl}/${resource}/${id}`;
      // setToken();

      // const { data } = await httpClient.get(url);
      switch (resource) {
        case "renewable-energy":
          const get = getOneRenewableEnergy(id);
          return {
            data: get,
          };
        case "settings":
          const getCompany = getOneCompany(id);
          return {
            data: getCompany,
          };
        case "users":
          const user = getOneUser(id);
          return {
            data: user,
          };
        default:
          const data: any = {};
          return { data };
      }
    },

    deleteOne: async ({ resource, id, variables }) => {
      // const url = `${apiUrl}/${resource}/${id}`;
      // setToken();

      // const { data } = await httpClient.delete(url, {
      //   data: variables,
      // });

      switch (resource) {
        case "renewable-energy":
          const remove = deleteRenewableEnergy(id);
          return {
            data: remove,
          };
        case "settings":
          const company = deleteCompany(id);
          return {
            data: company,
          };
        case "users":
          const user = deleteUser(id);
          return {
            data: user,
          };
        default:
          const data: any = {};
          return { data };
      }
    },

    getApiUrl: () => {
      return apiUrl;
    },

    custom: async ({ url, query }: any) => {
      const endPoint = url.split("/");
      console.log("endpoint", endPoint);

      switch (endPoint[5]) {
        case "renewable-energy":
          const getEnergyData = getAllRenewableEnergy();
          return {
            data: getEnergyData,
          };
        case "forecast":
          const getForecast = getForecastData(
            query.dateFrom,
            query.dateTo,
            query.resolution,
            query.planetId,
            query.models,
            query.aiPowerdForecast
          );
          return {
            data: getForecast,
          };
        case "historical":
          const getHistorical = getHistoricalData(
            query.dateFrom,
            query.dateTo,
            query.resolution,
            query.planetId,
            query.models,
            query.aiPowerdForecast
          );
          return {
            data: getHistorical,
          };
        case "mgpPrices":
          const prices = getPriceData(
            query.dateFrom,
            query.dateTo,
            query.resolution
          );
          return { data: prices };
        case "models":
          const getModelsData = getModels();
          return {
            data: getModelsData,
          };
        default:
          const data: any = {};
          return {
            data: data,
          };
      }
    },
  };
};

export default DataProviderMock;
