// @ts-nocheck
import { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Space,
  DatePicker,
  Divider,
  Collapse,
  Select,
  Button,
  TimePicker,
} from "antd";
import { DateTime } from "luxon";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { getMarketData } from "./MarketsNames";

import { useTranslate } from "@refinedev/core";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;

const { Panel } = Collapse;

export const PricesFiltering = () => {
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [timeFrom, setTimeFrom] = useState<string>("");
  const [timeTo, setTimeTo] = useState<string>("");
  const [resolution, setResolution] = useState<string>("");

  const [markets, setMarkets] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState<string[]>(["pun"]);

  const dispatch = useDispatch();
  const t = useTranslate();
  const { common } = useSelector((state: any) => ({
    ...state,
  }));

  useEffect(() => {
    setMarkets(getMarketData());
  }, []);

  useEffect(() => {
    setResolution("daily");

    setDateFrom(
      DateTime.now()
        .startOf("week")
        //  .setZone("Europe/Rome")
        .set({ hour: 0 })
        .toISO()
    );
    setTimeFrom(DateTime.now().toISO()?.slice(11, 16));
    setDateTo(
      DateTime.now()
        .endOf("week")
        //  .setZone("Europe/Rome")
        .set({ hour: 23, minute: 0 })
        .toISO()
    );
    setTimeTo(
      DateTime.now()
        .plus({ hours: 8 })
        //  .setZone("Europe/Rome")
        .toISO()
        ?.slice(11, 16)
    );
    dispatch({
      type: "SET_PRICES_FILTER",
      payload: {
        dateFrom: DateTime.now()
          .startOf("week")
          //  .setZone("Europe/Rome")
          .set({ hour: 0 })
          .setZone("local")
          .toISO(),
        dateTo: DateTime.now()
          .endOf("week")
          //  .setZone("Europe/Rome")
          .set({ hour: 23, minute: 0 })
          .setZone("local")
          .toISO(),
        resolution: "daily",
        markets: ["pun"],
      },
    });
  }, [dispatch]);

  // for default date when open the chart
  const handleDateFrom = (date, dateString) => {
    console.log("dateString", dateString);
    const dateTo = dateString.split("-");
    // const timeToArr = timeFrom.split(":");
    const finalDateTo = DateTime.fromObject(
      { year: dateTo[0], month: dateTo[1], day: dateTo[2] }
      //  { zone: "Europe/Rome" }
    )
      .plus({ days: 7 })
      .toISO();

    setDateFrom(dateString.slice(0, 10));
    setDateTo(finalDateTo.slice(0, 10));
  };

  const handleDateTo = (date, dateString) => {
    setDateTo(dateString.slice(0, 10));
    setTimeFrom(timeFrom.length < 5 ? "00:00" : timeFrom);
    setTimeTo(timeTo.length < 5 ? "23:00" : timeTo);
  };

  const onChangeTimeFrom = (time: Dayjs, timeString: string) => {
    console.log(time, timeString);
    setTimeFrom(timeString);
  };
  const onChangeTimeTo = (time: Dayjs, timeString: string) => {
    console.log(time, timeString);
    setTimeTo(timeString);
  };

  const handleSelectMarkets = (value) => {
    setSelectedMarkets(value);
  };

  const handleResolution = (value) => {
    setResolution(value);
  };

  const handleUpdateFilter = () => {
    dispatch({
      type: "IS_LOADED_DATA",
      payload: true,
    });
    dispatch({
      type: "SET_PRICES_FILTER",
      payload: {
        dateFrom: DateTime.fromISO(
          dateFrom.slice(0, 10) + "T" + timeFrom + ":00.000",
          { zone: "local" }
        ),
        dateTo: DateTime.fromISO(
          dateTo.slice(0, 10) + "T" + timeTo + ":00.000",
          { zone: "local" }
        ),
        resolution,
        markets: selectedMarkets,
      },
    });
  };

  const disabledDate = (current: Dayjs) => {
    return DateTime.now().plus({ days: 7 }).toISO() < current.toISOString();
  };

  return (
    <Collapse defaultActiveKey={["1"]} ghost={true} style={{ marginLeft: -18 }}>
      <Panel header={t("dashboard.filter.title")} key="1">
        <Card>
          <Row justify="start">
            <Col span={4}></Col>
            <Col span={20}>
              <Row>
                <Col span={8}>
                  <div>
                    <h4>{t("dashboard.filter.dateFrom")}</h4>
                  </div>
                  <Space>
                    <DatePicker
                      value={dayjs(dateFrom, "YYYY-MM-DD")}
                      //  disabled={isFixedRange}
                      disabledDate={disabledDate}
                      allowClear={false}
                      onChange={handleDateFrom}
                      format="YYYY-MM-DD"
                      popupStyle={{
                        backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
                      }}
                    />

                    <TimePicker
                      value={dayjs(timeFrom, "HH:mm")}
                      //  disabled={isFixedRange}
                      onChange={onChangeTimeFrom}
                      format={"HH:mm"}
                      popupStyle={{
                        backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
                      }}
                    />
                  </Space>
                </Col>
                <Col span={8}>
                  <div>
                    <h4>{t("dashboard.filter.dateTo")}</h4>
                  </div>
                  <Space>
                    <DatePicker
                      value={dayjs(dateTo, "YYYY-MM-DD")}
                      allowClear={false}
                      onChange={handleDateTo}
                      format="YYYY-MM-DD"
                      popupStyle={{
                        backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
                      }}
                    />
                    <TimePicker
                      value={dayjs(timeTo, "HH:mm")}
                      onChange={onChangeTimeTo}
                      format={"HH:mm"}
                      popupStyle={{
                        backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
                      }}
                    />
                  </Space>
                </Col>
                <Col span={4}>
                  <div>
                    <h4>{t("dashboard.filter.resolution")}</h4>
                  </div>
                  <Space>
                    <Select
                      placeholder={t("dashboard.filter.resolution")}
                      onChange={handleResolution}
                      value={resolution}
                      //  disabled={!showDataRange}
                      style={{ width: 170 }}
                      dropdownStyle={{
                        backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
                      }}
                    >
                      <Option value="hourly">
                        {t("dashboard.filter.hourly")}
                      </Option>
                      <Option value="daily">
                        {t("dashboard.filter.daily")}
                      </Option>
                      <Option value="weekly">
                        {t("dashboard.filter.weekly")}
                      </Option>
                      <Option value="monthly">
                        {t("dashboard.filter.monthly")}
                      </Option>
                      <Option value="yearly">
                        {t("dashboard.filter.yearly")}
                      </Option>
                    </Select>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space>
                    <Button
                      style={{ marginTop: 29, width: 100 }}
                      onClick={handleUpdateFilter}
                    >
                      {t("dashboard.filter.update")}
                    </Button>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={4} offset={4}></Col>
                <Col span={11}>
                  <div>
                    <h4>{t("dashboard.filter.selectMarkets")}</h4>
                  </div>
                  <Space>
                    <Select
                      mode="multiple"
                      placeholder={t("dashboard.filter.selectMarkets")}
                      onChange={handleSelectMarkets}
                      value={selectedMarkets}
                      //  disabled={!showDataRange}
                      style={{ width: 550 }}
                      dropdownStyle={{
                        backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
                      }}
                    >
                      {markets.map((item) => {
                        return (
                          <Option value={item.marketName} key={item.id}>
                            {item.marketName.toUpperCase()}
                          </Option>
                        );
                      })}
                    </Select>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <Divider style={{ margin: "10px 0px" }} />
      </Panel>
    </Collapse>
  );
};
