import React from "react";
import {
  List,
  useTable,
  // EditButton,
  // DeleteButton,
  ShowButton,
} from "@refinedev/antd";

import { Table, Space } from "antd";
import { IUser } from "@/interfaces/user";

import { useTranslate } from "@refinedev/core";

export const UsersList: React.FC = () => {
  const { tableProps } = useTable<IUser>();
  const t = useTranslate();

  return (
    <>
      <List title={t("user.title")}>
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="id" title="ID" />
          <Table.Column dataIndex="firstName" title={t("user.firstName")} />
          <Table.Column dataIndex="lastName" title={t("user.lastName")} />
          <Table.Column
            dataIndex="email"
            title={t("pages.login.fields.email")}
          />
          {/* <Table.Column dataIndex="role" title={t("user.role")} />
          <Table.Column dataIndex="title" title={t("user.postion")} /> */}
          <Table.Column<IUser>
            title={t("user.actions")}
            dataIndex="actions"
            key="actions"
            render={(_, record) => (
              <Space>
                <ShowButton size="small" recordItemId={record?.id} hideText />
                {/* <EditButton hideText size="small" recordItemId={record.id} />
                <DeleteButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  onSuccess={(data) => {
                    refetch();
                  }}
                /> */}
              </Space>
            )}
          />
        </Table>
      </List>
    </>
  );
};
