import { Row, Col, Image } from "antd";
export const ROIShow: React.FC = () => {
  return (
    <>
      <Row justify={"center"} align={"middle"} style={{ height: 600 }}>
        <Col>
          <Image
            src="/images/comingsoon.png"
            preview={false}
            width={400}
            height={400}
          />
        </Col>
      </Row>
    </>
  );
};
