// @ts-nocheck
import DataProvider from "./provider";
import DataProviderMock from "../../Mocks/providers/dataProviderMock";
import { env } from "@env";

const customDataProvider = (apiUrl?: string, httpClient?: any) => {
  if (env.REACT_APP_IS_MOCKED === "true") {
    console.log("mock");
    return DataProviderMock(apiUrl, httpClient);
  } else {
    console.log("not mock");
    return DataProvider(apiUrl, httpClient);
  }
};
export default customDataProvider;
