import { useState, useEffect } from "react";
import { ForeCastFiltering } from "../../../components/dashboard/Filter/forecast";
import { MultiLinePlotAnimation } from "../../../components/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useCustom, useTranslate } from "@refinedev/core";
import { env } from "@env";
import { CardComponent } from "../../../components/card";
import { Image, Row, Col, Skeleton } from "antd";
import { LoadingAndEmpty } from "../../../components/LoadingComponent";
import { UNITS } from "../../../interfaces/enum/unitsENUM";
import { DateTime } from "luxon";

export const ForecastDashboard = () => {
  const [forecast, setForecast] = useState([]);
  const [aiPowerdForecast, setAiPowerdForecast] = useState([]);
  // const [maxNum, setMaxNum] = useState<number>(0);
  const [installedCapacity, setInstalledCapacity] = useState<number>(0);

  const t = useTranslate();
  const { chartFiltering, locationsReducer, units, common } = useSelector(
    (state: any) => ({
      ...state,
    })
  );
  const dispatch = useDispatch();

  const { data: forecastData } = useCustom({
    url: `${env.REACT_APP_API_URL}/forecasted`,
    method: "get",
    config: {
      query: {
        dateFrom:
          chartFiltering.forecastFilter !== null &&
          DateTime.fromISO(chartFiltering.forecastFilter.dateFrom)
            .toUTC()
            .toISO(),
        dateTo:
          chartFiltering.forecastFilter !== null &&
          DateTime.fromISO(chartFiltering.forecastFilter.dateTo)
            .toUTC()
            .toISO(),
        resolution:
          chartFiltering.forecastFilter !== null &&
          chartFiltering.forecastFilter.resolution,
        externalId:
          locationsReducer.selectedLocation !== null &&
          locationsReducer?.selectedLocation?.externalId,
        models:
          chartFiltering.forecastFilter !== null &&
          chartFiltering.forecastFilter.models.toString(),
        // aiPowerdForecast:
        //   chartFiltering.forecastFilter !== null &&
        //   chartFiltering.forecastFilter.isEnabledAI,
      },
    },
    queryOptions: {
      enabled:
        chartFiltering.forecastFilter !== null &&
        locationsReducer.locations.length > 0 &&
        locationsReducer?.selectedLocation !== null,
    },
  });

  // const { data: modelsData } = useCustom({
  //   url: `${env.REACT_APP_API_URL}/api/v1/models`,
  //   method: "get",
  // });

  // console.log("modelsData", modelsData?.data);

  console.log("forecastData", typeof forecastData?.data);

  useEffect(() => {
    if (forecastData?.data.length === 0) {
      setForecast([]);
    } else {
      if (forecastData?.data !== undefined) {
        if (units.selectedUnit === UNITS.MWP) {
          const dataModified = forecastData?.data?.data
            ?.filter((x: any) => x.type !== "aiPowerdForecast")
            .map((data: any) => ({
              id: data.id,
              externalId: data.externalId,
              timestamp: data.timestamp,
              type: data.type,
              value: data.value / 1000,
            }));
          const dataModifiedAi = forecastData?.data?.data
            ?.filter((x: any) => x.type === "aiPowerdForecast")
            .map((data: any) => ({
              id: data.id,
              externalId: data.externalId,
              timestamp: data.timestamp,
              type: data.type,
              value: data.value / 1000,
            }));
          // const max: number = Math.max(
          //   ...forecastData?.data?.data.map((o: any) => o.value / 1000)
          // );
          // setMaxNum(max);
          setForecast(dataModified);
          setAiPowerdForecast(dataModifiedAi);
          dispatch({
            type: "IS_LOADED_DATA",
            payload: false,
          });
        } else {
          const dataModified = forecastData?.data?.data
            ?.filter((x: any) => x.type !== "aiPowerdForecast")
            .map((data: any) => ({
              id: data.id,
              externalId: data.externalId,
              timestamp: data.timestamp,
              type: data.type,
              value: data.value,
            }));
          // const max: number = Math.max(
          //   ...forecastData?.data?.data.map((o: any) => o.value)
          // );
          // setMaxNum(max);
          //   console.log("max number", max);

          setForecast(dataModified);

          const dataModifiedAi = forecastData?.data?.data
            ?.filter((x: any) => x.type === "aiPowerdForecast")
            .map((data: any) => ({
              id: data.id,
              externalId: data.externalId,
              timestamp: data.timestamp,
              type: data.type,
              value: data.value,
            }));
          setAiPowerdForecast(dataModifiedAi);
          dispatch({
            type: "IS_LOADED_DATA",
            payload: false,
          });
        }
        if (forecast.length !== 0) {
          dispatch({
            type: "IS_LOADED_DATA",
            payload: false,
          });
        }
      } else {
        setForecast([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastData?.data, units.selectedUnit]);

  useEffect(() => {
    if (chartFiltering.forecastFilter !== null) {
      if (chartFiltering.forecastFilter.resolution === "3h") {
        setInstalledCapacity(
          locationsReducer?.selectedLocation?.installedCapacity * 3
        );
      } else if (chartFiltering.forecastFilter.resolution === "8h") {
        setInstalledCapacity(
          locationsReducer?.selectedLocation?.installedCapacity * 8
        );
      } else {
        setInstalledCapacity(
          locationsReducer?.selectedLocation?.installedCapacity
        );
      }
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    chartFiltering.forecastFilter !== null &&
      chartFiltering.forecastFilter.resolution,
  ]);
  // const asyncFetch = () => {
  //   fetch(
  //     "https://gw.alipayobjects.com/os/bmw-prod/e00d52f4-2fa6-47ee-a0d7-105dd95bde20.json"
  //   )
  //     .then((response) => response.json())
  //     .then((json) => setData(json))
  //     .catch((error) => {
  //       console.log("fetch data failed", error);
  //     });
  // };
  const config = {
    data: !chartFiltering.isEnabledAI ? forecast : aiPowerdForecast,
    xField: "timestamp",
    yField: "value",
    seriesField: "type",
    yAxis: {
      label: {
        formatter: (v: any) => `${(v / 1).toFixed(1)} ${units.selectedUnit}`,
      },
      max:
        units.selectedUnit === UNITS.KWP
          ? installedCapacity
          : installedCapacity / 1000,
    },
    legend: {
      position: "top",
      itemName: {
        formatter: (text: any) => {
          const splitedLable = text.split("Power");
          return (
            splitedLable[0].charAt(0).toUpperCase() +
            splitedLable[0].slice(1) +
            " Power " +
            splitedLable[1]
          );
        },
      },
    },
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
    annotations: [
      {
        type: "line",
        start: [
          chartFiltering.isEnabledInstalledCapacityForecast && "max",
          chartFiltering.isEnabledInstalledCapacityForecast &&
            (units.selectedUnit === UNITS.KWP
              ? installedCapacity
              : installedCapacity / 1000),
        ],
        end: [
          chartFiltering.isEnabledInstalledCapacityForecast && "min",
          chartFiltering.isEnabledInstalledCapacityForecast &&
            (units.selectedUnit === UNITS.KWP
              ? installedCapacity
              : installedCapacity / 1000),
        ],

        style: {
          stroke: "#F4664A",
          lineWidth: 4,
          lineDash: [2, 2],
        },
      },
    ],
    slider: {
      start: 0,
      end: 1,
      textStyle: {
        fill: "white",
      },
    },
  };
  return (
    <>
      {forecastData !== undefined ? (
        <>
          <CardComponent style={{ width: 600, marginBottom: 10 }}>
            {locationsReducer.selectedLocation !== null && (
              <Row justify={"start"}>
                <Col>
                  <Image
                    src={`/images/${
                      locationsReducer?.selectedLocation?.locationType ===
                      "wind"
                        ? "wind"
                        : "solar"
                    }.png`}
                    preview={false}
                    width={150}
                    height={100}
                  />
                </Col>
                <Col style={{ marginLeft: 20 }}>
                  <Row>
                    <h4>
                      {t("renewableEnergy.timeZone")}:{" "}
                      <span style={{ fontSize: 12 }}>
                        {locationsReducer?.selectedLocation?.timeZone}
                      </span>{" "}
                    </h4>
                  </Row>
                  <Row>
                    <h4>
                      {t("renewableEnergy.address")}:{" "}
                      <span style={{ fontSize: 12 }}>
                        {" "}
                        {`${locationsReducer?.selectedLocation?.address.street}, ${locationsReducer?.selectedLocation?.address.city}, ${locationsReducer?.selectedLocation?.address.state}, ${locationsReducer?.selectedLocation?.address.country}`}
                      </span>
                    </h4>
                  </Row>
                  <Row>
                    <h4>
                      {t("renewableEnergy.installedCapacity")}:{" "}
                      <span>{`${
                        units.selectedUnit === UNITS.KWP
                          ? locationsReducer?.selectedLocation
                              ?.installedCapacity
                          : locationsReducer?.selectedLocation
                              ?.installedCapacity / 1000
                      } `}</span>
                      {units.selectedUnit}
                    </h4>
                  </Row>
                </Col>
              </Row>
            )}
          </CardComponent>
          <ForeCastFiltering />
          <LoadingAndEmpty
            loading={common.isLoaded}
            bordered={false}
            data={forecast}
            title={t("sider.forecast.title")}
          >
            <MultiLinePlotAnimation {...config} />
          </LoadingAndEmpty>
        </>
      ) : (
        <>
          <ForeCastFiltering />
          <Skeleton />
        </>
      )}
    </>
  );
};
