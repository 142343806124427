import { combineReducers } from "redux";
import {
  chartFilteringReducer,
  commonReducer,
  locationsReducer,
  mapReducer,
  unitsReducer,
} from "../reduxReducers";
const reducer = combineReducers({
  common: commonReducer,
  chartFiltering: chartFilteringReducer,
  locationsReducer,
  map: mapReducer,
  units: unitsReducer,
});

export default reducer;
