import { useEffect, useState } from "react";
import { Card, Row, Col } from "antd";
import {
  MultiLinePlotAnimation,
  WindChart,
  ChartFiltering,
} from "../../../components/dashboard";
import { useCustom, useTranslate } from "@refinedev/core";
import { env } from "@env";
// import { windSources, windRoseData } from './data'
import { Kpi } from "../../../components";
import { RESOLUTION } from "../../../interfaces/enum/resolutionENUM";
import { useSelector } from "react-redux";

export const ProductionDashboardShow: React.FC = () => {
  const [windRoseData, setWindRoseData] = useState([]);
  const [windSources, setWindSources] = useState([]);
  const t = useTranslate();

  const { locationsReducer } = useSelector((state: any) => ({ ...state }));

  const { data: overview } = useCustom({
    url: `${env.REACT_APP_API_URL}/api/v1/dashboards/${
      locationsReducer.selectedLocation !== null &&
      locationsReducer.selectedLocation.id
    }/energyProduction`,
    method: "get",
    config: {
      query: {
        dateFrom: "2023/04/10 20:00",
        dateTo: "2023/04/10 20:00",
        resolution: RESOLUTION.DAILY,
        best_model: "True",
      },
    },
    queryOptions: {
      enabled:
        locationsReducer.selectedLocation !== null &&
        locationsReducer.selectedLocation.id !== undefined,
    },
  });

  const { data: wind } = useCustom({
    url: `${env.REACT_APP_API_URL}/api/v1/dashboards/${
      locationsReducer.selectedLocation !== null &&
      locationsReducer.selectedLocation.id
    }/wind`,
    method: "get",
    config: {
      query: {
        dateFrom: "2023/04/10 20:00",
      },
    },
    queryOptions: {
      enabled:
        locationsReducer.selectedLocation !== null &&
        locationsReducer.selectedLocation.id !== undefined,
    },
  });

  console.log("wind 1", wind?.data?.data.windData.windRoseData);

  useEffect(() => {
    if (wind?.data !== undefined) {
      setWindRoseData(wind?.data?.data.windData.windRoseData);
      setWindSources(wind?.data?.data.windData.windSources);
    }
  }, [wind]);

  const config = {
    data:
      overview?.data?.data.productionData === undefined
        ? []
        : overview?.data?.data.productionData,
    xField: "timestamp",
    yField: "value",
    seriesField: "type",
    // title:"Production",
    // yAxis: 'marketPrice',
    yAxis: {
      label: {
        formatter: (v: any) => `${(v / 1).toFixed(1)} MWh`,
      },
    },
    legend: {
      position: "top",
      itemName: {
        style: {
          spacing: 2000,
          fill: "#1ec6ff",
        },
      },
    },
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
    slider: {
      start: 0,
      end: 1,
      textStyle: {
        fill: "white",
      },
    },
  };

  return (
    <>
      <Row gutter={16} style={{ marginBottom: 10 }}>
        <Col span={24}>
          <ChartFiltering />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 10 }}>
        <Col span={24}>
          <Card
            title={
              <div>
                <h3 style={{ marginTop: 5 }}>
                  {t("dashboard.production.production")}
                </h3>
                <Row gutter={16} style={{ margin: 10 }}>
                  <Col span={8}>
                    <Card>
                      <Kpi
                        title={t("dashboard.production.power")}
                        value={overview?.data?.data.kpis.power}
                        precision={3}
                        // prefix={<ArrowUpOutlined />}
                        suffix="kW"
                      />
                    </Card>
                  </Col>
                </Row>
              </div>
            }
          >
            <MultiLinePlotAnimation {...config} />
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 10 }}>
        <Col span={24}>
          <Card title={t("dashboard.production.wind")}>
            <WindChart windRoseData={windRoseData} windSources={windSources} />
          </Card>
        </Col>
      </Row>
    </>
  );
};
