import React from "react";
import {
  List,
  useTable,
  EditButton,
  DeleteButton,
  ShowButton,
} from "@refinedev/antd";

import { Table, Space } from "antd";
import { ICompany } from "@/interfaces/company";

import { useTranslate } from "@refinedev/core";

export const CompaniesList: React.FC = () => {
  const {
    tableProps,
    tableQueryResult: { refetch },
  } = useTable<ICompany>();
  const t = useTranslate();

  return (
    <>
      <List>
        <Table rowKey="id" {...tableProps}>
          <Table.Column dataIndex="id" title="ID" />
          <Table.Column dataIndex="name" title={t("company.name")} />
          <Table.ColumnGroup title={t("company.address")}>
            <Table.Column
              title={t("company.city")}
              dataIndex={["address", "city"]}
              key="city"
            />
            <Table.Column
              title={t("company.state")}
              dataIndex={["address", "state"]}
              key="state"
            />
            <Table.Column
              title={t("company.country")}
              dataIndex={["address", "country"]}
              key="country"
            />
          </Table.ColumnGroup>

          <Table.Column<ICompany>
            title={t("company.actions")}
            dataIndex="actions"
            key="actions"
            render={(_, record) => (
              <Space>
                <ShowButton size="small" recordItemId={record?.id} hideText />
                <EditButton hideText size="small" recordItemId={record.id} />
                <DeleteButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  onSuccess={(data) => {
                    refetch();
                  }}
                />
              </Space>
            )}
          />
        </Table>
      </List>
    </>
  );
};
