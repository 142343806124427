import { useState, useEffect } from "react";
import { useCustom, useTranslate } from "@refinedev/core";
import { Row, Col, Button, Skeleton } from "antd";
import { env } from "@env";
import {
  GroupedColumnPlot,
  MultiLinePlotAnimation,
} from "../../../../components";
// import { HeatMap } from "../../../../components/dashboard";
import { PricesFiltering } from "../../../../components/dashboard/Filter/prices";
// import { HeatMapFiltering } from "../../../../components/dashboard/Filter/heatMap";

import { LoadingAndEmpty } from "../../../../components/LoadingComponent";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";

export const EnergyPricesShow: React.FC = () => {
  const { common, chartFiltering } = useSelector((state: any) => ({
    ...state,
  }));
  const t = useTranslate();
  const [isColumns, setIsColumns] = useState(true);
  // const [slotTimeData, setSlotTimeData] = useState([]);
  const dispatch = useDispatch();

  const { data } = useCustom({
    url: `${env.REACT_APP_API_URL}/electricitymarket/mgpPrices`,
    method: "get",
    config: {
      query: {
        dateFrom:
          chartFiltering.pricesFilter !== null &&
          DateTime.fromISO(chartFiltering.pricesFilter.dateFrom)
            .toUTC()
            .toISO(),
        dateTo:
          chartFiltering.pricesFilter !== null &&
          DateTime.fromISO(chartFiltering.pricesFilter.dateTo).toUTC().toISO(),
        resolution:
          chartFiltering.pricesFilter !== null &&
          chartFiltering.pricesFilter.resolution,
        markets:
          chartFiltering.pricesFilter !== null &&
          chartFiltering.pricesFilter.markets.toString(),
      },
    },
    queryOptions: {
      enabled: chartFiltering.pricesFilter !== null,
    },
  });

  useEffect(() => {
    if (data?.data !== undefined) {
      if (data?.data.data !== 0) {
        dispatch({
          type: "IS_LOADED_DATA",
          payload: false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data]);

  console.log("prices data", data?.data);
  const config = {
    data:
      data?.data.data === undefined
        ? []
        : data.data.data[0]?.marketPrice === null
        ? []
        : data.data.data,
    isGroup: isColumns ? true : false,
    xField: "date",
    yField: "marketPrice",
    seriesField: "marketName",
    tooltip: {
      customContent: (title: string, data: any[]) => {
        console.log("tooltip data", data);
        return (
          <div style={{ padding: "16px 8px", color: "#000", width: "200px" }}>
            <p> {title} </p>
            {data.map((item: any) => {
              return (
                <Row justify="start">
                  <Col span={2}>
                    <span
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: `${item?.mappingData?.color}`,
                        display: "inline-block",
                      }}
                    ></span>
                  </Col>
                  <Col span={11}>
                    <p>{item.name}:</p>
                  </Col>
                  <Col span={8}>
                    <p>{item.value.slice(0, 7)}</p>
                  </Col>
                  <Col span={3}>
                    <p>€</p>
                  </Col>
                </Row>
              );
            })}
          </div>
        );
      },
    },
    yAxis: {
      label: {
        formatter: (v: any) => `${(v / 1).toFixed(1)} €/MWh`,
      },
    },
    legend: {
      position: "top",
      itemName: {
        style: {
          spacing: 2000,
          fill: "#1ec6ff",
        },
      },
    },
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
    slider: {
      start: 0,
      end: 1,
      textStyle: {
        fill: "white",
      },
    },
  };

  // const { data: timeSlot, isLoading: isLoadTimeSlot } = useCustom({
  //   url: `${env.REACT_APP_API_URL}/electricitymarket/mgpPrices`,
  //   method: "get",
  //   config: {
  //     query: {
  //       dateFrom:
  //         chartFiltering.heatMapFilter !== null &&
  //         chartFiltering.heatMapFilter.dateFrom,
  //       dateTo:
  //         chartFiltering.heatMapFilter !== null &&
  //         chartFiltering.heatMapFilter.dateTo,
  //       resolution:
  //         chartFiltering.heatMapFilter !== null &&
  //         chartFiltering.heatMapFilter.resolution,
  //       markets:
  //         chartFiltering.heatMapFilter !== null &&
  //         chartFiltering.heatMapFilter.markets.toString(),
  //     },
  //   },
  // });
  // let newMarketPriceArray: any = [];
  // useEffect(() => {
  //   if (timeSlot?.data !== undefined) {
  //     const weekday = [
  //       "Sunday",
  //       "Monday",
  //       "Tuesday",
  //       "Wednesday",
  //       "Thursday",
  //       "Friday",
  //       "Saturday",
  //     ];
  //     timeSlot?.data?.data.map((slot: any) => {
  //       newMarketPriceArray.push({
  //         marketName: slot.marketName,
  //         marketPrice: slot.marketPrice,
  //         day: weekday[new Date(slot.date.slice(0, 10)).getDay()],
  //         date: slot.date.slice(0, 10),
  //         time: slot.date.slice(11, 16),
  //         timeSlot: slot.timeSlot,
  //       });
  //     });
  //     setSlotTimeData(newMarketPriceArray);
  //   }
  // }, [timeSlot?.data]);

  // const configHeatMap = {
  //   height: 500,
  //   autoFit: true,
  //   // data: timeSlot?.data === undefined ? [] : timeSlot?.data[0]?.marketPrice === null ? [] : timeSlot?.data,
  //   data: slotTimeData,
  //   seriesField: "marketName",
  //   xField: "time",
  //   yField: "date",
  //   colorField: "timeSlot",
  //   reflect: "y",
  //   color: ["#044A42", "#F6C90E", "#990000"],
  //   meta: {
  //     marketPrice: {
  //       type: "cat",
  //     },
  //   },
  //   tooltip: {
  //     fields: ["timeSlot", "marketPrice", "day", "date"],
  //   },
  //   legend: {
  //     layout: "horizontal",
  //     position: "bottom",
  //   },
  // };

  return (
    <>
      {data !== undefined ? (
        <>
          <PricesFiltering />
          <LoadingAndEmpty
            title={
              <Button
                onClick={() => {
                  setIsColumns(!isColumns);
                }}
              >
                {isColumns
                  ? t("renewableEnergy.lines")
                  : t("renewableEnergy.columns")}
              </Button>
            }
            loading={common.isLoaded}
            bordered={false}
            data={data?.data === undefined ? [] : data?.data.data}
          >
            {isColumns ? (
              <GroupedColumnPlot {...config} />
            ) : (
              <MultiLinePlotAnimation {...config} />
            )}
          </LoadingAndEmpty>
          {/* <HeatMapFiltering />
      <LoadingAndEmpty
        bordered={true}
        loading={isLoadTimeSlot}
        data={slotTimeData}
        style={{
          marginBottom: 30,
        }}
      >
        <HeatMap {...configHeatMap} />
      </LoadingAndEmpty> */}
        </>
      ) : (
        <>
          <PricesFiltering />
          <Skeleton />
        </>
      )}
    </>
  );
};
