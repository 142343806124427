import React from "react";
import { Show } from "@refinedev/antd";
import { Typography, Row, Col, Space } from "antd";
import { useShow } from "@refinedev/core";
import { IUser } from "@/interfaces/user";

import { useTranslate } from "@refinedev/core";

const { Title, Text } = Typography;

export const ShowUser: React.FC = () => {
  const { queryResult } = useShow<IUser>();

  const { data, isLoading } = queryResult;
  const record = data?.data;
  const t = useTranslate();

  return (
    <Show isLoading={isLoading} canEdit={false} canDelete={false}>
      <Row>
        <Col>
          <Space align="baseline">
            <Title level={5}>Id: </Title>
            <Text>{record?.id}</Text>
          </Space>
        </Col>
      </Row>

      <Row>
        <Col span={5}>
          <Space align="baseline">
            <Title level={5}>{t("user.firstName")}: </Title>
            <Text>{record?.firstName}</Text>
          </Space>
        </Col>
        <Col span={5}>
          <Space align="baseline">
            <Title level={5}>{t("user.lastName")}: </Title>
            <Text>{record?.lastName}</Text>
          </Space>
        </Col>
        <Col span={5}>
          <Space align="baseline">
            <Title level={5}>{t("pages.login.fields.email")}: </Title>
            <Text>{record?.email}</Text>
          </Space>
        </Col>
        <Col span={5}>
          <Space align="baseline">
            <Title level={5}>{t("user.role")}: </Title>
            <Text>{record?.attributes.role[0]}</Text>
          </Space>
        </Col>
        <Col span={5}>
          <Space align="baseline">
            <Title level={5}>{t("user.postion")}: </Title>
            <Text>{record?.attributes.title[0]}</Text>
          </Space>
        </Col>
      </Row>
    </Show>
  );
};
