import { DownOutlined } from "@ant-design/icons";
import { useGetIdentity, useGetLocale, useSetLocale } from "@refinedev/core";
import {
  Layout as AntdLayout,
  Avatar,
  Button,
  Dropdown,
  Menu,
  Space,
  Switch,
  Typography,
} from "antd";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ColorModeContext } from "../../contexts/color-mode";
import { SelectLocation } from "../selectLocation";
import { SelectUnit } from "../selectUnit";
import "./style.css";
const { Text } = Typography;

type IUser = {
  id: number;
  name: string;
  avatar: string;
};

export const Header: React.FC = () => {
  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const { data: user } = useGetIdentity<IUser>();
  const { mode, setMode } = useContext(ColorModeContext);
  const { locationsReducer, common } = useSelector((state: any) => ({
    ...state,
  }));
  const currentLocale = locale();

  const menu = (
    <Menu
      selectedKeys={currentLocale ? [currentLocale] : []}
      style={{ backgroundColor: common.isDarkMode ? "#000c17" : "#fff" }}
    >
      {[...(i18n.languages || [])].sort().map((lang: string) => (
        <Menu.Item
          key={lang}
          onClick={() => changeLanguage(lang)}
          icon={
            <span style={{ marginRight: 8 }}>
              <Avatar size={16} src={`/images/flags/${lang}.svg`} />
            </span>
          }
        >
          {lang === "en" ? "English" : "Italian"}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <AntdLayout.Header
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "0px 24px",
        height: "100px",
      }}
    >
      <Space style={{ width: "60%", justifyContent: "space-between" }}>
        <SelectLocation items={locationsReducer.locations} />
        <SelectUnit />
      </Space>
      {/* <Space style={{ width: "30%", justifyContent: "space-between" }}>
        
      </Space> */}
      <Space style={{ marginLeft: "40%" }}>
        <Dropdown overlay={menu}>
          <Button type="link">
            <Space>
              <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />
              {currentLocale === "en" ? "English" : "Italian"}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
        <Switch
          checkedChildren="🌛"
          unCheckedChildren="🔆"
          onChange={() => setMode(mode === "light" ? "dark" : "light")}
          defaultChecked={mode === "dark"}
        />
        <Space style={{ marginLeft: "8px" }}>
          {user?.name && (
            <Text style={{ color: "white" }} strong>
              {user.name}
            </Text>
          )}
          {user?.avatar && <Avatar src={user?.avatar} alt={user?.name} />}
        </Space>
      </Space>
    </AntdLayout.Header>
  );
};
