import { Card, Row, Image } from "antd";
import { useTranslate } from "@refinedev/core";
interface LoadingAndEmptyProps {
  title?: any;
  loading?: boolean;
  className?: string;
  bordered?: boolean;
  style?: {};
  data?: any[];
  children: any;
  locationMessage?: any;
  isLocationMessage?: boolean;
}

export const LoadingAndEmpty = (props: LoadingAndEmptyProps) => {
  const t = useTranslate();

  return (
    <>
      <Card
        title={props.title}
        // id={isDarkMode ? "darkCard" : "lightCard"}
        loading={props.loading}
        className={props.className}
        bordered={props.bordered}
        style={props.style}
      >
        {props.loading === false && props.data?.length !== 0 ? (
          <>{props.children}</>
        ) : (
          <>
            <Row justify="center">
              <Image
                src="/images/nodata.png"
                alt="no data"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "20vw",
                  height: "12vw",
                }}
                preview={false}
              />
            </Row>
            {props.isLocationMessage === true ? (
              <>{props.locationMessage}</>
            ) : (
              <>
                <Row justify="center">
                  <h1>{t("loadingAndEmpty.title")}</h1>
                </Row>
                <Row justify="center">
                  <h5>{t("loadingAndEmpty.content")}</h5>
                </Row>
              </>
            )}
          </>
        )}
      </Card>
    </>
  );
};
