import data from './Data/markets.json'

export interface MarketData {
  id: string
  marketName: string
}

export const getMarketData = (): MarketData[] => {
  const marketDataArray: MarketData[] = []

  for (const item of data) {
    marketDataArray.push({
      id: item.id,
      marketName: item.marketName,
    })
  }

  return marketDataArray
}
