import React, { useState } from "react";

import { Edit, useForm } from "@refinedev/antd";
import { Col, Form, Input, Row } from "antd";
import { ICompany } from "@/interfaces/company";
import { useTranslate } from "@refinedev/core";
import { getCountriesStatesCities } from "../../../Mocks/countries/index";
import { Countries } from "../../../components/countries";
import { States } from "../../../components/states";
import { Cities } from "../../../components/cities";

export const EditCompany: React.FC = () => {
  const { formProps, saveButtonProps } = useForm<ICompany>();
  const t = useTranslate();
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  return (
    <Edit saveButtonProps={saveButtonProps} title={t("company.edit")}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          return formProps.onFinish?.({
            ...values,
            totalParks: 4,
            totalUsers: 2,
          });
        }}
      >
        <Form.Item
          label={t("company.name")}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        {/* <Form.Item
          label={t("company.totalParks")}
          name="totalParks"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label={t("company.totalUsers")}
          name="totalUsers"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="number" />
        </Form.Item> */}
        <Row
          justify={"space-around"}
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col span={8} className="gutter-row">
            <Form.Item
              label={t("renewableEnergy.country")}
              name={["address", "country"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Countries
                onChange={(value) => {
                  setCountry(value);
                  console.log("value country", value);
                }}

                // data={getCountriesStatesCities()}
              />
            </Form.Item>
          </Col>
          <Col span={8} className="gutter-row">
            <Form.Item
              label={t("renewableEnergy.state")}
              name={["address", "state"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <States
                onChange={(value) => {
                  //   setStates(getStates(value));
                  setState(value);
                  console.log("value state", value);
                }}
                data={
                  country.length > 0
                    ? getCountriesStatesCities().filter(
                        (x: any) => x.name === country
                      )
                    : []
                }
              />
            </Form.Item>
          </Col>
          <Col span={8} className="gutter-row">
            <Form.Item
              label={t("renewableEnergy.city")}
              name={["address", "city"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Cities
                onChange={(value) => {
                  //   setStates(getStates(value));
                  console.log("value state", value);
                }}
                data={
                  state.length > 0
                    ? getCountriesStatesCities()
                        .filter((x: any) => x.name === country)[0]
                        .state.filter((x: any) => x.name === state)
                    : []
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
