const initial = {
  locations: [],
  //selectedLocationUuid: null,
  selectedLocation: null,
};

export const locationsReducer = (state = initial, action: any) => {
  switch (action.type) {
    case "SET_LOCATIONS":
      return { ...state, locations: action.payload };
    // case "SELECTED_LOCATION_UUID":
    //   return { ...state, selectedLocationUuid: action.payload };
    case "SELECTED_LOCATION":
      return { ...state, selectedLocation: action.payload };
    default:
      return state;
  }
};
