const initial = {
  selectedUnit: null,
};

export const unitsReducer = (state = initial, action: any) => {
  switch (action.type) {
    case "SELECTED_UNIT":
      return { ...state, selectedUnit: action.payload };
    default:
      return state;
  }
};
