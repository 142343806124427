import React, { useState, useEffect } from "react";
import {
  Show,
  // useTable,
  ShowButton,
  EditButton,
  useSelect,
} from "@refinedev/antd";
import {
  Typography,
  Row,
  Col,
  Space,
  Tabs,
  Table,
  Modal,
  Select,
  Button,
} from "antd";
import { useShow, useNavigation, useCustom } from "@refinedev/core";
import { ICompany } from "@/interfaces/company";
// import { IRenewableEnergy } from "@/interfaces/RenewableEnergy";
import { IUser } from "@/interfaces/user";
import { RENEWABLE_ENERGY } from "../../../interfaces/enum/renewableEnergyENUM";
import { UNITS } from "../../../interfaces/enum/unitsENUM";
import { env } from "@env";

import { useSelector } from "react-redux";

import { useTranslate } from "@refinedev/core";
import type { ColumnsType } from "antd/es/table";

const { Title, Text } = Typography;
const { TabPane } = Tabs;

export const ShowCompany: React.FC = () => {
  const { queryResult } = useShow<ICompany>();
  const { units, common } = useSelector((state: any) => ({ ...state }));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>("");
  const [isSelectedUser, setIsSelectedUser] = useState(false);

  // const { tableProps: parksData } = useTable<IRenewableEnergy>({
  //   resource: "renewable-energy",
  // });
  // const { tableProps: usersData } = useTable<IUser>({
  //   resource: "users",
  // });
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const t = useTranslate();
  const { show, edit } = useNavigation();
  const [parks, setParks] = useState<any>([]);

  console.log("record ..", data?.data);

  const { selectProps: usersSelectProps, queryResult: queryResultUsers } =
    useSelect<IUser>({
      resource: "users",
      optionValue: "id",
      optionLabel: "email",
    });

  useEffect(() => {
    if (record !== undefined) {
      if (units.selectedUnit === UNITS.MWP) {
        const dataModified = record?.parks?.map((data: any) => ({
          id: data.id,
          locationName: data.locationName,
          locationType: data.locationType,
          timeZone: data.timeZone,
          installedCapacity:
            units.selectedUnit === UNITS.KWP
              ? data.installedCapacity
              : data.installedCapacity / 1000,
          unit: units.selectedUnit,
          planetCost: data.planetCost,
          operationalCost: data.operationalCost,
          yearsOfDepreciation: data.yearsOfDepreciation,
        }));
        setParks(dataModified);
      } else {
        setParks(record?.parks);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record?.parks, units.selectedUnit]);

  const {
    data: users,
    refetch,
    isLoading: isCompaniesLoading,
    isFetched,
  } = useCustom({
    url: `${env.REACT_APP_API_URL}/companies/${record?.id}/users`,
    method: "get",
  });

  console.log("users data", users?.data);

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("renewableEnergy.locationName"),
      dataIndex: "locationName",
      key: "locationName",
    },
    {
      title: t("renewableEnergy.locationType"),
      dataIndex: "locationType",
      key: "locationType",
      filters: [
        {
          text: RENEWABLE_ENERGY.HUDRO,
          value: RENEWABLE_ENERGY.HUDRO,
        },
        {
          text: RENEWABLE_ENERGY.SOLAR,
          value: RENEWABLE_ENERGY.SOLAR,
        },
        {
          text: RENEWABLE_ENERGY.WIND,
          value: RENEWABLE_ENERGY.WIND,
        },
      ],
      onFilter: (value: string | number | boolean, record: any) =>
        record?.locationType.indexOf(value) === 0,
      // onFilter: (value: string, record: any): any =>
      //   // record.name.indexOf(value) === 0,
      //   console.log("filter", record, value),
    },
    {
      title: t("renewableEnergy.timeZone"),
      dataIndex: "timeZone",
      key: "timeZone",
    },
    {
      title: t("renewableEnergy.installedCapacity"),
      dataIndex: "installedCapacity",
      key: "installedCapacity",
    },
    {
      title: t("renewableEnergy.unit"),
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: t("renewableEnergy.planetCost") + " €",
      dataIndex: "planetCost",
      key: "planetCost",
    },
    {
      title: t("renewableEnergy.operationalCost") + " €",
      dataIndex: "operationalCost",
      key: "operationalCost",
    },
    {
      title: t("renewableEnergy.yearsOfDepreciation"),
      dataIndex: "yearsOfDepreciation",
      key: "yearsOfDepreciation",
    },
    {
      title: t("renewableEnergy.actions"),
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <ShowButton
            size="small"
            recordItemId={record?.id}
            hideText
            onClick={() => {
              show("parks", record.id);
            }}
          />
          <EditButton
            hideText
            size="small"
            recordItemId={record.id}
            onClick={() => {
              edit("parks", record.id);
            }}
          />
        </Space>
      ),
    },
  ];

  const { data: addUserToCompany } = useCustom({
    url: `${env.REACT_APP_API_URL}/companies/${record?.id}/users/${selectedUser}`,
    method: "post",
    successNotification(data, values, resource): any {
      console.log("addUserToCompany", addUserToCompany);

      refetch();
    },
    queryOptions: {
      enabled: selectedUser.length > 0 && isSelectedUser === true,
    },
  });

  const showModal = () => {
    setIsModalOpen(true);
    setIsSelectedUser(false);
    setSelectedUser("");
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsSelectedUser(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  console.log("is loading", isCompaniesLoading, isFetched);

  return (
    <>
      <Show isLoading={isLoading} title={record?.name}>
        <Row>
          <Col>
            <Space align="baseline">
              <Title level={5}>Id: </Title>
              <Text>{record?.id}</Text>
            </Space>
          </Col>
        </Row>

        <Row>
          {/* <Col span={4}>
            <Space align="baseline">
              <Title level={5}>{t("company.totalParks")}: </Title>
              <Text>{record?.totalParks}</Text>
            </Space>
          </Col> */}
          {/* <Col span={4}>
            <Space align="baseline">
              <Title level={5}>{t("company.totalUsers")}: </Title>
              <Text>{record?.totalUsers}</Text>
            </Space>
          </Col> */}
          <Col span={5}>
            <Space align="baseline">
              <Title level={5}>{t("company.country")}: </Title>
              <Text>{record?.address?.country}</Text>
            </Space>
          </Col>
          <Col span={5}>
            <Space align="baseline">
              <Title level={5}>{t("company.state")}: </Title>
              <Text>{record?.address?.state}</Text>
            </Space>
          </Col>
          <Col span={5}>
            <Space align="baseline">
              <Title level={5}>{t("company.city")}: </Title>
              <Text>{record?.address?.city}</Text>
            </Space>
          </Col>
        </Row>
      </Show>
      <Tabs tabPosition="top">
        <TabPane tab={t("company.parks")} key="1">
          <Table dataSource={parks} rowKey="id" columns={columns}></Table>
        </TabPane>
        <TabPane tab={t("company.users")} key="2">
          <Table
            dataSource={users?.data?.data}
            rowKey="id"
            loading={isCompaniesLoading}
            caption={
              <Button onClick={showModal}>
                {t("company.addUserToCompany")}
              </Button>
            }
          >
            <Table.Column dataIndex="id" title="ID" />
            <Table.Column dataIndex="firstName" title={t("user.firstName")} />
            <Table.Column dataIndex="lastName" title={t("user.lastName")} />
            <Table.Column dataIndex="username" title={t("user.username")} />
            <Table.Column
              dataIndex="email"
              title={t("pages.login.fields.email")}
            />
            {/* <Table.Column dataIndex="role" title={t("user.role")} />
            <Table.Column dataIndex="title" title={t("user.postion")} /> */}
            <Table.Column<IUser>
              title={t("renewableEnergy.actions")}
              dataIndex="actions"
              key="actions"
              render={(_, record) => (
                <Space>
                  <ShowButton
                    size="small"
                    recordItemId={record?.id}
                    hideText
                    onClick={() => {
                      show("users", record.id);
                    }}
                  />
                  {/* <EditButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    onClick={() => {
                      edit("users", record.id);
                    }}
                  />
                   <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    onSuccess={(data) => {
                      refetch();
                      console.log("data deleted", data);
                    }}
                  />  */}
                </Space>
              )}
            />
          </Table>
        </TabPane>
      </Tabs>
      <Modal
        title={t("company.addUserToCompany")}
        open={isModalOpen}
        onOk={handleOk}
        okText={t("company.addUser")}
        onCancel={handleCancel}
        style={{ backgroundColor: common.isDarkMode ? "#000c17" : "#fff" }}
        bodyStyle={{ backgroundColor: common.isDarkMode ? "#000c17" : "#fff" }}
      >
        <Row>
          <Col span={24} className="gutter-row">
            <Select
              size="large"
              onChange={(value) => {
                setSelectedUser(value);
              }}
              placeholder={t("company.selectUser")}
              style={{ width: 470 }}
              dropdownStyle={{
                backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
              }}
              {...usersSelectProps}
              options={queryResultUsers?.data?.data
                ?.filter((r: any) => r.firstName.length > 2)
                .map((el: any) => ({
                  label: el.email,
                  value: el.id,
                }))}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};
