import { DateTime } from "luxon";
import dataP957 from "../data/SolarP957.json";
import dataP962 from "../data/SolarP962.json";
import dataFERRANTI from "../data/windFERRANTI.json";
import dataFRANZI from "../data/windFRANZI.json";

export const roundTimestamp = (timestamp: string, interval: string) => {
  const minute = Number(timestamp.slice(14, 16));
  const hour = Number(timestamp.slice(11, 13));

  let roundedMinute;
  let roundedHour;

  if (interval === "15m") {
    // Round to the nearest 15 minutes
    roundedMinute = Math.floor(minute / 15) * 15;
    roundedHour = hour;
  } else if (interval === "8h") {
    // Round to the nearest 8 hours
    roundedMinute = 0;
    roundedHour = Math.floor(hour / 8) * 8;
  } else if (interval === "3h") {
    // Round to the nearest 3 hours
    roundedMinute = 0;
    roundedHour = Math.floor(hour / 3) * 3;
  } else {
    // Invalid interval specified
    throw new Error(
      "Invalid rounding interval. Supported intervals: 15m, 8h, 3h"
    );
  }

  const roundedTimestamp =
    timestamp.slice(5, 10) +
    " " +
    ("0" + roundedHour).slice(-2) +
    ":" +
    ("0" + roundedMinute).slice(-2);

  return roundedTimestamp;
};

function adjustTimestampAndValue(obj: any, planet: string) {
  let adjustedArr: any[] = [];
  // console.log("obj", obj);
  for (let i = 0; i < 4; i++) {
    const value =
      planet === "UP_FERRANTI_1" || planet === "UP_FRANZI_1"
        ? (obj.value * 1000) / 4
        : obj.value / 4;
    const fullDate = obj.timestamp.split("T");
    const date = fullDate[0].split("-");
    const hours = fullDate[1].split(":");
    // console.log(
    //   "obj obj",
    //   DateTime.fromObject({
    //     year: date[0],
    //     month: date[1],
    //     day: date[2],
    //     hour: hours[0],
    //     minute: hours[1],
    //   })
    // );
    adjustedArr.push({
      ...obj,
      timestamp: DateTime.fromObject({
        year: date[0],
        month: date[1],
        day: date[2],
        hour: hours[0],
        minute: hours[1],
      })
        .plus({ minutes: 15 * i })
        .toFormat("MM-dd HH:mm"),
      value,
    });
  }
  // console.log("adjustedArr", adjustedArr);
  return adjustedArr;
}

export const getForecastData = (
  dateFrom: string,
  dateTo: string,
  resolution: string,
  planetId: string,
  models: string[],
  aiPowerdForecast: boolean
) => {
  const forecastArr: any[] = [];
  console.log(
    "Forecast data",
    models,
    dateFrom,
    dateTo,
    planetId,
    resolution,
    aiPowerdForecast
  );

  if (dateFrom !== "" && dateTo !== "") {
    let split: any = {};

    const data: any[] =
      planetId === "UP_FERRANTI_1"
        ? dataFERRANTI
        : planetId === "UP_FRANZI_1"
        ? dataFRANZI
        : planetId === "P957"
        ? dataP957
        : planetId === "P962"
        ? dataP962
        : [];

    data.forEach((item: any) => {
      //  console.log("item item", item);
      let timestamp = "";
      let timestampCompare = "";
      let object = "";
      if (resolution === "15m") {
        const luxonTimestamp = DateTime.fromISO(item.timestamp, {
          zone: "utc",
        });
        timestampCompare = luxonTimestamp.toISO() ?? "";
        if (timestampCompare < dateFrom || timestampCompare > dateTo) return;

        //   console.log("timestampCompare", timestampCompare);
        //      console.log("dateTo", dateTo);
        // console.log("dateFrom", dateFrom);
        const resolutionArray = adjustTimestampAndValue(item, planetId);
        //  console.log("resolutionArray", resolutionArray);
        resolutionArray.forEach((newItem: any) => {
          object = `${newItem.timestamp}`;
          if (split[object]) {
            split[object].push(newItem);
          } else {
            split[object] = [];
            split[object].push(newItem);
          }
        });
        //  console.log("split", split);
      } else if (resolution === "1h") {
        console.log("resolution forecast", resolution);

        const fullDate = item.timestamp.split("T");
        const date = fullDate[0].split("-");
        const hours = fullDate[1].split(":");
        const luxonTimestamp = DateTime.fromObject({
          year: date[0],
          month: date[1],
          day: date[2],
          hour: hours[0],
          minute: hours[1],
        }).setZone("utc");
        timestampCompare = luxonTimestamp.toISODate() ?? "";
        if (timestampCompare < dateFrom || timestampCompare > dateTo) return;

        timestamp =
          DateTime.fromObject({
            year: date[0],
            month: date[1],
            day: date[2],
            hour: hours[0],
            minute: hours[1],
          })
            .setZone("utc")
            .startOf("hour")
            .toFormat("MM-dd HH:mm") ?? "";
        object = `${timestamp}`;
        const value =
          planetId === "UP_FERRANTI_1" || planetId === "UP_FRANZI_1"
            ? item.value * 1000
            : item.value;
        if (split[object]) {
          split[object].push({ ...item, value });
        } else {
          split[object] = [];
          split[object].push({ ...item, value });
        }
      } else if (resolution === "3h" || resolution === "8h") {
        const luxonTimestamp = DateTime.fromISO(item.timestamp, {
          zone: "utc",
        });
        timestampCompare = luxonTimestamp.toISODate() ?? "";
        if (timestampCompare < dateFrom || timestampCompare > dateTo) return;

        timestamp = roundTimestamp(
          DateTime.fromISO(item.timestamp, { zone: "utc" }).toISO() ?? "",
          resolution
        );
        object = `${timestamp}`;
        const value =
          planetId === "UP_FERRANTI_1" || planetId === "UP_FRANZI_1"
            ? item.value * 1000
            : item.value;

        if (split[object]) {
          split[object].push({ ...item, value });
        } else {
          split[object] = [];
          split[object].push({ ...item, value });
        }
      }
    });

    let newModels: any[] = [];
    if (aiPowerdForecast)
      newModels =
        planetId === "UP_FERRANTI_1" || planetId === "UP_FRANZI_1"
          ? ["expectedPowerModel40"]
          : ["expectedPowerModelA"];
    else if (typeof models === "string") newModels = [models];
    else newModels = models;

    newModels.forEach((model) => {
      const modelTrim = model.trim();
      //  console.log("modelTrim", modelTrim);

      Object.keys(split).forEach((key) => {
        //  console.log("key", key);
        forecastArr.push({
          type: model,
          value: split[key].reduce(
            (previousValue: number, currentValue: any) => {
              if (currentValue.type === modelTrim)
                return +previousValue + +currentValue.value;
              return +previousValue;
            },
            0
          ),
          timestamp: key,
        });
      });
    });
  }
  return {
    data: forecastArr,
  };
};
