// @ts-nocheck

// const fs = require('fs');
// import data from './Data/20211101.json'
import { EnergyMarketDayPrice, PriceResponse } from 'interfaces/'
import data from './Data/pricing.json'
import { Pricing, PricingObject } from './interfaces'

export const getPriceData = (
  dateFrom: string,
  dateTo: string,
  resolution: string,
): PriceResponse => {
  return {
    data: getEnergyMarketPricing(dateFrom, dateTo, resolution),
  }
}

export const getEnergyMarketPricing = (
  dateFrom: string,
  dateTo: string,
  resolution: string,
): EnergyMarketDayPrice[] => {
  // let rawdata = fs.readFileSync('./Data/20211101.json');
  // let energyPricing = JSON.parse(rawdata);
  const days: string[] = []
  const energyPricingArray: EnergyMarketDayPrice[] = []
  if (dateFrom !== '' && dateTo !== '') {
    const finalDateFrom = new Date(dateFrom)
    const finalDateTo = new Date(dateTo)

    for (const item of data.pricingData) {
      if (finalDateFrom <= new Date(item.timestamp) && finalDateTo >= new Date(item.timestamp)) {
        if (resolution === 'daily') {
          if (!days.includes(item.timestamp.slice(0, 10))) days.push(item.timestamp.slice(0, 10))
        } else {
          energyPricingArray.push(
            {
              marketName: 'PUN',
              marketPrice: item.PUN,
              date: item.timestamp
                .slice(8, 10)
                .concat('-')
                .concat(item.timestamp.slice(5, 7))
                .concat(' ')
                .concat(item.timestamp.slice(11, 16)),
            },
            {
              marketName: 'Forecast PUN',
              marketPrice: item.ForecastPUN,
              date: item.timestamp
                .slice(8, 10)
                .concat('-')
                .concat(item.timestamp.slice(5, 7))
                .concat(' ')
                .concat(item.timestamp.slice(11, 16)),
            },
          )
        }
      }
    }
  }

  if (resolution === 'daily') {
    return getDataEveryDay(days)
  }

  return energyPricingArray
}

const getDataEveryDay = (days: string[]): Pricing[] => {
  const pricingArray = []
  for (const day of days) {
    pricingArray.push(
      {
        marketName: 'PUN',
        marketPrice: calculateAvrageOfTheDay(data.pricingData, new Date(day), 'PUN'),
        date: day,
      },
      {
        marketName: 'Forecast PUN',
        marketPrice: calculateAvrageOfTheDay(data.pricingData, new Date(day), 'ForecastPUN'),
        date: day,
      },
    )
  }
  return pricingArray
}

const calculateAvrageOfTheDay = (array: PricingObject[], date: Date, value: string) => {
  let sum = 0
  let count = 0
  const selectedDate = date.toISOString().slice(0, 10)
  for (const item of array) {
    const itemDate = item.timestamp.slice(0, 10)
    if (new Date(itemDate) < new Date(selectedDate)) {
      continue
    } else if (new Date(itemDate) > new Date(selectedDate)) {
      break
    } else {
      sum = sum + item[value]
      count = count + 1
    }
  }
  return sum / count
}
// const formateNumberFromCommaToDot = (number: string): number => {
//  return parseFloat(number.replace(',','.'))
// }
