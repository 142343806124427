import { Statistic } from 'antd'

interface KpiProps{
    title: string
    value: number
    precision: number
    suffix: string
}

export const Kpi: React.FC<KpiProps> = ({precision, suffix, title, value}:KpiProps) => {
    return (
      <>
        <Statistic
            title={title}
            value={value}
            precision={precision}
            valueStyle={{ color: '#3f8600' }}
            // prefix={<ArrowUpOutlined />}
            suffix={suffix}
        />          
      </>
    );
  };
  