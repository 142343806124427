import { Kpi } from "../../../components";
import { Card, Row, Col } from "antd";
import { MultiLinePlotAnimation } from "../../../components/dashboard";
import { useCustom, useTranslate } from "@refinedev/core";
import { env } from "@env";
// import { useEffect } from "react";
import { LoadingAndEmpty } from "../../../components/LoadingComponent";

import {
  //useDispatch,
  useSelector,
} from "react-redux";
import { RESOLUTION } from "../../../interfaces/enum/resolutionENUM";

export const OverViewDashboard: React.FC = () => {
  //  const dispatch = useDispatch();
  const t = useTranslate();

  const { locationsReducer } = useSelector((state: any) => ({ ...state }));

  const { data: overview, isLoading } = useCustom({
    url: `${env.REACT_APP_API_URL}/api/v1/dashboards/${parseInt(
      locationsReducer.selectedLocation !== null &&
        locationsReducer.selectedLocation.id
    )}/energyProduction`,
    method: "get",
    config: {
      query: {
        dateFrom: "2023/04/10 20:00",
        dateTo: "2023/04/10 20:00",
        resolution: RESOLUTION.DAILY,
        best_model: "True",
      },
    },
    queryOptions: {
      enabled:
        locationsReducer.selectedLocation !== null &&
        locationsReducer.selectedLocation.id !== undefined,
    },
  });

  console.log("overview", overview?.data);

  // const { data: locations } = useCustom({
  //   url: `${env.REACT_APP_API_URL}/api/v1/locations`,
  //   method: "get",
  // });

  // useEffect(() => {
  //   if (locations?.data !== undefined) {
  //     dispatch({
  //       type: "SET_LOCATIONS",
  //       payload: locations?.data?.data,
  //     });
  //   }
  // }, [locations, dispatch]);

  const config = {
    data:
      overview?.data?.productionData === undefined
        ? []
        : overview?.data.productionData,
    xField: "timestamp",
    yField: "value",
    seriesField: "type",
    title: `${t("dashboard.overview.production")}`,
    // yAxis: 'marketPrice',
    yAxis: {
      label: {
        formatter: (v: any) => `${(v / 1).toFixed(1)} MWh`,
      },
    },
    legend: {
      position: "top",
      itemName: {
        style: {
          spacing: 2000,
          fill: "#1ec6ff",
        },
      },
    },
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
    slider: {
      start: 0,
      end: 1,
      textStyle: {
        fill: "white",
      },
    },
  };

  return (
    <>
      <Row gutter={16} style={{ marginBottom: 10 }}>
        <Col span={6}>
          <Card>
            <Kpi
              title={t("dashboard.overview.absoluteSavings")}
              //  value={overview?.data.kpis.absoluteSavings}
              value={50}
              precision={3}
              suffix=""
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Kpi
              title={t("dashboard.overview.relativeSavings")}
              //  value={overview?.data.kpis.relativeSavings}
              value={40}
              precision={3}
              suffix=""
            />
          </Card>
        </Col>
        {/* <Col span={6}>
          <Card>
            <Kpi
              title={t('dashboard.overview.power')}
              value={overview?.data?.data.kpis.power}
              precision={3}
              suffix="kW"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Kpi
              title={t('dashboard.overview.energy')}
              value={overview?.data?.data.kpis.energy}
              precision={3}
              suffix="kWh"
            />
          </Card>
        </Col> */}
      </Row>
      <Row gutter={16} style={{ marginBottom: 10 }}>
        <Col span={24}>
          <LoadingAndEmpty
            loading={isLoading}
            bordered={false}
            data={
              overview?.data?.productionData === undefined
                ? []
                : overview?.data.productionData
            }
          >
            <MultiLinePlotAnimation {...config} />
          </LoadingAndEmpty>
        </Col>
      </Row>
    </>
  );
};
