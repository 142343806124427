import { Edit, useForm } from "@refinedev/antd";
import { Form, Input } from "antd";
import { IUser } from "@/interfaces/user";
import { useTranslate } from "@refinedev/core";

export const EditUser: React.FC = () => {
  const { formProps, saveButtonProps } = useForm<IUser>();
  const t = useTranslate();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          return formProps.onFinish?.({
            ...values,
          });
        }}
      >
        <Form.Item
          label={t("user.firstName")}
          name="firstName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("user.lastName")}
          name="lastName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("user.role")}
          name="role"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("user.postion")}
          name="title"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  );
};
