// @ts-nocheck
import { Card, Row, Col, Space, DatePicker, Divider, Collapse } from "antd";
// import moment from 'moment'
import { useTranslate } from "@refinedev/core";

const { Panel } = Collapse;

export const ChartFiltering = () => {
  // const [dateFrom, setDateFrom] = useState<string>('')
  // const [dateTo, setDateTo] = useState<string>('')
  const t = useTranslate();

  // for default date when open the chart
  const handleDateFrom = (date, dateString) => {
    // setDateFrom(dateString.slice(0, 10))
    // setDateTo(dateString.slice(0, 10))
  };

  const handleDateTo = (date, dateString) => {
    setDateTo(dateString.slice(0, 10));
  };

  return (
    <Collapse defaultActiveKey={["1"]} ghost={true}>
      <Panel header={t("dashboard.filter.title")} key="1">
        <Card>
          <Row justify="space-around">
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 4 }}
              xl={{ span: 4 }}
            >
              <div>
                <h4>{t("dashboard.filter.dateFrom")}</h4>
              </div>
              <Space>
                <DatePicker
                  allowClear={false}
                  onChange={handleDateFrom}
                  format="YYYY-MM-DD"
                />
              </Space>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 4 }}
              xl={{ span: 4 }}
            >
              <div>
                <h4>{t("dashboard.filter.dateTo")}</h4>
              </div>
              <Space>
                <DatePicker
                  allowClear={false}
                  onChange={handleDateTo}
                  format="YYYY-MM-DD"
                />
              </Space>
            </Col>
          </Row>
        </Card>
        <Divider style={{ margin: "10px 0px" }} />
      </Panel>
    </Collapse>
  );
};
