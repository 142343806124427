import { useEffect, useState } from 'react'
import {
  PolarChart,
  CommonSeriesSettings,
  Series,
  ArgumentAxis,
  ValueAxis,
  Margin,
} from 'devextreme-react/polar-chart'
import './style.css'

interface WindChartProps{
    windSources: any[]
    windRoseData: any[]
}

export const WindChart = ({windRoseData, windSources}:WindChartProps ) => {
  const [periodValues, setPeriodValues] = useState()
  console.log('from compon', windSources);
  
  useEffect(() => {
    setPeriodValues(windRoseData[0]?.values)
  }, [windRoseData])

  const onLegendClick = ({ target: series }: any) => {
    if (series.isVisible()) {
      series.hide()
    } else {
      series.show()
    }
  }

//   const handleChange = ({ value }) => {
//     setPeriodValues(value)
//   }

  return (
    <div id='chart-demo'>
      <PolarChart
        id='radarChart'
        palette='Soft'
        dataSource={periodValues}
        onLegendClick={onLegendClick}
      //  title='Wind Rose, Philadelphia PA'
      >
        <CommonSeriesSettings type='stackedbar' />
        {windSources.map((item) => (
          <Series key={item.value} valueField={item.value} name={item.name} />
        ))}
        <Margin bottom={50} left={100} />
        <ArgumentAxis discreteAxisDivisionMode='crossLabels' firstPointOnStartAngle={true} />
        <ValueAxis valueMarginsEnabled={false} />
        {/* <Export enabled={true} /> */}
      </PolarChart>
      {/* <div className="center">
          <SelectBox
            width="300"
            dataSource={windRoseData}
            displayExpr="period"
            valueExpr="values"
            value={periodValues}
            onValueChanged={handleChange}
          />
        </div> */}
    </div>
  )
}
