// @ts-nocheck
import { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Space,
  DatePicker,
  Divider,
  Collapse,
  Radio,
  Select,
  Button,
  TimePicker,
  Checkbox,
} from "antd";
import { DateTime } from "luxon";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { getModels } from "../../../../Mocks/models";
// import { env } from "@env";

import { useTranslate } from "@refinedev/core";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;

const { Panel } = Collapse;

export const ForeCastFiltering = () => {
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [timeFrom, setTimeFrom] = useState<string>("");
  const [timeTo, setTimeTo] = useState<string>("");
  const [resolution, setResolution] = useState<string>("");
  const [selectedModels, setSelectedModels] = useState<string[]>([]);
  const [models, setModels] = useState([]);
  const [fixedRange, setFixedRange] = useState("");
  const [isFixedRange, setIsFixedRange] = useState(true);
  const [isEnabledAI, setIsEnabledAI] = useState(false);
  // const [isEnabledInstalledCapacity, setIsEnabledInstalledCapacity] =
  //   useState(false);

  const dispatch = useDispatch();
  const t = useTranslate();
  const { locationsReducer, common, chartFiltering } = useSelector(
    (state: any) => ({
      ...state,
    })
  );

  // const { data: modelsData } = useCustom({
  //   url: `${env.REACT_APP_API_URL}/api/v1/models`,
  //   method: "get",
  // });

  useEffect(() => {
    if (getModels()?.data !== undefined) {
      if (locationsReducer.selectedLocation !== null) {
        if (locationsReducer?.selectedLocation?.locationType === "solar") {
          const solarModelsArr = getModels()?.data.filter(
            (x) => x.isWind === false && x.isHistorical === false
          );
          setModels(solarModelsArr);
          setSelectedModels([
            solarModelsArr[0].type,
            solarModelsArr[1].type,
            solarModelsArr[2].type,
          ]);
          dispatch({
            type: "SET_FORECAST_FILTER",
            payload: {
              dateFrom: DateTime.fromISO(
                dateFrom.slice(0, 10) + "T" + timeFrom + ":00.000",
                { zone: `local` }
              ),
              dateTo: DateTime.fromISO(
                dateTo.slice(0, 10) + "T" + timeTo + ":00.000",
                { zone: `local` }
              ),
              resolution,
              models: [
                solarModelsArr[0].type,
                solarModelsArr[1].type,
                solarModelsArr[2].type,
              ],
              fixedRange,
              isEnabledAI: false,
            },
          });
        } else {
          const windModelsArr = getModels()?.data.filter(
            (x) => x.isWind === true && x.isHistorical === false
          );
          setModels(windModelsArr);
          setSelectedModels([
            windModelsArr[0].type,
            windModelsArr[1].type,
            windModelsArr[2].type,
          ]);
          dispatch({
            type: "SET_FORECAST_FILTER",
            payload: {
              dateFrom: DateTime.fromISO(
                dateFrom.slice(0, 10) + "T" + timeFrom + ":00.000",
                { zone: `local` }
              ),
              dateTo: DateTime.fromISO(
                dateTo.slice(0, 10) + "T" + timeTo + ":00.000",
                { zone: `local` }
              ),
              resolution,
              models: [
                windModelsArr[0].type,
                windModelsArr[1].type,
                windModelsArr[2].type,
              ],
              fixedRange,
              isEnabledAI: false,
            },
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsReducer.selectedLocation]);

  useEffect(() => {
    setResolution("1h");
    setFixedRange("8h");

    setDateFrom(DateTime.now().toISO().slice(0, 10));
    setTimeFrom(DateTime.now().toISO()?.slice(11, 16));
    setDateTo(
      DateTime.now()
        .plus({ hours: 8 })
        // .setZone("Europe/Rome")
        .toISO()
        .slice(0, 10)
    );
    setTimeTo(
      DateTime.now()
        .plus({ hours: 8 })
        //  .setZone("Europe/Rome")
        .toISO()
        ?.slice(11, 16)
    );
    dispatch({
      type: "SET_FORECAST_FILTER",
      payload: {
        dateFrom: DateTime.now().toISO(),
        dateTo: DateTime.now().plus({ hours: 8 }).toISO(),
        //  .setZone("Europe/Rome")
        //  .toUTC(),
        resolution: "1h",
        models: [
          "externalExpectedPower",
          "expectedPowerModel28",
          "expectedPowerModel40",
        ],
        isEnabledAI: false,
      },
    });
  }, [dispatch]);

  // for default date when open the chart
  const handleDateFrom = (date, dateString) => {
    console.log("dateString", dateString);
    const dateTo = dateString.split("-");
    const timeToArr = timeFrom.split(":");
    // const finalDateTo = DateTime.fromObject(
    //   {
    //     year: dateTo[0],
    //     month: dateTo[1],
    //     day: dateTo[2],
    //     hour: timeToArr[0],
    //     minute: timeToArr[1],
    //   },
    //   { zone: "Europe/Rome" }
    // ).toISO();

    switch (fixedRange) {
      case "8h":
        setDateTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeToArr[0],
            minute: timeToArr[1],
          })
            .plus({ hours: 8 })
            //  .setZone("Europe/Rome")
            .toISO()
        );
        setTimeTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeToArr[0],
            minute: timeToArr[1],
          })
            .plus({ hours: 8 })
            //  .setZone("Europe/Rome")
            .toISO()
            ?.slice(11, 16)
        );
        break;
      case "16h":
        setDateTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeToArr[0],
            minute: timeToArr[1],
          })
            .plus({ hours: 16 })
            //  .setZone("Europe/Rome")
            .toISO()
        );
        setTimeTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeToArr[0],
            minute: timeToArr[1],
          })
            .plus({ hours: 16 })
            //  .setZone("Europe/Rome")
            .toISO()
            ?.slice(11, 16)
        );
        break;
      case "24h":
        setDateTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeToArr[0],
            minute: timeToArr[1],
          })
            .plus({ hours: 24 })
            //  .setZone("Europe/Rome")
            .toISO()
        );
        setTimeTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeToArr[0],
            minute: timeToArr[1],
          })
            .plus({ hours: 24 })
            //  .setZone("Europe/Rome")
            .toISO()
            ?.slice(11, 16)
        );
        break;
      case "36h":
        setDateTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeToArr[0],
            minute: timeToArr[1],
          })
            .plus({ hours: 36 })
            // .setZone("Europe/Rome")
            .toISO()
        );
        setTimeTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeToArr[0],
            minute: timeToArr[1],
          })
            .plus({ hours: 36 })
            //  .setZone("Europe/Rome")
            .toISO()
            ?.slice(11, 16)
        );
        break;
      case "7days":
        setDateTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeToArr[0],
            minute: timeToArr[1],
          })
            .plus({ days: 7 })
            //  .setZone("Europe/Rome")
            .toISO()
        );
        setTimeTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeToArr[0],
            minute: timeToArr[1],
          })
            .plus({ days: 7 })
            //  .setZone("Europe/Rome")
            .toISO()
            ?.slice(11, 16)
        );
        break;
      default:
        break;
    }

    // .plus({ days: 7 })

    //  console.log("finalDateTo", finalDateTo);
    setDateFrom(dateString.slice(0, 10));
    // setDateTo(finalDateTo.slice(0, 10));
    // setTimeFrom(timeFrom.length < 5 ? "00:00" : timeFrom);
    // setTimeTo(timeTo.length < 5 ? "23:00" : timeTo);
    // console.log("timeFrom", timeFrom);
  };

  const handleDateTo = (date, dateString) => {
    setDateTo(dateString.slice(0, 10));
    setTimeFrom(timeFrom.length < 5 ? "00:00" : timeFrom);
    setTimeTo(timeTo.length < 5 ? "23:00" : timeTo);
  };

  const onChangeRadio = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);

    setFixedRange(e.target.value);
    const dateTo = dateFrom.split("-");
    const timeTo = timeFrom.split(":");
    switch (e.target.value) {
      case "8h":
        setResolution("1h");
        setIsFixedRange(true);
        setDateTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeTo[0],
            minute: timeTo[1],
          })
            .plus({ hours: 8 })
            //  .setZone("Europe/Rome")
            .toISO()
        );
        setTimeTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeTo[0],
            minute: timeTo[1],
          })
            .plus({ hours: 8 })
            //  .setZone("Europe/Rome")
            .toISO()
            ?.slice(11, 16)
        );
        break;
      case "16h":
        setResolution("1h");
        setIsFixedRange(true);
        setDateTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeTo[0],
            minute: timeTo[1],
          })
            .plus({ hours: 16 })
            //  .setZone("Europe/Rome")
            .toISO()
        );
        setTimeTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeTo[0],
            minute: timeTo[1],
          })
            .plus({ hours: 16 })
            //  .setZone("Europe/Rome")
            .toISO()
            ?.slice(11, 16)
        );
        break;
      case "24h":
        setResolution("3h");
        setIsFixedRange(true);
        setDateTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeTo[0],
            minute: timeTo[1],
          })
            .plus({ hours: 24 })
            //  .setZone("Europe/Rome")
            .toISO()
        );
        setTimeTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeTo[0],
            minute: timeTo[1],
          })
            .plus({ hours: 24 })
            //  .setZone("Europe/Rome")
            .toISO()
            ?.slice(11, 16)
        );
        break;
      case "36h":
        setResolution("3h");
        setIsFixedRange(true);
        setDateTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeTo[0],
            minute: timeTo[1],
          })
            .plus({ hours: 36 })
            //  .setZone("Europe/Rome")
            .toISO()
        );
        setTimeTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeTo[0],
            minute: timeTo[1],
          })
            .plus({ hours: 36 })
            //  .setZone("Europe/Rome")
            .toISO()
            ?.slice(11, 16)
        );
        break;
      case "7days":
        setResolution("8h");
        setIsFixedRange(true);
        setDateTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeTo[0],
            minute: timeTo[1],
          })
            .plus({ days: 7 })
            //  .setZone("Europe/Rome")
            .toISO()
        );
        setTimeTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeTo[0],
            minute: timeTo[1],
          })
            .plus({ days: 7 })
            //  .setZone("Europe/Rome")
            .toISO()
            ?.slice(11, 16)
        );
        break;
      // case "custom":
      //   setResolution("daily");
      //   setIsFixedRange(false);
      //   setDateTo(
      //     DateTime.now().plus({ days: 7 }).setZone("Europe/Rome").toISO()
      //   );
      //   setTimeTo(
      //     DateTime.now()
      //       .plus({ days: 7 })
      //       .setZone("Europe/Rome")
      //       .toISO()
      //       ?.slice(11, 15)
      //   );
      //   break;
      default:
        break;
    }
  };

  const onChangeTimeFrom = (time: Dayjs, timeString: string) => {
    console.log(time, timeString);
    setTimeFrom(timeString);
  };
  const onChangeTimeTo = (time: Dayjs, timeString: string) => {
    console.log(time, timeString);
    setTimeTo(timeString);
  };

  const handleSelectModels = (value) => {
    setSelectedModels(value);
    console.log("value", value);
  };

  const onChangeChecked = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
    setIsEnabledAI(e.target.checked);
    //  setSelectedModels([]);
    dispatch({
      type: "IS_ENABLED_AI",
      payload: e.target.checked,
    });

    // dispatch({
    //   type: "SET_FORECAST_FILTER",
    //   payload: {
    //     dateFrom: dateFrom.slice(0, 10) + "T" + timeFrom + ":00.000Z",
    //     dateTo: dateTo.slice(0, 10) + "T" + timeTo + ":00.000Z",
    //     resolution,
    //     models: [],
    //     fixedRange,
    //     isEnabledAI: e.target.checked,
    //   },
    // });
  };

  const onChangeCheckedInstalledCapacity = (e: CheckboxChangeEvent) => {
    // setIsEnabledInstalledCapacity(e.target.checked);
    dispatch({
      type: "IS_ENABLED_INSTALLED_CAPACITY_FORECAST",
      payload: e.target.checked,
    });
  };

  const handleResolution = (value) => {
    setResolution(value);
  };

  const handleUpdateFilter = () => {
    // if (resolution !== "1h") {
    //   dispatch({
    //     type: "IS_ENABLED_INSTALLED_CAPACITY_FORECAST",
    //     payload: false,
    //   });
    //   setIsEnabledInstalledCapacity(false);
    // }
    dispatch({
      type: "IS_LOADED_DATA",
      payload: true,
    });
    dispatch({
      type: "SET_FORECAST_FILTER",
      payload: {
        dateFrom: DateTime.fromISO(
          dateFrom.slice(0, 10) + "T" + timeFrom + ":00.000",
          { zone: `local` }
        ).toISO(),
        dateTo: DateTime.fromISO(
          dateTo.slice(0, 10) + "T" + timeTo + ":00.000",
          { zone: `local` }
        ).toISO(),
        resolution,
        models: selectedModels,
        fixedRange,
        isEnabledAI,
      },
    });
  };

  const disabledDate = (current: Dayjs) => {
    return DateTime.now().plus({ days: 7 }).toISO() < current.toISOString();
  };

  return (
    <Collapse defaultActiveKey={["1"]} ghost={true} style={{ marginLeft: -18 }}>
      <Panel header={t("dashboard.filter.title")} key="1">
        <Card>
          <Row justify="start">
            <Col span={4}>
              <Row>
                <Col span={16}>
                  <div>
                    <h4>{t("dashboard.filter.fixed")}</h4>
                  </div>
                  <Radio.Group onChange={onChangeRadio} value={fixedRange}>
                    <Space direction="vertical">
                      <Radio value={"8h"}>{t("dashboard.filter.next8h")}</Radio>
                      <Radio value={"16h"}>
                        {t("dashboard.filter.next16h")}
                      </Radio>
                      <Radio value={"24h"}>
                        {t("dashboard.filter.next24h")}
                      </Radio>
                      <Radio value={"36h"}>
                        {t("dashboard.filter.next36h")}
                      </Radio>
                      <Radio value={"7days"}>
                        {t("dashboard.filter.next7Days")}
                      </Radio>
                      {/* <Radio value={"custom"}>
                        {t("dashboard.filter.custom")}
                      </Radio> */}
                    </Space>
                  </Radio.Group>
                </Col>
                <Col span={1}>
                  <span
                    style={{
                      borderLeft: "1px solid grey",
                      height: "180px",
                      display: "block",
                      marginTop: 29,
                    }}
                  ></span>
                </Col>
              </Row>
            </Col>
            <Col span={20}>
              <Row>
                <Col span={8}>
                  <div>
                    <h4>{t("dashboard.filter.dateFrom")}</h4>
                  </div>
                  <Space>
                    <DatePicker
                      value={dayjs(dateFrom, "YYYY-MM-DD")}
                      //  disabled={isFixedRange}
                      disabledDate={disabledDate}
                      allowClear={false}
                      onChange={handleDateFrom}
                      format="YYYY-MM-DD"
                      popupStyle={{
                        backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
                      }}
                    />

                    <TimePicker
                      value={dayjs(timeFrom, "HH:mm")}
                      //  disabled={isFixedRange}
                      onChange={onChangeTimeFrom}
                      format={"HH:mm"}
                      popupStyle={{
                        backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
                      }}
                    />
                  </Space>
                </Col>
                <Col span={8}>
                  <div>
                    <h4>{t("dashboard.filter.dateTo")}</h4>
                  </div>
                  <Space>
                    <DatePicker
                      value={dayjs(dateTo, "YYYY-MM-DD")}
                      disabled={isFixedRange}
                      allowClear={false}
                      onChange={handleDateTo}
                      format="YYYY-MM-DD"
                      popupStyle={{
                        backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
                      }}
                    />
                    <TimePicker
                      value={dayjs(timeTo, "HH:mm")}
                      disabled={isFixedRange}
                      onChange={onChangeTimeTo}
                      format={"HH:mm"}
                      popupStyle={{
                        backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
                      }}
                    />
                  </Space>
                </Col>
                <Col span={4}>
                  <div>
                    <h4>{t("dashboard.filter.resolution")}</h4>
                  </div>
                  <Space>
                    <Select
                      placeholder={t("dashboard.filter.resolution")}
                      onChange={handleResolution}
                      value={resolution}
                      //  disabled={!showDataRange}
                      style={{ width: 170 }}
                      dropdownStyle={{
                        backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
                      }}
                    >
                      {(fixedRange === "8h" || fixedRange === "16h") && (
                        <Option value="1h">
                          {t("dashboard.filter.every1h")}
                        </Option>
                      )}
                      {/* {(fixedRange === "16h" ||
                        fixedRange === "24h" ||
                        fixedRange === "36h") && (
                        <Option value="1h">
                          {t("dashboard.filter.every1h")}
                        </Option>
                      )} */}
                      {(fixedRange === "24h" ||
                        fixedRange === "36h" ||
                        fixedRange === "16h") && (
                        <Option value="3h">
                          {t("dashboard.filter.every3h")}
                        </Option>
                      )}
                      {fixedRange === "7days" && (
                        <Option value="8h">
                          {t("dashboard.filter.every8h")}
                        </Option>
                      )}
                      {/* {fixedRange === "custom" && (
                        <>
                          <Option value="hourly">
                            {t("dashboard.filter.hourly")}
                          </Option>
                          <Option value="daily">
                            {t("dashboard.filter.daily")}
                          </Option>
                        </>
                      )} */}
                    </Select>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space>
                    <Button
                      style={{ marginTop: 29, width: 100 }}
                      onClick={handleUpdateFilter}
                    >
                      {t("dashboard.filter.update")}
                    </Button>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={4}>
                  {/* {locationsReducer?.selectedLocation?.aiPoweredEnabled && ( */}
                  <Checkbox
                    value={chartFiltering.isEnabledInstalledCapacityForecast}
                    onChange={onChangeCheckedInstalledCapacity}
                    style={{ marginTop: 29 }}
                    // disabled={
                    //   chartFiltering.forecastFilter !== null &&
                    //   chartFiltering.forecastFilter.resolution !== "1h"
                    // }
                  >
                    {t("dashboard.filter.installedCapacity")}
                  </Checkbox>
                  {/* )} */}
                </Col>
                <Col span={4}>
                  {/* {locationsReducer?.selectedLocation?.aiPoweredEnabled && ( */}
                  <Checkbox
                    value={isEnabledAI}
                    onChange={onChangeChecked}
                    style={{ marginTop: 29 }}
                  >
                    {t("dashboard.filter.aiPowerd")}
                  </Checkbox>
                  {/* )} */}
                </Col>
                <Col span={11}>
                  <div>
                    <h4>{t("dashboard.filter.models")}</h4>
                  </div>
                  <Space>
                    <Select
                      disabled={isEnabledAI}
                      mode="multiple"
                      placeholder={t("dashboard.filter.models")}
                      onChange={handleSelectModels}
                      value={selectedModels}
                      //  disabled={!showDataRange}
                      style={{ width: 550 }}
                      dropdownStyle={{
                        backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
                      }}
                    >
                      {models.map((item) => {
                        return <Option value={item.type}>{item.model}</Option>;
                      })}
                    </Select>
                  </Space>
                </Col>
                {/* <Col span={5}>
                  <Space>
                    <Button
                      disabled={!isEnabledAI}
                      type="primary"
                      style={{ marginTop: 29 }}
                    >
                      {t("dashboard.filter.aiPowerd")}
                    </Button>
                  </Space>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </Card>
        <Divider style={{ margin: "10px 0px" }} />
      </Panel>
    </Collapse>
  );
};
