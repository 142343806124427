import data from "./data.json";
export const getTimeZones = () => {
  let timeZonesArr: {}[] = [];
  let id = 1;
  data.forEach((item) => {
    item.utc.forEach((timeZone) => {
      timeZonesArr.push({
        id,
        value: timeZone,
        label: timeZone,
      });
      id++;
    });
  });
  console.log("time zone", timeZonesArr);

  return timeZonesArr;
};
