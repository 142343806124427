const initial = {
  latlng: {
    lat: 41.89997532953544,
    long: 12.496624078032728,
  },
};

export const mapReducer = (state = initial, action: any) => {
  switch (action.type) {
    case "SET_LNGLAT":
      return { ...state, latlng: action.payload };
    default:
      return state;
  }
};
