import React from 'react'
import { Column } from '@ant-design/plots'
import { Card } from 'antd'

export const GroupedColumnPlot = (config: any) => {
  return (
    <Card title={config.title ? config.title : ' '} bordered={false}>
      <Column {...config} />
    </Card>
  )
}
