import data from "../data/models.json";

export const getModels = () => {
  let modelsArr: any = [];
  data.forEach((item) => {
    modelsArr.push({
      id: item.id,
      model: item.model,
      type: item.type,
      isWind: item.isWind,
      isHistorical: item.isHistorical,
    });
  });

  return { data: modelsArr };
};
