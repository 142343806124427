// @ts-nocheck
import { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Space,
  DatePicker,
  Divider,
  Collapse,
  Radio,
  Select,
  Button,
  // TimePicker,
  Checkbox,
} from "antd";
import { DateTime } from "luxon";
import dayjs from "dayjs";
import { getModels } from "../../../../Mocks/models";

import type { Dayjs } from "dayjs";
// import { env } from "@env";

// import moment from "moment";
import { useTranslate } from "@refinedev/core";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;

const { Panel } = Collapse;

export const HistoricalFiltering = () => {
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [timeFrom, setTimeFrom] = useState<string>("");
  const [timeTo, setTimeTo] = useState<string>("");
  const [resolution, setResolution] = useState<string>("");

  const [selectedModels, setSelectedModels] = useState<string[]>([]);
  const [models, setModels] = useState([]);
  const [isEnabledAI, setIsEnabledAI] = useState(false);

  const [fixedRange, setFixedRange] = useState("");
  const [isFixedRange, setIsFixedRange] = useState(true);
  // const [isEnabledInstalledCapacity, setIsEnabledInstalledCapacity] =
  //   useState(false);
  const dispatch = useDispatch();
  const t = useTranslate();
  const { locationsReducer, common, chartFiltering } = useSelector(
    (state: any) => ({
      ...state,
    })
  );

  // const { data: modelsData } = useCustom({
  //   url: `${env.REACT_APP_API_URL}/api/v1/models`,
  //   method: "get",
  // });

  useEffect(() => {
    if (getModels()?.data !== undefined) {
      if (locationsReducer.selectedLocation !== null) {
        if (locationsReducer?.selectedLocation?.locationType === "solar") {
          const solarModelsArr = getModels()?.data.filter(
            (x) => x.isWind === false || x.isHistorical === true
          );
          setModels(solarModelsArr);
          setSelectedModels([
            "activePower",
            "externalExpectedPower",
            "expectedPowerModel40",
          ]);
          dispatch({
            type: "SET_HISTORICAL_FILTER",
            payload: {
              dateFrom: dateFrom.slice(0, 10) + "T23:00:00.000Z",

              dateTo: dateTo.slice(0, 10) + "T00:00:00.000Z",

              resolution,
              models: solarModelsArr[0].type,
              fixedRange,
              // models: [
              //   "activePower",
              //   "externalExpectedPower",
              //   "expectedPowerModel40",
              // ],
              isEnabledAI: false,
            },
          });
        } else {
          const windModelsArr = getModels()?.data.filter(
            (x) => x.isWind === true || x.isHistorical === true
          );
          setModels(windModelsArr);
          setSelectedModels([
            "activePower",
            "externalExpectedPower",
            "expectedPowerModel40",
          ]);
          dispatch({
            type: "SET_HISTORICAL_FILTER",
            payload: {
              dateFrom: dateFrom.slice(0, 10) + "T23:00:00.000Z",

              dateTo: dateTo.slice(0, 10) + "T00:00:00.000Z",
              resolution,
              models: windModelsArr[0].type,
              fixedRange,
              // models: [
              //   "activePower",
              //   "externalExpectedPower",
              //   "expectedPowerModel40",
              // ],
              isEnabledAI: false,
            },
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsReducer.selectedLocation]);

  useEffect(() => {
    setResolution("hourly");
    setFixedRange("lastDay");

    setDateFrom(DateTime.now().toISO().slice(0, 10));
    setTimeFrom(DateTime.now().toISO()?.slice(11, 16));
    setDateTo(
      DateTime.now()
        .minus({ days: 1 })
        //  .setZone("Europe/Rome")
        .toISO()
        .slice(0, 10)
    );
    setTimeTo(
      DateTime.now()
        .minus({ days: 1 })
        //  .setZone("Europe/Rome")
        .toISO()
        ?.slice(11, 16)
    );
    dispatch({
      type: "SET_HISTORICAL_FILTER",
      payload: {
        dateFrom: DateTime.now().toISO().slice(0, 10) + "T23:00:00.000Z",
        dateTo:
          DateTime.now()
            .minus({ days: 1 })
            //  .setZone("Europe/Rome")
            .toISO()
            .slice(0, 10) + "T00:00:00.000Z",
        resolution: "hourly",
        models: [
          "activePower",
          "externalExpectedPower",
          "expectedPowerModel40",
        ],
        isEnabledAI: false,
      },
    });
  }, [dispatch]);

  // for default date when open the chart
  const handleDateFrom = (date, dateString) => {
    console.log("dateString", dateString);
    const dateTo = dateString.split("-");
    const timeToArr = timeFrom.split(":");
    // const finalDateTo = DateTime.fromObject(
    //   {
    //     year: dateTo[0],
    //     month: dateTo[1],
    //     day: dateTo[2],
    //     hour: timeToArr[0],
    //     minute: timeToArr[1],
    //   },
    //   { zone: "Europe/Rome" }
    // ).toISO();
    // .plus({ days: 7 })

    //  console.log("finalDateTo", finalDateTo);
    switch (fixedRange) {
      case "lastDay":
        setDateTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeToArr[0],
            minute: timeToArr[1],
          })
            .minus({ days: 1 })
            //  .setZone("Europe/Rome")
            .toISO()
        );

        break;
      case "lastWeek":
        setDateTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeToArr[0],
            minute: timeToArr[1],
          })
            .minus({ days: 7 })
            //  .setZone("Europe/Rome")
            .toISO()
        );

        break;
      case "lastMonth":
        setDateTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeToArr[0],
            minute: timeToArr[1],
          })
            .minus({ months: 1 })
            //  .setZone("Europe/Rome")
            .toISO()
        );

        break;
      case "lastYear":
        setDateTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeToArr[0],
            minute: timeToArr[1],
          })
            .minus({ months: 12 })
            //  .setZone("Europe/Rome")
            .toISO()
        );

        break;
      default:
        break;
    }

    setDateFrom(dateString.slice(0, 10));
    // setDateTo(finalDateTo.slice(0, 10));
    // setTimeFrom(timeFrom.length < 5 ? "23:00" : timeFrom);
    // setTimeTo(timeTo.length < 5 ? "00:00" : timeTo);
    // console.log("timeFrom", timeFrom);
  };

  const handleDateTo = (date, dateString) => {
    setDateTo(dateString.slice(0, 10));
    setTimeFrom(timeFrom.length < 5 ? "23:00" : timeFrom);
    setTimeTo(timeTo.length < 5 ? "00:00" : timeTo);
  };

  const onChangeRadio = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setFixedRange(e.target.value);
    const dateTo = dateFrom.split("-");
    const timeTo = timeFrom.split(":");
    switch (e.target.value) {
      case "lastDay":
        setResolution("hourly");
        setIsFixedRange(true);
        //  setIsEnabledInstalledCapacity(false);
        setDateTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeTo[0],
            minute: timeTo[1],
          })
            .minus({ days: 1 })
            //  .setZone("Europe/Rome")
            .toISO()
        );

        break;
      case "lastWeek":
        setResolution("every12h");
        setIsFixedRange(true);
        //  setIsEnabledInstalledCapacity(false);
        setDateTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeTo[0],
            minute: timeTo[1],
          })
            .minus({ days: 7 })
            //  .setZone("Europe/Rome")
            .toISO()
        );

        break;
      case "lastMonth":
        setResolution("daily");
        setIsFixedRange(true);
        //   setIsEnabledInstalledCapacity(false);
        setDateTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeTo[0],
            minute: timeTo[1],
          })
            .minus({ months: 1 })
            //  .setZone("Europe/Rome")
            .toISO()
        );

        break;
      case "lastYear":
        setResolution("weekly");
        //  setIsEnabledInstalledCapacity(false);
        setIsFixedRange(true);
        setDateTo(
          DateTime.fromObject({
            year: dateTo[0],
            month: dateTo[1],
            day: dateTo[2],
            hour: timeTo[0],
            minute: timeTo[1],
          })
            .minus({ months: 12 })
            //  .setZone("Europe/Rome")
            .toISO()
        );

        break;
      default:
        break;
    }
  };

  // const onChangeTimeFrom = (time: Dayjs, timeString: string) => {
  //   console.log(time, timeString);
  //   setTimeFrom(timeString);
  // };
  // const onChangeTimeTo = (time: Dayjs, timeString: string) => {
  //   console.log(time, timeString);
  //   setTimeTo(timeString);
  // };

  const handleResolution = (value) => {
    setResolution(value);
  };

  const handleUpdateFilter = () => {
    // if (resolution !== "hourly") {
    //   dispatch({
    //     type: "IS_ENABLED_INSTALLED_CAPACITY_HISTORICAL",
    //     payload: false,
    //   });
    //   setIsEnabledInstalledCapacity(false);
    // }
    dispatch({
      type: "IS_LOADED_DATA",
      payload: true,
    });
    dispatch({
      type: "SET_HISTORICAL_FILTER",
      payload: {
        dateFrom: dateFrom.slice(0, 10) + "T23:00:00.000Z",
        dateTo: dateTo.slice(0, 10) + "T00:00:00.000Z",
        resolution,
        fixedRange,
        isEnabledAI: false,
        models: selectedModels,
      },
    });
  };

  const disabledDate = (current: Dayjs) => {
    return DateTime.now().toISO() < current.toISOString();
  };

  const onChangeChecked = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
    setIsEnabledAI(e.target.checked);
    dispatch({
      type: "IS_ENABLED_AI_HISTORICAL",
      payload: e.target.checked,
    });
    // dispatch({
    //   type: "SET_HISTORICAL_FILTER",
    //   payload: {
    //     dateFrom: dateFrom.slice(0, 10) + "T" + timeFrom + ":00.000Z",
    //     dateTo: dateTo.slice(0, 10) + "T" + timeTo + ":00.000Z",
    //     resolution,
    //     models: [],
    //     fixedRange,
    //     isEnabledAI: e.target.checked,
    //   },
    // });
  };

  const handleSelectModels = (value) => {
    setSelectedModels(value);
    console.log("value", value);
  };

  const onChangeCheckedInstalledCapacity = (e: CheckboxChangeEvent) => {
    // setIsEnabledInstalledCapacity(e.target.checked);
    dispatch({
      type: "IS_ENABLED_INSTALLED_CAPACITY_HISTORICAL",
      payload: e.target.checked,
    });
  };
  return (
    <Collapse defaultActiveKey={["1"]} ghost={true} style={{ marginLeft: -18 }}>
      <Panel header={t("dashboard.filter.title")} key="1">
        <Card>
          <Row justify="start">
            <Col span={4}>
              <Row>
                <Col span={16}>
                  <div>
                    <h4>{t("dashboard.filter.fixed")}</h4>
                  </div>
                  <Radio.Group onChange={onChangeRadio} value={fixedRange}>
                    <Space direction="vertical">
                      <Radio value={"lastDay"}>
                        {t("dashboard.filter.lastDay")}
                      </Radio>
                      <Radio value={"lastWeek"}>
                        {t("dashboard.filter.lastWeek")}
                      </Radio>
                      <Radio value={"lastMonth"}>
                        {t("dashboard.filter.lastMonth")}
                      </Radio>
                      {/* <Radio value={"lastYear"}>
                        {t("dashboard.filter.lastYear")}
                      </Radio> */}
                      {/* <Radio value={"custom"}>
                        {t("dashboard.filter.custom")}
                      </Radio> */}
                    </Space>
                  </Radio.Group>
                </Col>
                <Col span={1}>
                  <span
                    style={{
                      borderLeft: "1px solid grey",
                      height: "180px",
                      display: "block",
                      marginTop: 29,
                    }}
                  ></span>
                </Col>
              </Row>
            </Col>
            <Col span={20}>
              <Row>
                <Col span={8}>
                  <div>
                    <h4>{t("dashboard.filter.dateFrom")}</h4>
                  </div>
                  <Space>
                    <DatePicker
                      value={dayjs(dateFrom, "YYYY-MM-DD")}
                      disabledDate={disabledDate}
                      allowClear={false}
                      onChange={handleDateFrom}
                      format="YYYY-MM-DD"
                      popupStyle={{
                        backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
                      }}
                    />
                    {/* <TimePicker
                      value={dayjs(timeFrom, "HH:mm")}
                      //  disabled={isFixedRange}
                      onChange={onChangeTimeFrom}
                      format={"HH:mm"}
                    /> */}
                  </Space>
                </Col>
                <Col span={8}>
                  <div>
                    <h4>{t("dashboard.filter.dateTo")}</h4>
                  </div>
                  <Space>
                    <DatePicker
                      value={dayjs(dateTo, "YYYY-MM-DD")}
                      disabled={isFixedRange}
                      allowClear={false}
                      onChange={handleDateTo}
                      format="YYYY-MM-DD"
                      popupStyle={{
                        backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
                      }}
                    />
                    {/* <TimePicker
                      value={dayjs(timeTo, "HH:mm")}
                      disabled={isFixedRange}
                      onChange={onChangeTimeTo}
                      format={"HH:mm"}
                    /> */}
                  </Space>
                </Col>
                <Col span={4}>
                  <div>
                    <h4>{t("dashboard.filter.resolution")}</h4>
                  </div>
                  <Space>
                    <Select
                      placeholder={t("dashboard.filter.resolution")}
                      onChange={handleResolution}
                      value={resolution}
                      //  disabled={!showDataRange}
                      style={{ width: 170 }}
                      dropdownStyle={{
                        backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
                      }}
                    >
                      {fixedRange === "lastDay" && (
                        <Option value="hourly">
                          {t("dashboard.filter.hourly")}
                        </Option>
                      )}
                      {fixedRange === "lastWeek" && (
                        <Option value="every12h">
                          {t("dashboard.filter.every12h")}
                        </Option>
                      )}
                      {fixedRange === "lastMonth" && (
                        <Option value="daily">
                          {t("dashboard.filter.daily")}
                        </Option>
                      )}
                      {fixedRange === "lastYear" && (
                        <Option value="weekly">
                          {t("dashboard.filter.weekly")}
                        </Option>
                      )}
                      {fixedRange === "lastYear" && (
                        <Option value="monthly">
                          {t("dashboard.filter.monthly")}
                        </Option>
                      )}
                      {/* {fixedRange === "custom" && (
                        <>
                          <Option value="hourly">
                            {t("dashboard.filter.hourly")}
                          </Option>
                          <Option value="daily">
                            {t("dashboard.filter.daily")}
                          </Option>
                        </>
                      )} */}
                    </Select>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space>
                    <Button
                      onClick={handleUpdateFilter}
                      style={{ marginTop: 29, width: 100 }}
                    >
                      {t("dashboard.filter.update")}
                    </Button>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={4}>
                  <Checkbox
                    value={chartFiltering.isEnabledInstalledCapacityHistorical}
                    onChange={onChangeCheckedInstalledCapacity}
                    style={{ marginTop: 29 }}
                    // disabled={
                    //   chartFiltering.historicalFilter !== null &&
                    //   chartFiltering.historicalFilter.resolution !== "hourly"
                    // }
                  >
                    {t("dashboard.filter.installedCapacity")}
                  </Checkbox>
                </Col>
                <Col span={4}>
                  {/* {locationsReducer?.selectedLocation?.aiPoweredEnabled && ( */}
                  <Checkbox
                    value={isEnabledAI}
                    onChange={onChangeChecked}
                    style={{ marginTop: 29 }}
                  >
                    {t("dashboard.filter.aiPowerd")}
                  </Checkbox>
                  {/* )} */}
                </Col>
                <Col span={11}>
                  <div>
                    <h4>{t("dashboard.filter.models")}</h4>
                  </div>
                  <Space>
                    <Select
                      disabled={isEnabledAI}
                      mode="multiple"
                      placeholder={t("dashboard.filter.models")}
                      onChange={handleSelectModels}
                      value={selectedModels}
                      //  disabled={!showDataRange}
                      style={{ width: 550 }}
                      dropdownStyle={{
                        backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
                      }}
                    >
                      {models.map((item) => {
                        return <Option value={item.type}>{item.model}</Option>;
                      })}
                    </Select>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <Divider style={{ margin: "10px 0px" }} />
      </Panel>
    </Collapse>
  );
};
