import { Authenticated, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import { ErrorComponent, Layout, notificationProvider } from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";
import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
// import DataProvider from "./providers/data";
import DataProviderController from "./providers/data/providerController";

import { Login } from "pages/login";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { Header } from "./components/header";
import { Title } from "./components/title";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { OverViewDashboard } from "./pages/dashboards/overview";
import { ProductionDashboardShow } from "./pages/dashboards/production";
import { AuthProvider } from "./providers/auth";
import { I18nProvider } from "./providers/i18n";
import { env } from "@env";
// import { EnergyPricesShow, ROIShow } from "./pages/dashboards/economics";
import { EnergyPricesShow } from "./pages/dashboards/economics/energyPrices/show";
import { ROIShow } from "./pages/dashboards/economics/ROI/show";

// import { WeatherDashboardShow } from "./pages/dashboards/weather";
import { ForecastDashboard } from "./pages/dashboards/forecast";
import { HistoricalDashboard } from "./pages/dashboards/historical";

import {
  RenewableEnergy,
  ShowRenewableEnergy,
  EditRenewableEnergy,
} from "./pages/renewableEnergy";

import {
  CompaniesList,
  ShowCompany,
  CreateCompany,
  EditCompany,
} from "./pages/settings";

import { CreateUser, EditUser, ShowUser, UsersList } from "./pages/users";

function App() {
  // const t = useTranslate();
  const lang = localStorage.getItem("i18nextLng");
  console.log("lang", lang);

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <Refine
            dataProvider={DataProviderController(env.REACT_APP_API_URL)}
            notificationProvider={notificationProvider}
            routerProvider={routerBindings}
            authProvider={AuthProvider()}
            i18nProvider={I18nProvider()}
            resources={[
              {
                name: "dashboards",
                list: "/forecast",
                meta: {
                  label: lang === "en" ? "Dashboards" : "Cruscotti",
                },
              },
              {
                name: "forecast",
                meta: {
                  parent: "dashboards",
                  label: lang === "en" ? "Forecast" : "Previsione",
                },
                list: "/forecast",
              },
              {
                name: "historical",
                meta: {
                  parent: "dashboards",
                  label: lang === "en" ? "Historical" : "Storica",
                },
                list: "/historical",
              },
              // {
              //   name: "Weather",
              //   meta: {
              //     parent: "dashboards",
              //     label: "Weather",
              //   },
              //   list: "/weather",
              // },
              {
                name: "Economics",
                meta: {
                  label: lang === "en" ? "Economics" : "Economia",
                },
                list: "/energy-prices",
              },
              {
                name: "energy-prices",
                meta: {
                  label:
                    lang === "en" ? "Energy Prices" : "Prezzi dell'energia",
                  parent: "Economics",
                },
                list: "/energy-prices",
              },
              {
                name: "ROI",
                meta: {
                  label: lang === "en" ? "ROI" : "ROI",
                  parent: "Economics",
                },
                list: "/ROI",
              },

              {
                name: "parks",
                meta: {
                  label: lang === "en" ? "Parks" : "Parchi",
                },
                list: "/parks",
                show: "/parks/show/:id",
                edit: "/parks/edit/:id",
              },
              {
                name: "users",
                meta: {
                  label: lang === "en" ? "Users" : "Utenti",
                },
                list: "/users",
                show: "/users/show/:id",
                edit: "/users/edit/:id",
                create: "/users/create",
              },
              {
                name: "companies",
                meta: {
                  label: lang === "en" ? "Settings" : "Impostazioni",
                },
                list: "/companies",
                show: "/companies/show/:id",
                edit: "/companies/edit/:id",
                create: "/companies/create",
              },
            ]}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
          >
            <Routes>
              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                    <Layout Header={Header} Title={Title}>
                      <Outlet />
                    </Layout>
                  </Authenticated>
                }
              >
                <Route index path="/" element={<OverViewDashboard />} />
                <Route path="/production">
                  <Route index element={<ProductionDashboardShow />} />
                </Route>
                {/* <Route path="/weather">
                  <Route index element={<WeatherDashboardShow />} />
                </Route> */}
                <Route path="/forecast">
                  <Route index element={<ForecastDashboard />} />
                </Route>
                <Route path="/historical">
                  <Route index element={<HistoricalDashboard />} />
                </Route>
                <Route path="/energy-prices">
                  <Route index element={<EnergyPricesShow />} />
                </Route>
                <Route path="/ROI">
                  <Route index element={<ROIShow />} />
                </Route>
                {/* <Route path="/renewable-energy">
                  <Route index element={<RenewableEnergy />} />
                </Route> */}
                <Route
                  index
                  element={<NavigateToResource resource="parks" />}
                />
                <Route path="/parks">
                  <Route index element={<RenewableEnergy />} />
                  <Route path="show/:id" element={<ShowRenewableEnergy />} />
                  {/* <Route
                        path="edit/:id"
                        element={<AntdInferencer />}
                    /> */}
                  <Route path="edit/:id" element={<EditRenewableEnergy />} />
                </Route>
                <Route path="/companies">
                  <Route index element={<CompaniesList />} />
                  <Route path="show/:id" element={<ShowCompany />} />
                  <Route path="create" element={<CreateCompany />} />
                  <Route path="edit/:id" element={<EditCompany />} />
                </Route>
                <Route path="/users">
                  <Route index element={<UsersList />} />
                  <Route path="show/:id" element={<ShowUser />} />
                  <Route path="create" element={<CreateUser />} />
                  <Route path="edit/:id" element={<EditUser />} />
                </Route>
              </Route>
              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <NavigateToResource />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<Login />} />
              </Route>
              <Route
                element={
                  <Authenticated>
                    <Layout Header={Header} Title={Title}>
                      <Outlet />
                    </Layout>
                  </Authenticated>
                }
              >
                <Route path="*" element={<ErrorComponent />} />
              </Route>
            </Routes>
            <RefineKbar />
            <UnsavedChangesNotifier />
          </Refine>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
