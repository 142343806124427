const initial = {
  filterByDate: null,
  isEnabledAI: false,
  isEnabledAIHistorical: false,
  forecastFilter: null,
  historicalFilter: null,
  pricesFilter: null,
  heatMapFilter: null,
  isEnabledInstalledCapacityForecast: false,
  isEnabledInstalledCapacityHistorical: false,
};

export const chartFilteringReducer = (state = initial, action: any) => {
  switch (action.type) {
    case "FILLTERING_BY_DATE":
      return { ...state, filterByDate: action.payload };
    case "IS_ENABLED_AI":
      return { ...state, isEnabledAI: action.payload };
    case "IS_ENABLED_AI_HISTORICAL":
      return { ...state, isEnabledAIHistorical: action.payload };
    case "IS_ENABLED_INSTALLED_CAPACITY_FORECAST":
      return { ...state, isEnabledInstalledCapacityForecast: action.payload };
    case "IS_ENABLED_INSTALLED_CAPACITY_HISTORICAL":
      return { ...state, isEnabledInstalledCapacityHistorical: action.payload };
    case "SET_FORECAST_FILTER":
      return { ...state, forecastFilter: action.payload };
    case "SET_HISTORICAL_FILTER":
      return { ...state, historicalFilter: action.payload };
    case "SET_PRICES_FILTER":
      return { ...state, pricesFilter: action.payload };
    case "SET_HEATMAP_FILTER":
      return { ...state, heatMapFilter: action.payload };
    default:
      return state;
  }
};
