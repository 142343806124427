// @ts-nocheck

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Select, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "@refinedev/core";

const { Option } = Select;

export const SelectUnit = () => {
  const dispatch = useDispatch();
  const { units, common } = useSelector((state: any) => ({ ...state }));

  const t = useTranslate();

  useEffect(() => {
    dispatch({
      type: "SELECTED_UNIT",
      payload: "kWp",
    });
  }, []);

  const handleSelectUnit = (value) => {
    dispatch({
      type: "SELECTED_UNIT",
      payload: value,
    });
  };

  return (
    <>
      {/* <Row>
        <Col span={24}>
          <h4 style={{ color: "#fff", marginBottom: 20, marginLeft: 65 }}>
            {t("header.selectUnit")}
          </h4>
        </Col>
      </Row> */}
      <h4 style={{ color: "#fff", marginBottom: 20, marginLeft: 10 }}>
        {" "}
        {t("header.selectUnit")}
      </h4>
      <Row style={{ marginTop: -40 }}>
        <Select
          style={{ width: 250, marginLeft: 10 }}
          placeholder={"Units"}
          onChange={handleSelectUnit}
          value={units.selectedUnit}
          dropdownStyle={{
            backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
          }}
        >
          <Option key={"kWp"} value={"kWp"}>
            {"kWp"}
          </Option>
          <Option key={"MWp"} value={"MWp"}>
            {"MWp"}
          </Option>
        </Select>
      </Row>
    </>
  );
};
