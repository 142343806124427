declare global {
  interface Window {
    env: EnvType
  }
}

// change with your own variables
type EnvType = {
  PUBLIC_URL: string

  REACT_APP_NODE_ENV: string

  // Keycloak auth 
  REACT_APP_AUTH_CLIENT_ID: string;
  REACT_APP_AUTH_URL: string;
  REACT_APP_AUTH_REALM: string;

  REACT_APP_API_URL: string
  REACT_APP_WEBSOCKET_ENDPOINT: string
}
export const env: EnvType = { ...process.env, ...window.env }
