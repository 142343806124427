// @ts-nocheck
let companyArray = [
  {
    id: 1,
    name: "Company 1",
    address: {
      city: "Rome",
      state: "Rome",
      country: "Italy",
    },
    totalParks: 4,
    totalUsers: 2,
  },
];

export const getAllCompanies = () => {
  return [...new Set(companyArray)];
};

export const getOneCompany = (id: any) => {
  return companyArray.filter((x) => x.id === parseInt(id))[0];
};

export const addCompany = async (data: any) => {
  console.log("from mock create", data);

  companyArray.push({
    id: companyArray.length + 1,
    name: data.name,
    address: {
      city: data.city,
      state: data.state,
      country: data.country,
    },
    totalParks: data.totalParks,
    totalUsers: data.totalUsers,
  });

  return companyArray;
};

export const deleteCompany = (id: any) => {
  const index = companyArray.findIndex((object) => {
    return object.id === parseInt(id);
  });
  console.log("delete array", index);
  companyArray.splice(index, 1);
  return companyArray;
};

export const editCompany = (data: any, id: any) => {
  console.log("from mock edit", data);
  const index = companyArray.findIndex((object) => {
    return object.id === parseInt(id);
  });
  companyArray[index].id = parseInt(id);
  companyArray[index].name = data.name;
  companyArray[index].address.city = data.address.city;
  companyArray[index].address.state = data.address.state;
  companyArray[index].address.country = data.address.country;
  companyArray[index].totalParks = data.totalParks;
  companyArray[index].totalUsers = data.totalUsers;

  return companyArray;
};
