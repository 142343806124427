import React from 'react'
// import routerProvider from '@refinedev/react-router-v6'
import { TitleProps } from '@refinedev/core'

// const { Link } = routerProvider

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  < >
    {collapsed ? (
      <img
        src={'/logo-collapsed-light.svg'}
        alt='Logo'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '5rem',
          height: '5rem',
          padding: '0px 10px',
        }}
      />
    ) : (
      <img
        src={'/refine.svg'}
        alt='Logo'
        style={{
          width: '80%',
          padding: '12px 5px',
        }}
      />
    )}
  </>
)
