import React, { useState, useEffect } from "react";
import {
  List,
  useTable,
  EditButton,
  DeleteButton,
  ShowButton,
  Create,
  useForm,
  SaveButton,
  useSelect,
} from "@refinedev/antd";

import {
  Table,
  Modal,
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  Tabs,
  Space,
  Radio,
  Image,
  Skeleton,
} from "antd";
import { IRenewableEnergy } from "@/interfaces/RenewableEnergy";
import { RENEWABLE_ENERGY } from "../../interfaces/enum/renewableEnergyENUM";
import { EXTERNAL_ID } from "../../interfaces/enum/externalIdENUM";

import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "@refinedev/core";
import { UNITS } from "../../interfaces/enum/unitsENUM";
import { ICompany } from "@/interfaces/company";

import { Map } from "../../components/map/index";
import { CardComponent } from "../../components/card";
import { TimeZone } from "../../components/timeZones";
import { Countries } from "../../components/countries";
import { States } from "../../components/states";
import { Cities } from "../../components/cities";

import { getCountriesStatesCities } from "../../Mocks/countries/index";
import { EuroOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";

export const RenewableEnergy: React.FC = () => {
  const {
    tableProps,
    tableQueryResult: { refetch, isLoading },
  } = useTable<IRenewableEnergy>();
  const t = useTranslate();
  const dispatch = useDispatch();
  const { formProps, saveButtonProps } = useForm<IRenewableEnergy>({
    resource: "parks",
    action: "create",
  });
  const { map, common, units } = useSelector((state: any) => ({ ...state }));
  const { TabPane } = Tabs;
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  // const [externalId, setExternalId] = useState(null);
  const [years, setYears] = useState<any>([]);
  const [parks, setParks] = useState<any>([]);
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const { push } = useNavigation();
  const [isCreateNow, setIsCreateNow] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  // const handleOk = () => {
  //   setIsModalOpen(false);
  //   setIsCreateNow(true);
  //   // push("create");
  // };

  useEffect(() => {
    if (tableProps.dataSource !== undefined) {
      if (units.selectedUnit === UNITS.MWP) {
        const dataModified = tableProps.dataSource?.map((data: any) => ({
          id: data.id,
          locationName: data.locationName,
          locationType: data.locationType,
          timeZone: data.timeZone,
          installedCapacity:
            units.selectedUnit === UNITS.KWP
              ? data.installedCapacity
              : data.installedCapacity / 1000,
          unit: units.selectedUnit,
          planetCost: data.planetCost,
          operationalCost: data.operationalCost,
          yearsOfDepreciation: data.yearsOfDepreciation,
        }));
        setParks(dataModified);
      } else {
        setParks(tableProps.dataSource);
      }
    }
  }, [tableProps.dataSource, units.selectedUnit]);

  useEffect(() => {
    dispatch({
      type: "SET_LOCATIONS",
      payload: tableProps.dataSource,
    });
  }, [tableProps.dataSource, dispatch]);

  useEffect(() => {
    setLat(map.latlng?.lat);
    setLong(map.latlng?.long);
  }, [map.latlng]);

  useEffect(() => {
    for (let index = 1; index <= 30; index++) {
      let year = `${index} ${index === 1 ? "Year" : "Years"}`;
      setYears((prev: any): any => {
        return [...prev, year];
      });
    }
  }, []);

  const handleCancel = () => {
    setIsModalOpen(false);
    //  setIsCreateNow(false);
  };

  const {
    selectProps: companiesSelectProps,
    queryResult: queryResultCompanies,
  } = useSelect<ICompany>({
    resource: "companies",
    optionValue: "id",
    optionLabel: "name",
  });

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("renewableEnergy.locationName"),
      dataIndex: "locationName",
      key: "locationName",
    },
    {
      title: t("renewableEnergy.locationType"),
      dataIndex: "locationType",
      key: "locationType",
      filters: [
        {
          text: RENEWABLE_ENERGY.HUDRO,
          value: RENEWABLE_ENERGY.HUDRO,
        },
        {
          text: RENEWABLE_ENERGY.SOLAR,
          value: RENEWABLE_ENERGY.SOLAR,
        },
        {
          text: RENEWABLE_ENERGY.WIND,
          value: RENEWABLE_ENERGY.WIND,
        },
      ],
      onFilter: (value: string | number | boolean, record: any) =>
        record?.locationType.indexOf(value) === 0,
      // onFilter: (value: string, record: any): any =>
      //   // record.name.indexOf(value) === 0,
      //   console.log("filter", record, value),
    },
    {
      title: t("renewableEnergy.timeZone"),
      dataIndex: "timeZone",
      key: "timeZone",
    },
    {
      title: t("renewableEnergy.installedCapacity"),
      dataIndex: "installedCapacity",
      key: "installedCapacity",
    },
    {
      title: t("renewableEnergy.unit"),
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: t("renewableEnergy.planetCost") + " €",
      dataIndex: "planetCost",
      key: "planetCost",
    },
    {
      title: t("renewableEnergy.operationalCost") + " €",
      dataIndex: "operationalCost",
      key: "operationalCost",
    },
    {
      title: t("renewableEnergy.yearsOfDepreciation"),
      dataIndex: "yearsOfDepreciation",
      key: "yearsOfDepreciation",
    },
    {
      title: t("renewableEnergy.actions"),
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <ShowButton size="small" recordItemId={record?.id} hideText />
          <EditButton hideText size="small" recordItemId={record.id} />
          <DeleteButton
            hideText
            size="small"
            recordItemId={record.id}
            onSuccess={(data) => {
              refetch();
              console.log("data deleted", data);
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      {tableProps !== undefined ? (
        <>
          <List
            canCreate={false}
            title={
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" span={14}>
                  <h4 style={{ color: common.isDarkMode ? "#fff" : "#000" }}>
                    {t("renewableEnergy.title")}
                  </h4>
                </Col>
                <Col className="gutter-row" span={4}>
                  <Button
                    onClick={() => {
                      setIsModalOpen(true);
                      setIsCreateNow(false);
                      setCurrentTab(1);
                    }}
                  >
                    {t("renewableEnergy.create")}
                  </Button>
                </Col>
              </Row>
            }
          >
            {!isModalOpen && (
              <Map
                markerArray={tableProps?.dataSource}
                iconURL="/images/mapMarker.png"
                isDataArray={true}
              />
            )}
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              justify={"space-around"}
              align={"middle"}
            >
              <Col span={8} className="gutter-row">
                <CardComponent
                  style={{ width: 350, marginTop: 20, marginBottom: 20 }}
                >
                  <Row justify={"center"}>
                    <Col>
                      <Image
                        src="/images/wind.png"
                        preview={false}
                        width={250}
                        height={170}
                      />
                    </Col>
                  </Row>
                  <Row justify={"center"}>
                    <Col>
                      <h1>
                        #
                        {tableProps?.dataSource !== undefined &&
                          tableProps?.dataSource?.filter(
                            (x) => x.locationType === RENEWABLE_ENERGY.WIND
                          ).length}{" "}
                        {t("renewableEnergy.park")}
                      </h1>
                      <h1>
                        {tableProps?.dataSource !== undefined &&
                        units.selectedUnit === UNITS.KWP
                          ? tableProps?.dataSource
                              ?.filter(
                                (x) => x.locationType === RENEWABLE_ENERGY.WIND
                              )
                              .reduce(
                                (a, b): any =>
                                  parseFloat(a.toString()) +
                                  parseFloat(b.installedCapacity.toString()),
                                0
                              )
                              .toString()
                              .slice(0, 6)
                          : tableProps?.dataSource
                              ?.filter(
                                (x) => x.locationType === RENEWABLE_ENERGY.WIND
                              )
                              .reduce(
                                (a, b): any =>
                                  parseFloat(a.toString()) +
                                  parseFloat(b.installedCapacity.toString()) /
                                    1000,
                                0
                              )
                              .toString()
                              .slice(0, 6)}{" "}
                        {units.selectedUnit}/h
                      </h1>
                    </Col>
                  </Row>
                </CardComponent>
              </Col>
              <Col span={8} className="gutter-row">
                <CardComponent
                  style={{ width: 350, marginTop: 20, marginBottom: 20 }}
                >
                  <Row justify={"center"}>
                    <Col>
                      <Image
                        src="/images/solar.png"
                        preview={false}
                        width={200}
                        height={170}
                      />
                    </Col>
                  </Row>
                  <Row justify={"center"}>
                    <Col>
                      <h1>
                        #
                        {tableProps?.dataSource !== undefined &&
                          tableProps?.dataSource?.filter(
                            (x) => x.locationType === RENEWABLE_ENERGY.SOLAR
                          ).length}{" "}
                        {t("renewableEnergy.park")}
                      </h1>
                      <h1>
                        {tableProps?.dataSource !== undefined &&
                        units.selectedUnit === UNITS.KWP
                          ? tableProps?.dataSource
                              ?.filter(
                                (x) => x.locationType === RENEWABLE_ENERGY.SOLAR
                              )
                              .reduce(
                                (a, b): any =>
                                  parseFloat(a.toString()) +
                                  parseFloat(b.installedCapacity.toString()),
                                0
                              )
                              .toString()
                              .slice(0, 6)
                          : tableProps?.dataSource
                              ?.filter(
                                (x) => x.locationType === RENEWABLE_ENERGY.SOLAR
                              )
                              .reduce(
                                (a, b): any =>
                                  parseFloat(a.toString()) +
                                  parseFloat(b.installedCapacity.toString()) /
                                    1000,
                                0
                              )
                              .toString()
                              .slice(0, 6)}{" "}
                        {units.selectedUnit}/h
                      </h1>
                    </Col>
                  </Row>
                </CardComponent>
              </Col>
              <Col className="gutter-row">
                <CardComponent
                  style={{ width: 350, marginTop: 20, marginBottom: 20 }}
                >
                  <Row justify={"center"}>
                    <Col>
                      <Image
                        src="/images/hydro.png"
                        preview={false}
                        width={200}
                        height={170}
                      />
                    </Col>
                  </Row>
                  <Row justify={"center"}>
                    <Col>
                      <h1>
                        #
                        {tableProps?.dataSource !== undefined &&
                          tableProps?.dataSource?.filter(
                            (x) => x.locationType === RENEWABLE_ENERGY.HUDRO
                          ).length}{" "}
                        {t("renewableEnergy.park")}
                      </h1>
                      <h1>
                        {tableProps?.dataSource !== undefined &&
                        units.selectedUnit === UNITS.KWP
                          ? tableProps?.dataSource
                              ?.filter(
                                (x) => x.locationType === RENEWABLE_ENERGY.HUDRO
                              )
                              .reduce(
                                (a, b): any =>
                                  parseFloat(a.toString()) +
                                  parseFloat(b.installedCapacity.toString()),
                                0
                              )
                              .toString()
                              .slice(0, 6)
                          : tableProps?.dataSource
                              ?.filter(
                                (x) => x.locationType === RENEWABLE_ENERGY.HUDRO
                              )
                              .reduce(
                                (a, b): any =>
                                  parseFloat(a.toString()) +
                                  parseFloat(b.installedCapacity.toString()) /
                                    1000,
                                0
                              )
                              .toString()
                              .slice(0, 6)}{" "}
                        {units.selectedUnit}/h
                      </h1>
                    </Col>
                  </Row>
                </CardComponent>
              </Col>
            </Row>

            <Table
              //  {...tableProps}
              rowKey="id"
              dataSource={parks}
              columns={columns}
              loading={isLoading}
            >
              {/* <Table.Column dataIndex="id" title="ID" />
          <Table.Column
            dataIndex="locationName"
            title={t("renewableEnergy.locationName")}
          />
          <Table.Column
            dataIndex="locationType"
            title={t("renewableEnergy.locationType")}
          />
          <Table.Column
            dataIndex="timeZone"
            title={t("renewableEnergy.timeZone")}
          />
          <Table.Column
            dataIndex="installedCapacity"
            title={t("renewableEnergy.installedCapacity")}
          />
          <Table.Column dataIndex="unit" title={t("renewableEnergy.unit")} />
          <Table.Column
            dataIndex="planetCost"
            title={t("renewableEnergy.planetCost") + " €"}
          />
          <Table.Column
            dataIndex="operationalCost"
            title={t("renewableEnergy.operationalCost") + " €"}
          />
          <Table.Column
            dataIndex="yearsOfDepreciation"
            title={t("renewableEnergy.yearsOfDepreciation")}
          />
          <Table.Column<IRenewableEnergy>
            title={t("renewableEnergy.actions")}
            dataIndex="actions"
            key="actions"
            render={(_, record) => (
              <Space>
                <ShowButton size="small" recordItemId={record?.id} hideText />
                <EditButton hideText size="small" recordItemId={record.id} />
                <DeleteButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  onSuccess={(data) => {
                    refetch();
                    console.log("data deleted", data);
                  }}
                />
              </Space>
            )}
          /> */}
            </Table>
          </List>

          <Modal
            title={t("renewableEnergy.createRenewableEnergy")}
            open={isModalOpen}
            //  onOk={handleOk}
            className={common.isDarkMode ? "darkModal" : "lightModal"}
            style={{
              backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
              //  border: "1px solid white",
            }}
            bodyStyle={{
              backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
            }}
            okText={t("renewableEnergy.createNow")}
            cancelText={t("renewableEnergy.later")}
            onCancel={handleCancel}
            width={"98%"}
            destroyOnClose={true}
            footer={
              !isCreateNow && (
                <Row justify={"center"}>
                  <Button
                    onClick={() => {
                      setIsCreateNow(true);
                    }}
                  >
                    {t("renewableEnergy.createNow")}
                  </Button>
                </Row>
              )
            }
          >
            {isCreateNow ? (
              <Create
                //  saveButtonProps={saveButtonProps}
                breadcrumb={""}
                goBack={false}
                title={""}
                footerButtons={
                  <>
                    {currentTab !== 1 && (
                      <Button
                        onClick={() => {
                          setCurrentTab(currentTab - 1);
                        }}
                      >
                        {t("renewableEnergy.pervious")}
                      </Button>
                    )}
                    {currentTab !== 3 && (
                      <Button
                        onClick={() => {
                          setCurrentTab(currentTab + 1);
                        }}
                      >
                        {t("renewableEnergy.next")}
                      </Button>
                    )}

                    {currentTab === 3 && (
                      <SaveButton
                        {...saveButtonProps}
                        onClick={(e: any) => {
                          // -- your custom logic
                          //  setCurrentTab(1);

                          saveButtonProps.onClick(e);
                          console.log("save btn", e);
                        }}
                      />
                    )}
                  </>
                }
              >
                <Form
                  {...formProps}
                  layout="vertical"
                  onFinish={(values: any) => {
                    setIsModalOpen(false);
                    refetch();
                    console.log("values", values);

                    return formProps.onFinish?.({
                      ...values,
                      company: values.company.id,
                      installedCapacity: parseFloat(values.installedCapacity),
                      operationalCost: parseFloat(values.operationalCost),
                      planetCost: parseFloat(values.planetCost),
                      address: {
                        street: values.street,
                        country: values.country,
                        state: values.state,
                        city: values.city,
                        lat,
                        long,
                      },
                    });
                  }}
                >
                  <Tabs tabPosition="left" activeKey={currentTab.toString()}>
                    <TabPane tab={t("renewableEnergy.basicInfo")} key="1">
                      <Form.Item
                        label={t("renewableEnergy.locationName")}
                        name="locationName"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>

                      {isModalOpen && (
                        <Map
                          isDataArray={false}
                          iconURL="/images/mapMarker.png"
                          editableLocation={true}
                        />
                      )}
                      <Row
                        justify={"space-around"}
                        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                      >
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label={t("renewableEnergy.lat")}
                            //  name="lat"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Input
                              value={lat}
                              onChange={(e) => {
                                setLat(parseFloat(e.target.value));
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Form.Item
                            label={t("renewableEnergy.long")}
                            // name="long"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Input
                              value={long}
                              onChange={(e) => {
                                setLong(parseFloat(e.target.value));
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item
                        label={t("renewableEnergy.street")}
                        name="street"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Row
                        justify={"space-around"}
                        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                      >
                        <Col span={8} className="gutter-row">
                          <Form.Item
                            label={t("renewableEnergy.country")}
                            name="country"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Countries
                              onChange={(value) => {
                                setCountry(value);
                                console.log("value country", value);
                              }}

                              // data={getCountriesStatesCities()}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8} className="gutter-row">
                          <Form.Item
                            label={t("renewableEnergy.state")}
                            name="state"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <States
                              onChange={(value) => {
                                //   setStates(getStates(value));
                                setState(value);
                                console.log("value state", value);
                              }}
                              data={
                                country.length > 0
                                  ? getCountriesStatesCities().filter(
                                      (x: any) => x.name === country
                                    )
                                  : []
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8} className="gutter-row">
                          <Form.Item
                            label={t("renewableEnergy.city")}
                            name="city"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Cities
                              onChange={(value) => {
                                //   setStates(getStates(value));
                                console.log("value state", value);
                              }}
                              data={
                                state.length > 0
                                  ? getCountriesStatesCities()
                                      .filter((x: any) => x.name === country)[0]
                                      .state.filter(
                                        (x: any) => x.name === state
                                      )
                                  : []
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item
                        label={t("renewableEnergy.timeZone")}
                        name="timeZone"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <TimeZone
                          onChange={(value) => {
                            console.log("timeZone value", value);
                          }}
                        />
                      </Form.Item>
                    </TabPane>
                    <TabPane tab={t("renewableEnergy.systemInfo")} key="2">
                      <Form.Item
                        label={t("renewableEnergy.locationType")}
                        name="locationType"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Radio.Group
                          size="large"
                          // onChange={(e: any) => {
                          //   if (e.target.value === RENEWABLE_ENERGY.SOLAR) {
                          //     setExternalId(null);
                          //   } else {
                          //     setExternalId(null);
                          //   }
                          // }}
                        >
                          <Radio.Button
                            value={RENEWABLE_ENERGY.WIND}
                            style={{ width: 150, height: 100 }}
                          >
                            <Image
                              preview={false}
                              width={100}
                              height={50}
                              src="/images/wind.png"
                              style={{ marginTop: 20, marginLeft: 10 }}
                            />
                          </Radio.Button>
                          <Radio.Button
                            value={RENEWABLE_ENERGY.SOLAR}
                            style={{ width: 150, height: 100 }}
                          >
                            <Image
                              preview={false}
                              width={100}
                              height={50}
                              src="/images/solar.png"
                              style={{ marginTop: 20, marginLeft: 10 }}
                            />
                          </Radio.Button>
                          <Radio.Button
                            value={RENEWABLE_ENERGY.HUDRO}
                            style={{ width: 150, height: 100 }}
                            disabled={true}
                          >
                            <Image
                              preview={false}
                              width={100}
                              height={50}
                              src="/images/hydro.png"
                              style={{ marginTop: 20, marginLeft: 10 }}
                            />
                          </Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      {/* <Form.Item
                    label="Location type"
                    name="locationType"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      options={[
                        {
                          label: RENEWABLE_ENERGY.SOLAR,
                          value: RENEWABLE_ENERGY.SOLAR,
                        },
                        {
                          label: RENEWABLE_ENERGY.WIND,
                          value: RENEWABLE_ENERGY.WIND,
                        },
                        {
                          label: RENEWABLE_ENERGY.HUDRO,
                          value: RENEWABLE_ENERGY.HUDRO,
                        },
                      ]}
                    />
                  </Form.Item> */}
                      <Row
                        justify={"space-around"}
                        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                      >
                        <Col span={12} className="gutter-row">
                          <Form.Item
                            label={t("renewableEnergy.installedCapacity")}
                            name="installedCapacity"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Input type="number" />
                          </Form.Item>
                        </Col>
                        <Col span={12} className="gutter-row">
                          <Form.Item
                            label={t("renewableEnergy.unit")}
                            name="unit"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              dropdownStyle={{
                                backgroundColor: common.isDarkMode
                                  ? "#000c17"
                                  : "#fff",
                              }}
                              options={[
                                {
                                  label: "kWp",
                                  value: "kWp",
                                },
                                {
                                  label: "MWp",
                                  value: "MWp",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row
                        justify={"space-around"}
                        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                      >
                        <Col span={12} className="gutter-row">
                          <Form.Item
                            label={t("renewableEnergy.externalId")}
                            name="externalId"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              dropdownStyle={{
                                backgroundColor: common.isDarkMode
                                  ? "#000c17"
                                  : "#fff",
                              }}
                              options={[
                                {
                                  label: EXTERNAL_ID.UP_BRTHNRGNMV_4,
                                  value: EXTERNAL_ID.UP_BRTHNRGNMV_4,
                                },
                                {
                                  label: EXTERNAL_ID.UP_FERRANTI_1,
                                  value: EXTERNAL_ID.UP_FERRANTI_1,
                                },
                                {
                                  label: EXTERNAL_ID.UP_FRANZI_1,
                                  value: EXTERNAL_ID.UP_FRANZI_1,
                                },
                                {
                                  label: EXTERNAL_ID.UP_SANBASILIO_1,
                                  value: EXTERNAL_ID.UP_SANBASILIO_1,
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12} className="gutter-row">
                          <Form.Item
                            label={t("renewableEnergy.company")}
                            name={["company", "id"]}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              dropdownStyle={{
                                backgroundColor: common.isDarkMode
                                  ? "#000c17"
                                  : "#fff",
                              }}
                              {...companiesSelectProps}
                              options={queryResultCompanies?.data?.data
                                ?.filter((r: any) => r.archived === false)
                                .map((el: any) => ({
                                  label: el.name,
                                  value: el.id,
                                }))}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab={t("renewableEnergy.yieldInfo")} key="3">
                      <Row
                        justify={"space-around"}
                        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                      >
                        <Col span={12} className="gutter-row">
                          <Form.Item
                            label={t("renewableEnergy.operationalCost")}
                            name="operationalCost"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              prefix={
                                <EuroOutlined className="site-form-item-icon" />
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12} className="gutter-row">
                          <Form.Item
                            label={t("renewableEnergy.planetCost")}
                            name="planetCost"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              prefix={
                                <EuroOutlined className="site-form-item-icon" />
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24} className="gutter-row">
                          <Form.Item
                            label={t("renewableEnergy.yearsOfDepreciation")}
                            name="yearsOfDepreciation"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              dropdownStyle={{
                                backgroundColor: common.isDarkMode
                                  ? "#000c17"
                                  : "#fff",
                              }}
                              options={years.map((year: string) => ({
                                label: year,
                                value: year,
                              }))}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </TabPane>
                  </Tabs>
                </Form>
              </Create>
            ) : (
              <Row justify={"center"}>
                <Row>
                  <Col span={24}>
                    <Image
                      src="/images/renewable-energy.png"
                      preview={false}
                      width={280}
                      height={280}
                    />
                  </Col>
                </Row>
                <Col span={6}>
                  <p>
                    <span style={{ fontWeight: "bold", fontSize: 20 }}>
                      {t("renewableEnergy.createModelText1")}
                    </span>{" "}
                    {t("renewableEnergy.createModelText2")}
                  </p>
                </Col>
              </Row>
            )}
          </Modal>
        </>
      ) : (
        <>
          <Skeleton />
        </>
      )}
    </>
  );
};
