// @ts-nocheck
let energyArray = [
  {
    id: 1,
    externalId: "UP_FRANZI_1",
    locationName: "Location Wind 1",
    locationType: "WIND",
    address: {
      street: "61 Rome square",
      country: "Italy",
      state: "Rome",
      city: "Rome",
      lat: 41.99997532953544,
      long: 12.696624078032728,
    },
    timeZone: "Rome/Italy",
    installedCapacity: 16000,
    unit: "kWp",
    planetCost: 2000,
    operationalCost: 100,
    yearsOfDepreciation: "1 Year",
  },
  {
    id: 2,
    externalId: "UP_FERRANTI_1",
    locationName: "Location Wind 2",
    locationType: "WIND",
    address: {
      street: "61 Rome square",
      country: "Italy",
      state: "Rome",
      city: "Rome",
      lat: 41.99997532953544,
      long: 12.796624078032728,
    },
    timeZone: "Rome/Italy",
    installedCapacity: 54600,
    unit: "kWp",
    planetCost: 1000,
    operationalCost: 500,
    yearsOfDepreciation: "4 Years",
  },
  {
    id: 6,
    externalId: "P957",
    locationName: "Location Solar 1",
    locationType: "SOLAR",
    address: {
      street: "651 Rome square",
      country: "Italy",
      state: "Rome",
      city: "Rome",
      lat: 41.89997532953544,
      long: 12.496624078032728,
    },
    timeZone: "Rome/Italy",
    installedCapacity: 200,
    unit: "kWp",
    planetCost: 244000,
    operationalCost: 1510,
    yearsOfDepreciation: "2 Years",
  },
  {
    id: 7,
    externalId: "P962",
    locationName: "Location Solar 2 ",
    locationType: "SOLAR",
    address: {
      street: "651 Rome square",
      country: "Italy",
      state: "Rome",
      city: "Rome",
      lat: 41.79997532953544,
      long: 12.596624078032728,
    },
    timeZone: "Rome/Italy",
    installedCapacity: 150,
    unit: "kWp",
    planetCost: 21000,
    operationalCost: 1100,
    yearsOfDepreciation: "1 Year",
  },
];

export const getAllRenewableEnergy = () => {
  // energyArray = [];
  // renewableEnergy.forEach((energy) => {
  //   energyArray.push({
  //     id: energy.id,
  //     locationName: energy.locationName,
  //     locationType: energy.locationType,
  //     address: {
  //       street: energy.address.street,
  //       country: energy.address.country,
  //       region: energy.address.region,
  //       city: energy.address.city,
  //       lat: energy.address.lat,
  //       long: energy.address.long,
  //     },
  //     timeZone: energy.timeZone,
  //     installedCapacity: energy.installedCapacity,
  //     unit: energy.unit,
  //   });
  // });
  return [...new Set(energyArray)];
};

export const getOneRenewableEnergy = (id: any) => {
  return energyArray.filter((x) => x.id === parseInt(id))[0];
};

export const addRenewableEnergy = async (data: any) => {
  console.log("from mock create", data);

  //await fs.writeFile(filename, JSON.stringify(users));

  energyArray.push({
    id: energyArray.length + 10,
    externalId: data.externalId,
    locationName: data.locationName,
    locationType: data.locationType,
    address: {
      street: data.street,
      country: data.country,
      state: data.state,
      city: data.city,
      lat: data.lat,
      long: data.long,
    },
    timeZone: data.timeZone,
    installedCapacity:
      data.unit === "MWp"
        ? data.installedCapacity * 1000
        : data.installedCapacity,
    unit: data.unit === "MWp" ? "kWp" : "kWp",
    planetCost: data.planetCost,
    operationalCost: data.operationalCost,
    yearsOfDepreciation: data.yearsOfDepreciation,
  });

  return energyArray;
};

export const deleteRenewableEnergy = (id: any) => {
  const index = energyArray.findIndex((object) => {
    return object.id === parseInt(id);
  });
  console.log("delete array", index);
  energyArray.splice(index, 1);
  return energyArray;
};

export const editRenewableEnergy = (data: any, id: any) => {
  console.log("from mock edit", data);
  const index = energyArray.findIndex((object) => {
    return object.id === parseInt(id);
  });
  energyArray[index].id = parseInt(id);
  energyArray[index].locationName = data.locationName;
  energyArray[index].locationType = data.locationType;
  energyArray[index].timeZone = data.timeZone;
  energyArray[index].installedCapacity =
    data.unit === "MWp"
      ? data.installedCapacity * 1000
      : data.installedCapacity;
  energyArray[index].unit = data.unit === "MWp" ? "kWp" : "kWp";
  energyArray[index].address.street = data.address.street;
  energyArray[index].address.country = data.address.country;
  energyArray[index].address.state = data.address.state;
  energyArray[index].address.city = data.address.city;
  energyArray[index].address.lat = data.lat;
  energyArray[index].address.long = data.long;
  energyArray[index].operationalCost = data.operationalCost;
  energyArray[index].planetCost = data.planetCost;
  energyArray[index].yearsOfDepreciation = data.yearsOfDepreciation;

  return energyArray;
};
