import { AxiosInstance } from "axios";
import { stringify } from "query-string";
import { DataProvider as DataProviderType } from "@refinedev/core";
import { axiosInstance, generateSort, generateFilter } from "./utils";

import { useKeycloak } from "@react-keycloak/web";

const DataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance
): Omit<
  Required<DataProviderType>,
  "createMany" | "updateMany" | "deleteMany"
> => {
  const { keycloak } = useKeycloak();
  console.log("not mock not mock");
  const setToken = () => {
    const { token } = keycloak;
    console.log("token", token);
    httpClient.defaults.headers.common["Authorization"] = "Bearer " + token;
  };

  return {
    getList: async ({ resource, pagination, filters, sorters }) => {
      const url = `${apiUrl}/${resource}`;
      setToken();

      const { token } = keycloak;

      const { current = 1, pageSize = 10, mode = "server" } = pagination ?? {};

      const queryFilters = generateFilter(filters);

      const query: {
        _start?: number;
        _end?: number;
        _sort?: string;
        _order?: string;
      } = {};

      if (mode === "server") {
        query._start = (current - 1) * pageSize;
        query._end = current * pageSize;
      }

      const generatedSort = generateSort(sorters);
      if (generatedSort) {
        const { _sort, _order } = generatedSort;
        query._sort = _sort.join(",");
        query._order = _order.join(",");
      }

      const { data, headers } = await httpClient.get(
        `${url}?${stringify(query)}&${stringify(queryFilters)}`,
        {
          headers: {
            Authorization: "Bearer " + token, //the token is a variable which holds the token
          },
        }
      );

      const total = +headers["x-total-count"];

      return {
        data: data.data,
        total: total || data.length,
      };
    },

    getMany: async ({ resource, ids }) => {
      setToken();
      const { data } = await httpClient.get(
        `${apiUrl}/${resource}?${stringify({ id: ids })}`
      );

      return {
        data,
      };
    },

    create: async ({ resource, variables }) => {
      const url = `${apiUrl}/${resource}`;
      setToken();

      const { data } = await httpClient.post(url, variables);
      console.log("create resource", url);
      console.log("create data", data);
      return {
        data,
      };
    },

    update: async ({ resource, id, variables }) => {
      const url = `${apiUrl}/${resource}/${id}`;
      setToken();

      const { data } = await httpClient.patch(url, variables);

      return {
        data: data.data,
      };
    },

    getOne: async ({ resource, id }) => {
      const url = `${apiUrl}/${resource}/${id}`;
      setToken();

      const { data } = await httpClient.get(url);

      return {
        data: data.data,
      };
    },

    deleteOne: async ({ resource, id, variables }) => {
      const url = `${apiUrl}/${resource}/${id}`;
      setToken();

      const { data } = await httpClient.delete(url, {
        data: variables,
      });

      return {
        data: data.data,
      };
    },

    getApiUrl: () => {
      return apiUrl;
    },

    custom: async ({
      url,
      method,
      filters,
      sorters,
      payload,
      query,
      headers,
    }) => {
      let requestUrl = `${url}?`;
      setToken();

      if (sorters) {
        const generatedSort = generateSort(sorters);
        if (generatedSort) {
          const { _sort, _order } = generatedSort;
          const sortQuery = {
            _sort: _sort.join(","),
            _order: _order.join(","),
          };
          requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
        }
      }

      if (filters) {
        const filterQuery = generateFilter(filters);
        requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
      }

      if (query) {
        requestUrl = `${requestUrl}&${stringify(query)}`;
      }

      if (headers) {
        httpClient.defaults.headers = {
          ...httpClient.defaults.headers,
          ...headers,
        };
      }

      //   httpClient.defaults.headers["Authorization"] = "Bearer " + "kiro";

      let axiosResponse;
      switch (method) {
        case "put":
        case "post":
        case "patch":
          axiosResponse = await httpClient[method](url, payload);
          break;
        case "delete":
          axiosResponse = await httpClient.delete(url, {
            data: payload,
          });
          break;
        default:
          axiosResponse = await httpClient.get(requestUrl);
          break;
      }

      const { data } = axiosResponse;

      return Promise.resolve({ data });
    },
  };
};

export default DataProvider;
