const initial = {
  isDefaultDate: true,
  isDarkMode: false,
  isLoaded: false,
};

export const commonReducer = (state = initial, action: any) => {
  switch (action.type) {
    case "IS_DEFAULT_DATE":
      return { ...state, isDefaultDate: action.payload };
    case "IS_DARK_MODE":
      return { ...state, isDarkMode: action.payload };
    case "IS_LOADED_DATA":
      return { ...state, isLoaded: action.payload };
    default:
      return state;
  }
};
