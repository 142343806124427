import { Select } from "antd";
import { useTranslate } from "@refinedev/core";
import { getCountriesStatesCities } from "../../Mocks/countries";
import { useSelector } from "react-redux";

interface CountriesProps {
  onChange: (value: string) => any;
  value?: string;
}
const { Option } = Select;

export const Countries = ({ onChange, value }: CountriesProps) => {
  // const options: SelectProps["options"] = [];
  const t = useTranslate();
  const { common } = useSelector((state: any) => ({ ...state }));

  // const onChange = (value: string) => {
  //   console.log(`selected ${value}`);
  // };

  //   const onSearch = (value: string) => {
  //     console.log("search:", value);
  //   };

  //   useEffect(() => {
  //     data !== undefined &&
  //       data.forEach((item: any) => {
  //         options.push({
  //           value: item.name,
  //           label: item.name,
  //         });
  //       });
  //   }, [options]);

  return (
    <Select
      showSearch
      value={value}
      placeholder={t("renewableEnergy.country")}
      optionFilterProp="children"
      onChange={onChange}
      dropdownStyle={{
        backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
      }}
      //   onSearch={onSearch}
      //   filterOption={(input, option) =>
      //     (option?.label ?? "")
      //       .toString()
      //       .toLowerCase()
      //       .includes(input.toLowerCase())
      //   }
      //   options={options}
    >
      {getCountriesStatesCities().map((item: any) => {
        if (item.name.toLowerCase().includes(value?.toString().toLowerCase())) {
          return (
            <Option key={item.id} value={item.name}>
              {item.name}
            </Option>
          );
        } else {
          return (
            <Option key={item.id} value={item.name}>
              {item.name}
            </Option>
          );
        }
      })}
    </Select>
  );
};
