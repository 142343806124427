import { Card } from "antd";

interface CardComponentProps {
  children: any;
  style?: any;
}

export const CardComponent = ({ children, style }: CardComponentProps) => {
  return (
    <Card hoverable style={style}>
      {children}
    </Card>
  );
};
