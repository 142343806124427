// @ts-nocheck
let userArray = [
  {
    id: 1,
    firstName: "User",
    lastName: "1",
    title: "Head Of Energy Production",
    role: "Admin",
  },
  {
    id: 2,
    firstName: "USer",
    lastName: "2",
    title: "Energy Manager",
    role: "User",
  },
];

export const getAllUsers = () => {
  return [...new Set(userArray)];
};

export const getOneUser = (id: any) => {
  return userArray.filter((x) => x.id === parseInt(id))[0];
};

export const addUser = async (data: any) => {
  console.log("from mock create", data);

  userArray.push({
    id: userArray.length + 1,
    firstName: data.firstName,
    lastName: data.lastName,
    role: data.role,
    title: data.title,
  });

  return userArray;
};

export const deleteUser = (id: any) => {
  const index = userArray.findIndex((object) => {
    return object.id === parseInt(id);
  });
  console.log("delete array", index);
  userArray.splice(index, 1);
  return userArray;
};

export const editUser = (data: any, id: any) => {
  console.log("from mock edit", data);
  const index = userArray.findIndex((object) => {
    return object.id === parseInt(id);
  });
  userArray[index].id = parseInt(id);
  userArray[index].firstName = data.firstName;
  userArray[index].lastName = data.lastName;
  userArray[index].role = data.role;
  userArray[index].title = data.title;

  return userArray;
};
