import { getTimeZones } from "../../Mocks/timaZones/index";
import { Select } from "antd";
import { useTranslate } from "@refinedev/core";
import { useSelector } from "react-redux";

interface TimeZoneProps {
  onChange: (value: string) => any;
  value?: string;
}
const { Option } = Select;

export const TimeZone = ({ onChange, value }: TimeZoneProps) => {
  // const options: SelectProps["options"] = [];
  const t = useTranslate();
  const { common } = useSelector((state: any) => ({ ...state }));

  //   const onChange = (value: string) => {
  //     console.log(`selected ${value}`);
  //   };

  // const onSearch = (value: string) => {
  //   console.log("search:", value);
  // };

  // useEffect(() => {
  //   getTimeZones().forEach((item: any) => {
  //     options.push({
  //       value: item.value,
  //       label: item.label,
  //     });
  //   });
  // }, [options]);

  return (
    <Select
      showSearch
      value={value}
      placeholder={t("renewableEnergy.timeZone")}
      optionFilterProp="children"
      onChange={onChange}
      dropdownStyle={{
        backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
      }}

      // onSearch={onSearch}
      // filterOption={(input, option) =>
      //   (option?.label ?? "")
      //     .toString()
      //     .toLowerCase()
      //     .includes(input.toLowerCase())
      // }
    >
      {getTimeZones().map((item: any) => {
        if (
          item.label.toLowerCase().includes(value?.toString().toLowerCase())
        ) {
          return (
            <Option key={item.id} value={item.value}>
              {item.label}
            </Option>
          );
        } else {
          return (
            <Option key={item.id} value={item.value}>
              {item.label}
            </Option>
          );
        }
      })}
    </Select>
  );
};
