import { DateTime } from "luxon";
import dataP957 from "../data/SolarP957.json";
import dataP962 from "../data/SolarP962.json";
import dataFERRANTI from "../data/windFERRANTI.json";
import dataFRANZI from "../data/windFRANZI.json";
export const roundTimestamp = (timestamp: string, interval: string) => {
  // const minute = Number(timestamp.slice(14, 16));
  const hour = Number(timestamp.slice(11, 13));

  let roundedMinute;
  let roundedHour;
  if (interval === "12h") {
    // Round to the nearest 3 hours
    roundedMinute = 0;
    roundedHour = Math.floor(hour / 12) * 12;
  } else {
    // Invalid interval specified
    throw new Error(
      "Invalid rounding interval. Supported intervals: 15m, 8h, 3h"
    );
  }

  const roundedTimestamp =
    timestamp.slice(5, 10) +
    " " +
    ("0" + roundedHour).slice(-2) +
    ":" +
    ("0" + roundedMinute).slice(-2);

  return roundedTimestamp;
};
export const getHistoricalData = (
  dateFrom: string,
  dateTo: string,
  resolution: string,
  planetId: string,
  models: string[],
  aiPowerdForecast: boolean
) => {
  const historicalArr: any[] = [];
  console.log(
    "historical data",
    models,
    dateFrom,
    dateTo,
    planetId,
    resolution,
    aiPowerdForecast
  );
  // if (dateFrom !== "" && dateTo !== "") {
  //   const finalDateFrom = new Date(dateFrom);
  //   const finalDateTo = new Date(dateTo);

  //   if (planetId === "P957") {
  //     for (const item of dataP957) {
  //       if (
  //         finalDateFrom >= new Date(item.timestamp) &&
  //         finalDateTo <= new Date(item.timestamp)
  //       ) {
  //         item.type === "activePower" &&
  //           historicalArr.push({
  //             id: item.id,
  //             externalId: item.externalId,
  //             timestamp: item.timestamp,
  //             type: item.type,
  //             value: parseFloat(item.value),
  //           });
  //       }
  //     }
  //   } else if (planetId === "P962") {
  //     for (const item of dataP962) {
  //       if (
  //         finalDateFrom >= new Date(item.timestamp) &&
  //         finalDateTo <= new Date(item.timestamp)
  //       ) {
  //         item.type === "activePower" &&
  //           historicalArr.push({
  //             id: item.id,
  //             externalId: item.externalId,
  //             timestamp: item.timestamp,
  //             type: item.type,
  //             value: parseFloat(item.value),
  //           });
  //       }
  //     }
  //   } else if (planetId === "UP_FRANZI_1") {
  //     for (const item of dataFRANZI) {
  //       if (
  //         finalDateFrom >= new Date(item.timestamp) &&
  //         finalDateTo <= new Date(item.timestamp)
  //       ) {
  //         item.type === "activePower" &&
  //           historicalArr.push({
  //             id: item.locationId,
  //             externalId: item.externalId,
  //             timestamp: item.timestamp,
  //             type: item.type,
  //             value: parseFloat(item.value),
  //           });
  //       }
  //     }
  //   } else {
  //     for (const item of dataFERRANTI) {
  //       if (
  //         finalDateFrom >= new Date(item.timestamp) &&
  //         finalDateTo <= new Date(item.timestamp)
  //       ) {
  //         item.type === "activePower" &&
  //           historicalArr.push({
  //             id: item.locationId,
  //             externalId: item.externalId,
  //             timestamp: item.timestamp,
  //             type: item.type,
  //             value: parseFloat(item.value),
  //           });
  //       }
  //     }
  //   }
  // }
  if (dateFrom !== "" && dateTo !== "") {
    let split: any = {};

    const data: any[] =
      planetId === "UP_FERRANTI_1"
        ? dataFERRANTI
        : planetId === "UP_FRANZI_1"
        ? dataFRANZI
        : planetId === "P957"
        ? dataP957
        : planetId === "P962"
        ? dataP962
        : [];

    data.forEach((item: any) => {
      let timestamp = "";
      if (resolution === "every12h") {
        const luxonTimestamp = DateTime.fromISO(item.timestamp, {
          zone: "utc",
        });
        const timestampCompare = luxonTimestamp.toISO() ?? "";
        if (timestampCompare > dateFrom || timestampCompare < dateTo) return;

        timestamp = roundTimestamp(
          DateTime.fromISO(item.timestamp, { zone: "utc" }).toISO() ?? "",
          "12h"
        );
      } else if (resolution === "hourly") {
        const luxonTimestamp = DateTime.fromISO(item.timestamp, {
          zone: "utc",
        });
        const timestampCompare = luxonTimestamp.toISO() ?? "";

        if (timestampCompare > dateFrom || timestampCompare < dateTo) return;

        timestamp =
          DateTime.fromISO(item.timestamp, { zone: "utc" })
            .startOf("hour")
            .toFormat("MM-dd HH:mm") ?? "";
      } else if (resolution === "daily") {
        const luxonTimestamp = DateTime.fromISO(item.timestamp, {
          zone: "utc",
        });
        const timestampCompare = luxonTimestamp.toISODate() ?? "";
        if (timestampCompare > dateFrom || timestampCompare < dateTo) return;
        timestamp = `${luxonTimestamp.toFormat("MM-dd")}`;
      } else if (resolution === "monthly") {
        const luxonTimestamp = DateTime.fromISO(item.timestamp, {
          zone: "utc",
        });
        const month =
          luxonTimestamp.month < 10
            ? `0${luxonTimestamp.month}`
            : luxonTimestamp.month;

        timestamp = `${luxonTimestamp.year}-${month}`;
        if (timestamp > dateFrom || timestamp < dateTo) return;
      }

      if (resolution === "weekly") {
        const luxonTimestamp = DateTime.fromISO(item.timestamp, {
          zone: "utc",
        });
        const timestampCompare = luxonTimestamp.toISODate() ?? "";
        if (timestampCompare > dateFrom || timestampCompare < dateTo) return;
        // const month =
        //   luxonTimestamp.month < 10
        //     ? `0${luxonTimestamp.month}`
        //     : luxonTimestamp.month;

        timestamp = `${luxonTimestamp.startOf("week").toISODate()}`;
      }

      const object = `${timestamp}`;
      const value =
        planetId === "UP_FERRANTI_1" || planetId === "UP_FRANZI_1"
          ? item.value * 1000
          : item.value;
      if (split[object]) {
        split[object].push({ ...item, value });
      } else {
        split[object] = [];
        split[object].push({ ...item, value });
      }
    });

    // let newModels: any[] = ["activePower"];
    // const extraModel =
    //   planetId === "UP_FERRANTI_1" || planetId === "UP_FRANZI_1"
    //     ? "expectedPowerModel58"
    //     : "expectedPowerModelA";
    // newModels.push(extraModel);
    let newModels: any[] = [];
    if (aiPowerdForecast)
      newModels =
        planetId === "UP_FERRANTI_1" || planetId === "UP_FRANZI_1"
          ? ["expectedPowerModel40"]
          : ["expectedPowerModelA"];
    else if (typeof models === "string") newModels = [models];
    else newModels = models;

    newModels.forEach((model) => {
      const modelTrim = model.trim();
      console.log("modelTrim", modelTrim);

      Object.keys(split).forEach((key) => {
        historicalArr.push({
          type: model,
          value: split[key].reduce(
            (previousValue: number, currentValue: any) => {
              if (currentValue.type === modelTrim)
                return +previousValue + +currentValue.value;
              return +previousValue;
            },
            0
          ),
          timestamp: key,
        });
      });
    });
  }
  return {
    data: historicalArr,
  };
};
