import { useLogin, useTranslate } from "@refinedev/core";
import { Button } from "antd";

export const Login: React.FC = () => {
  const { mutate: login } = useLogin();

  const t = useTranslate();

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button onClick={() => login({})}>
        {t("pages.login.signin", "Sign in")}
      </Button>
    </div>
  );
};
