import React, { useEffect, useState } from "react";
import { Show, ShowButton, useSelect } from "@refinedev/antd";
import {
  Typography,
  Row,
  Col,
  Space,
  Table,
  Button,
  Modal,
  Select,
} from "antd";
import { useShow, useNavigation, useCustom } from "@refinedev/core";
import { IRenewableEnergy } from "@/interfaces/RenewableEnergy";
import { Map } from "../../components/map";
import { useDispatch } from "react-redux";
import { useTranslate } from "@refinedev/core";
import { UNITS } from "../../interfaces/enum/unitsENUM";
import { useSelector } from "react-redux";
import { IUser } from "@/interfaces/user";
import { env } from "@env";

const { Title, Text } = Typography;

export const ShowRenewableEnergy: React.FC = () => {
  const dispatch = useDispatch();
  const { queryResult } = useShow<IRenewableEnergy>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const { show } = useNavigation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>("");
  const [isSelectedUser, setIsSelectedUser] = useState(false);
  const { common } = useSelector((state: any) => ({ ...state }));

  const t = useTranslate();
  const { units } = useSelector((state: any) => ({
    ...state,
  }));
  console.log("record", record);

  const { selectProps: usersSelectProps, queryResult: queryResultUsers } =
    useSelect<IUser>({
      resource: "users",
      optionValue: "id",
      optionLabel: "email",
    });

  useEffect(() => {
    record !== undefined &&
      dispatch({
        type: "SET_LNGLAT",
        payload: {
          lat: record?.address.lat,
          long: record?.address.long,
        },
      });
  }, [record, dispatch]);

  const {
    data: users,
    isLoading: isUsersLoading,
    refetch,
  } = useCustom({
    url: `${env.REACT_APP_API_URL}/parks/${record?.id}/users`,
    method: "get",
  });

  const { data: addUserToPark } = useCustom({
    url: `${env.REACT_APP_API_URL}/parks/${record?.id}/users/${selectedUser}`,
    method: "post",
    successNotification(data, values, resource): any {
      console.log("addUserToPark", addUserToPark);

      refetch();
    },
    queryOptions: {
      enabled: selectedUser.length > 0 && isSelectedUser === true,
    },
  });

  const showModal = () => {
    setIsModalOpen(true);
    setIsSelectedUser(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsSelectedUser(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Show
        isLoading={isLoading}
        title={t("renewableEnergy.showRenewableEnergy")}
      >
        <Row>
          <Col>
            <Space align="baseline">
              <Title level={5}>Id: </Title>
              <Text>{record?.id}</Text>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={5}>
            <Space align="baseline">
              <Title level={5}>{t("renewableEnergy.locationName")}: </Title>
              <Text>{record?.locationName}</Text>
            </Space>
          </Col>
          <Col span={5}>
            <Space align="baseline">
              <Title level={5}>{t("renewableEnergy.locationType")}: </Title>
              <Text>{record?.locationType}</Text>
            </Space>
          </Col>
          <Col span={5}>
            <Space align="baseline">
              <Title level={5}>{t("renewableEnergy.timeZone")}: </Title>
              <Text>{record?.timeZone}</Text>
            </Space>
          </Col>
          <Col span={5}>
            <Space align="baseline">
              <Title level={5}>
                {t("renewableEnergy.installedCapacity")}:{" "}
              </Title>
              <Text>
                {units.selectedUnit === UNITS.MWP
                  ? `${
                      record?.installedCapacity !== undefined &&
                      (record?.installedCapacity / 1000)
                        .toString()
                        .concat(" ")
                        .concat(`${units.selectedUnit}`)
                    }`
                  : `${record?.installedCapacity
                      .toString()
                      .concat(" ")
                      .concat(`${units.selectedUnit}`)}`}
              </Text>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={5}>
            <Space align="baseline">
              <Title level={5}>{t("renewableEnergy.street")}: </Title>
              <Text>{record?.address.street}</Text>
            </Space>
          </Col>
          <Col span={5}>
            <Space align="baseline">
              <Title level={5}>{t("renewableEnergy.country")}: </Title>
              <Text>{record?.address.country}</Text>
            </Space>
          </Col>
          <Col span={5}>
            <Space align="baseline">
              <Title level={5}>{t("renewableEnergy.state")}: </Title>
              <Text>{record?.address.state}</Text>
            </Space>
          </Col>
          <Col span={5}>
            <Space align="baseline">
              <Title level={5}>{t("renewableEnergy.city")}: </Title>
              <Text>{record?.address.city}</Text>
            </Space>
          </Col>
          <Col span={5}>
            <Space align="baseline">
              <Title level={5}>{t("renewableEnergy.lat")}: </Title>
              <Text>{record?.address.lat}</Text>
            </Space>
          </Col>
          <Col span={5}>
            <Space align="baseline">
              <Title level={5}>{t("renewableEnergy.long")}: </Title>
              <Text>{record?.address.long}</Text>
            </Space>
          </Col>
          <Col span={5}>
            <Space align="baseline">
              <Title level={5}>{t("renewableEnergy.planetCost")}: </Title>
              <Text>{record?.planetCost} €</Text>
            </Space>
          </Col>
          <Col span={5}>
            <Space align="baseline">
              <Title level={5}>{t("renewableEnergy.operationalCost")}: </Title>
              <Text>{record?.operationalCost} €</Text>
            </Space>
          </Col>
          <Col span={5}>
            <Space align="baseline">
              <Title level={5}>
                {t("renewableEnergy.yearsOfDepreciation")}:{" "}
              </Title>
              <Text>{record?.yearsOfDepreciation}</Text>
            </Space>
          </Col>
        </Row>
        <Map
          isDataArray={false}
          iconURL="/images/mapMarker.png"
          editableLocation={false}
        />
        <Table
          loading={isUsersLoading}
          dataSource={users?.data?.data}
          rowKey="id"
          style={{ marginTop: 15 }}
          caption={
            <Button onClick={showModal}>
              {t("renewableEnergy.addUserToPark")}
            </Button>
          }
        >
          <Table.Column dataIndex="id" title="ID" />
          <Table.Column dataIndex="firstName" title={t("user.firstName")} />
          <Table.Column dataIndex="lastName" title={t("user.lastName")} />
          <Table.Column dataIndex="username" title={t("user.username")} />
          <Table.Column
            dataIndex="email"
            title={t("pages.login.fields.email")}
          />
          {/* <Table.Column dataIndex="role" title={t("user.role")} />
            <Table.Column dataIndex="title" title={t("user.postion")} /> */}
          <Table.Column<IUser>
            title={t("renewableEnergy.actions")}
            dataIndex="actions"
            key="actions"
            render={(_, record) => (
              <Space>
                <ShowButton
                  size="small"
                  recordItemId={record?.id}
                  hideText
                  onClick={() => {
                    show("users", record.id);
                  }}
                />
              </Space>
            )}
          />
        </Table>
      </Show>
      <Modal
        title={t("renewableEnergy.addUserToPark")}
        open={isModalOpen}
        onOk={handleOk}
        okText={t("renewableEnergy.addUser")}
        onCancel={handleCancel}
        style={{ backgroundColor: common.isDarkMode ? "#000c17" : "#fff" }}
        bodyStyle={{ backgroundColor: common.isDarkMode ? "#000c17" : "#fff" }}
      >
        <Row>
          <Col span={24} className="gutter-row">
            <Select
              placeholder={t("company.selectUser")}
              size="large"
              onChange={(value) => {
                setSelectedUser(value);
              }}
              style={{ width: 470 }}
              dropdownStyle={{
                backgroundColor: common.isDarkMode ? "#000c17" : "#fff",
              }}
              {...usersSelectProps}
              options={queryResultUsers?.data?.data
                ?.filter((r: any) => r.firstName.length > 2)
                .map((el: any) => ({
                  label: el.email,
                  value: el.id,
                }))}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};
