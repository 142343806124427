import React, { useState, useEffect } from "react";
import { HistoricalFiltering } from "../../../components/dashboard/Filter/historical.tsx";
import { MultiLinePlotAnimation } from "../../../components/dashboard";
import { useSelector, useDispatch } from "react-redux";
import { useCustom, useTranslate } from "@refinedev/core";
import { env } from "@env";
import { CardComponent } from "../../../components/card";
import { Image, Row, Col, Table, Skeleton } from "antd";
import { LoadingAndEmpty } from "../../../components/LoadingComponent";
import { UNITS } from "../../../interfaces/enum/unitsENUM";

export const HistoricalDashboard = () => {
  const [historical, setHistorical] = useState([]);
  const [kpisChart, setKpisChart] = useState([]);
  const [aiPowerdForecast, setAiPowerdForecast] = useState([]);

  const [KPIs, setKPIs] = useState([]);
  // const [maxNum, setMaxNum] = useState<number>(0);
  const [installedCapacity, setInstalledCapacity] = useState<number>(0);

  // const [isMAE, setIsMAE] = useState(true);
  const t = useTranslate();
  const { chartFiltering, locationsReducer, units, common } = useSelector(
    (state: any) => ({
      ...state,
    })
  );

  const dispatch = useDispatch();

  const { data: historicalData, isLoading } = useCustom({
    url: `${env.REACT_APP_API_URL}/historical`,
    method: "get",
    config: {
      query: {
        dateFrom:
          chartFiltering.historicalFilter !== null &&
          chartFiltering.historicalFilter.dateFrom,
        dateTo:
          chartFiltering.historicalFilter !== null &&
          chartFiltering.historicalFilter.dateTo,
        resolution:
          chartFiltering.historicalFilter !== null &&
          chartFiltering.historicalFilter.resolution,
        externalId:
          locationsReducer.selectedLocation !== null &&
          locationsReducer?.selectedLocation?.externalId,
        models:
          chartFiltering.historicalFilter !== null &&
          chartFiltering.historicalFilter.models.toString(),

        // aiPowerdForecast:
        //   chartFiltering.historicalFilter !== null &&
        //   chartFiltering.historicalFilter.isEnabledAI,
      },
    },
    queryOptions: {
      enabled:
        chartFiltering.historicalFilter !== null &&
        locationsReducer.locations.length > 0 &&
        locationsReducer?.selectedLocation !== null,
    },
  });

  console.log("historical", historicalData?.data?.data?.data);
  console.log("historical isLoading", isLoading);
  console.log("common.isLoaded", common.isLoaded);

  useEffect(() => {
    // console.log("out Loaded", isloaded);
    if (historicalData?.data.length === 0) {
      setHistorical([]);
    } else {
      if (historicalData?.data !== undefined) {
        if (units.selectedUnit === UNITS.MWP) {
          const dataModified = historicalData?.data?.data?.data
            ?.filter((x: any) => x.type !== "aiPowerdForecast")
            .map((data: any) => ({
              id: data.id,
              externalId: data.externalId,
              timestamp: data.timestamp,
              type: data.type,
              value: data.value / 1000,
            }));
          const dataModifiedAi = historicalData?.data?.data?.data
            ?.filter((x: any) => x.type === "aiPowerdForecast")
            .map((data: any) => ({
              id: data.id,
              externalId: data.externalId,
              timestamp: data.timestamp,
              type: data.type,
              value: data.value / 1000,
            }));
          const dataModifiedKpisChart =
            historicalData?.data?.data?.KPIsChart?.map((data: any) => ({
              //  id: data.id,
              //  externalId: data.externalId,
              timestamp: data.timestamp,
              type: data.type,
              value: data.value / 1000,
            }));
          // const max: number = Math.max(
          //   ...historicalData?.data?.data?.data.map((o: any) => o.value / 1000)
          // );
          // setMaxNum(max);
          setHistorical(dataModified);
          setAiPowerdForecast(dataModifiedAi);
          setKpisChart(dataModifiedKpisChart);
          dispatch({
            type: "IS_LOADED_DATA",
            payload: false,
          });
          if (
            historicalData?.data?.data?.KPIs[0]?.enabled !== undefined ||
            historicalData?.data?.data?.KPIs[0]?.enabled === false
          ) {
            setKPIs([]);
          } else {
            setKPIs(historicalData?.data?.data?.KPIs);
          }
        } else {
          // const max: number = Math.max(
          //   ...historicalData?.data?.data?.data.map((o: any) => o.value)
          // );

          const dataModified = historicalData?.data?.data?.data
            ?.filter((x: any) => x.type !== "aiPowerdForecast")
            .map((data: any) => ({
              id: data.id,
              externalId: data.externalId,
              timestamp: data.timestamp,
              type: data.type,
              value: data.value,
            }));
          const dataModifiedAi = historicalData?.data?.data?.data
            ?.filter((x: any) => x.type === "aiPowerdForecast")
            .map((data: any) => ({
              id: data.id,
              externalId: data.externalId,
              timestamp: data.timestamp,
              type: data.type,
              value: data.value,
            }));
          setAiPowerdForecast(dataModifiedAi);
          //  setMaxNum(max);
          setHistorical(dataModified);
          setKpisChart(historicalData?.data?.data?.KPIsChart);
          dispatch({
            type: "IS_LOADED_DATA",
            payload: false,
          });
          if (
            historicalData?.data?.data?.KPIs[0]?.enabled !== undefined ||
            historicalData?.data?.data?.KPIs[0]?.enabled === false
          ) {
            setKPIs([]);
          } else {
            setKPIs(historicalData?.data?.data?.KPIs);
          }
        }
        //  setKpisChart(historicalData?.data?.data?.KPIsChart);
        if (historical.length !== 0) {
          dispatch({
            type: "IS_LOADED_DATA",
            payload: false,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    historicalData?.data,
    units.selectedUnit,
    historicalData?.data?.data?.KPIs,
  ]);

  useEffect(() => {
    if (chartFiltering.historicalFilter !== null) {
      if (chartFiltering.historicalFilter.resolution === "every12h") {
        setInstalledCapacity(
          locationsReducer?.selectedLocation?.installedCapacity * 12
        );
      } else if (chartFiltering.historicalFilter.resolution === "daily") {
        setInstalledCapacity(
          locationsReducer?.selectedLocation?.installedCapacity * 24
        );
      } else {
        setInstalledCapacity(
          locationsReducer?.selectedLocation?.installedCapacity
        );
      }
    }
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    chartFiltering.historicalFilter !== null &&
      chartFiltering.historicalFilter.resolution,
  ]);

  // const asyncFetch = () => {
  //   fetch(
  //     "https://gw.alipayobjects.com/os/bmw-prod/e00d52f4-2fa6-47ee-a0d7-105dd95bde20.json"
  //   )
  //     .then((response) => response.json())
  //     .then((json) => setData(json))
  //     .catch((error) => {
  //       console.log("fetch data failed", error);
  //     });
  // };
  const config = {
    data: !chartFiltering.isEnabledAIHistorical ? historical : aiPowerdForecast,
    xField: "timestamp",
    yField: "value",
    seriesField: "type",
    yAxis: {
      label: {
        formatter: (v: any) => `${v / 1} ${units.selectedUnit}`,
      },
      max:
        units.selectedUnit === UNITS.KWP
          ? installedCapacity
          : installedCapacity / 1000,
    },
    legend: {
      position: "top",
      itemName: {
        formatter: (text: any) => {
          const splitedLable = text.split("Power");
          return (
            splitedLable[0].charAt(0).toUpperCase() +
            splitedLable[0].slice(1) +
            " Power " +
            splitedLable[1]
          );
        },
      },
    },
    smooth: true,
    lineStyle: (line: any) => {
      if (line?.type === "activePower") {
        return {
          lineWidth: 6,
        };
      }
    },
    // line: {
    //   size: 3,
    // },
    // @TODO 后续会换一种动画方式
    // state: {
    //   active: {
    //     style: {
    //       lineWidth: 10,
    //       fillOpacity: 0.65,
    //     },
    //   },
    // },
    // interactions: [
    //   {
    //     type: "element-single-selected",
    //   },
    //   {
    //     type: "element-active",
    //   },
    // ],
    annotations: [
      {
        type: "line",
        start: [
          chartFiltering.isEnabledInstalledCapacityHistorical && "max",
          chartFiltering.isEnabledInstalledCapacityHistorical &&
            (units.selectedUnit === UNITS.KWP
              ? installedCapacity
              : installedCapacity / 1000),
        ],
        end: [
          chartFiltering.isEnabledInstalledCapacityHistorical && "min",
          chartFiltering.isEnabledInstalledCapacityHistorical &&
            (units.selectedUnit === UNITS.KWP
              ? installedCapacity
              : installedCapacity / 1000),
        ],

        style: {
          stroke: "#F4664A",
          lineWidth: 4,
          lineDash: [2, 2],
        },
      },
    ],
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
    slider: {
      start: 0,
      end: 1,
      textStyle: {
        fill: "white",
      },
    },
  };

  const configKPIsChart = {
    data: kpisChart,
    xField: "timestamp",
    yField: "value",
    seriesField: "type",
    yAxis: {
      label: {
        formatter: (v: any) => `${v / 1}  ${units.selectedUnit}`,
      },
    },
    legend: {
      position: "top",
      itemName: {
        formatter: (text: any) => {
          const splitedLable = text.split("Power");
          return (
            splitedLable[0].charAt(0).toUpperCase() +
            splitedLable[0].slice(1) +
            " Power " +
            splitedLable[1]
          );
        },
      },
    },
    smooth: true,
    // animation: {
    //   appear: {
    //     animation: "path-in",
    //     duration: 5000,
    //   },
    // },
    slider: {
      start: 0,
      end: 1,
      textStyle: {
        fill: "white",
      },
    },
    point: {
      shape: "circle",
    },
  };

  // const configKPIsChartNMAE = {
  //   data: kpisChart,
  //   xField: "timestamp",
  //   yField: "value",
  //   seriesField: "type",
  //   yAxis: {
  //     label: {
  //       formatter: (v: any) => `${v / 1} %`,
  //     },
  //   },
  //   legend: {
  //     position: "top",
  //     itemName: {
  //       formatter: (text: any) => {
  //         const splitedLable = text.split("Power");
  //         return (
  //           splitedLable[0].charAt(0).toUpperCase() +
  //           splitedLable[0].slice(1) +
  //           " Power " +
  //           splitedLable[1]
  //         );
  //       },
  //     },
  //   },
  //   smooth: true,
  //   // animation: {
  //   //   appear: {
  //   //     animation: "path-in",
  //   //     duration: 5000,
  //   //   },
  //   // },
  //   slider: {
  //     start: 0,
  //     end: 1,
  //     textStyle: {
  //       fill: "white",
  //     },
  //   },
  //   point: {
  //     shape: "circle",
  //   },
  // };

  return (
    <>
      {historicalData !== undefined ? (
        <>
          <CardComponent style={{ width: 600, marginBottom: 10 }}>
            {locationsReducer.selectedLocation !== null && (
              <Row justify={"start"}>
                <Col>
                  <Image
                    src={`/images/${
                      locationsReducer?.selectedLocation?.locationType ===
                      "wind"
                        ? "wind"
                        : "solar"
                    }.png`}
                    preview={false}
                    width={150}
                    height={100}
                  />
                </Col>
                <Col style={{ marginLeft: 20 }}>
                  <Row>
                    <h4>
                      {t("renewableEnergy.timeZone")}:{" "}
                      <span style={{ fontSize: 12 }}>
                        {locationsReducer?.selectedLocation?.timeZone}
                      </span>{" "}
                    </h4>
                  </Row>
                  <Row>
                    <h4>
                      {t("renewableEnergy.address")}:{" "}
                      <span style={{ fontSize: 12 }}>
                        {" "}
                        {`${locationsReducer?.selectedLocation?.address.street}, ${locationsReducer?.selectedLocation?.address.city}, ${locationsReducer?.selectedLocation?.address.state}, ${locationsReducer?.selectedLocation?.address.country}`}
                      </span>
                    </h4>
                  </Row>
                  <Row>
                    <h4>
                      {t("renewableEnergy.installedCapacity")}:{" "}
                      <span>{`${
                        units.selectedUnit === UNITS.KWP
                          ? locationsReducer?.selectedLocation
                              ?.installedCapacity
                          : locationsReducer?.selectedLocation
                              ?.installedCapacity / 1000
                      } `}</span>
                      {units.selectedUnit}
                    </h4>
                  </Row>
                </Col>
              </Row>
            )}
          </CardComponent>
          <HistoricalFiltering />

          {KPIs.length !== 0 && (
            <Table
              rowKey="id"
              dataSource={KPIs}
              pagination={false}
              size="small"
              style={{ marginBottom: 15 }}
              loading={common.isLoaded}
            >
              <Table.Column dataIndex={"model"} title={"models"} />
              <Table.ColumnGroup title={"Default"}>
                <Table.Column title={"MAE"} dataIndex={"MAEP"} key="MAE" />
                <Table.Column title={"nMAE"} dataIndex={"nMAEP"} key="nMAE" />
              </Table.ColumnGroup>
              {chartFiltering.historicalFilter !== null &&
                chartFiltering.historicalFilter.resolution === "hourly" && (
                  <>
                    <Table.ColumnGroup title={"horizon 1"}>
                      <Table.Column
                        title={"MAE"}
                        dataIndex={"MAEH1"}
                        key="MAE"
                      />
                      <Table.Column
                        title={"nMAE"}
                        dataIndex={"nMAEH1"}
                        key="nMAE"
                      />
                    </Table.ColumnGroup>
                    <Table.ColumnGroup title={"horizon 2"}>
                      <Table.Column
                        title={"MAE"}
                        dataIndex={"MAEH2"}
                        key="MAE"
                      />
                      <Table.Column
                        title={"nMAE"}
                        dataIndex={"nMAEH2"}
                        key="nMAE"
                      />
                    </Table.ColumnGroup>
                    <Table.ColumnGroup title={"horizon 3"}>
                      <Table.Column
                        title={"MAE"}
                        dataIndex={"MAEH3"}
                        key="MAE"
                      />
                      <Table.Column
                        title={"nMAE"}
                        dataIndex={"nMAEH3"}
                        key="nMAE"
                      />
                    </Table.ColumnGroup>
                  </>
                )}
            </Table>
          )}
          <LoadingAndEmpty
            loading={common.isLoaded}
            //  loading={false}
            bordered={false}
            data={historical}
            title={t("sider.historical.title")}
          >
            <MultiLinePlotAnimation {...config} />
          </LoadingAndEmpty>
          <br />
          <LoadingAndEmpty
            loading={common.isLoaded}
            //  loading={false}

            bordered={false}
            data={kpisChart}
            title={
              // <div>
              //   {t("sider.kpi.title")}{" "}
              //   <Button
              //     style={{ marginLeft: 10 }}
              //     onClick={() => {
              //       setIsMAE(!isMAE);
              //     }}
              //   >
              //     {isMAE ? "nMAE" : "MAE"}
              //   </Button>
              // </div>
              t("sider.kpi.title")
            }
          >
            <MultiLinePlotAnimation {...configKPIsChart} />
            {/* {isMAE ? (
              <MultiLinePlotAnimation {...configKPIsChart} />
            ) : (
              <MultiLinePlotAnimation {...configKPIsChartNMAE} />
            )} */}
          </LoadingAndEmpty>
        </>
      ) : (
        <>
          <HistoricalFiltering />
          <Skeleton />
        </>
      )}
    </>
  );
};
