import { useEffect, useRef } from "react";
import { Row, Col } from "antd";
import { Icon } from "leaflet";
// import * as ELG from "esri-leaflet-geocoder";
import L from "leaflet";

import "./style.css";
import "leaflet/dist/leaflet.css";
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";

import { useSelector, useDispatch } from "react-redux";
interface MapProps {
  markerArray?: any;
  iconURL?: string;
  isDataArray: boolean;
  editableLocation?: boolean;
}

export const Map = ({
  markerArray,
  iconURL,
  isDataArray,
  editableLocation,
}: MapProps) => {
  const { common, map } = useSelector((state: any) => ({ ...state }));
  const dispatch = useDispatch();
  const mapRef = useRef(null);

  const customIcon = new Icon({
    iconUrl: iconURL,
    iconSize: [38, 38],
  });

  const mapURL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

  useEffect(() => {
    const mapData: any = L.map("map", {
      // center: [41.89997532953544, 12.496624078032728],
      center: [41.05307, 15.57546],
      zoom: 8,
      layers: [
        L.tileLayer(mapURL, {
          className: common.isDarkMode ? "map-tiles" : "",
        }),
      ],
    });

    if (isDataArray) {
      markerArray?.forEach((marker: any) => {
        L.marker(
          [parseFloat(marker.address.lat), parseFloat(marker.address.long)],
          {
            icon: customIcon,
          }
        ).addTo(mapData);
      });
    } else {
      L.marker([map.latlng?.lat, map.latlng?.long], {
        icon: customIcon,
      }).addTo(mapData);
      editableLocation &&
        mapData.on("click", (e: any) => {
          dispatch({
            type: "SET_LNGLAT",
            payload: {
              lat: e.latlng.lat,
              long: e.latlng.lng,
            },
          });
          mapData.setView(e.latlng, mapData.getZoom(), {
            animate: animateRef.current || false,
          });
        });
    }

    //   mapData.zoomControl.remove();
    mapData.touchZoom.disable();
    //   mapData.doubleClickZoom.disable();
    mapData.scrollWheelZoom.disable();
    mapData.boxZoom.disable();
    mapData.keyboard.disable();

    // add weather layer to map and apply style to it
    // const precipitationLayer = L.tileLayer(
    //   "https://maps.openweathermap.org/maps/2.0/weather/layer=temp_new/{z}/{x}/{y}?appid=e20bec4a0cad23d074553b44ea80a0f9"
    // );

    // // add precipitation layer to map
    // precipitationLayer.addTo(mapData);

    // clean up function
    return () => {
      // remove map when component unmounts
      mapData.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [common.isDarkMode, markerArray, map]);

  // useEffect(() => {
  //   markerArray!== undefined && markerArray.map((community: any)=>{
  //     community.members.map((member: any)=>{
  //       console.log('markerArray member ', member);
  //       console.log('markerArray member.lat ', member.lat);
  //       console.log('markerArray parseFloat member.lat ', parseFloat(member.lat));
  //     })
  //     console.log('markerArray community ', community);

  //   })
  // }, [markerArray])

  // const darkMap =
  //   "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png";
  // const lightMap = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

  // const customIcon = new Icon({
  //   iconUrl: iconURL,
  //   iconSize: [38, 38],
  // });

  //   const customIconGroup = (cluster: any) => {
  //     return divIcon({
  //       html: `<div class="cluster-icon"><img class="cluster-img" src="/images/flexygrid/SolarPanel.png"/></div>`,
  //       className: "custom-marker-cluster",
  //       iconSize: point(33, 33, true),
  //       iconUrl: iconGroupURL,
  //     });
  //   };
  // const SetViewOnClick = ({ animateRef }: any) => {
  //   const map = useMapEvent("click", (e) => {
  //     console.log("data", e);
  //     dispatch({
  //       type: "SET_LNGLAT",
  //       payload: {
  //         lat: e.latlng.lat,
  //         long: e.latlng.lng,
  //       },
  //     });
  //     map.setView(e.latlng, map.getZoom(), {
  //       animate: animateRef.current || false,
  //     });
  //   });

  //   return null;
  // };
  const animateRef = useRef(false);
  // const mapRef: any = useRef();
  // useEffect(() => {
  //   const { current = {} }: any = mapRef;
  //   const { leafletElement: map } = current;
  //   if (!map) return;

  //    const control = ELG.Geosearch();
  // }, []);

  return (
    <>
      <Row justify="center" align="middle">
        <Col span={24}>
          {/* {isDataArray ? ( */}
          <div id="map" ref={mapRef} style={{ height: 300 }}>
            {/* <SetViewOnClick animateRef={animateRef} /> */}
            {/* <TileLayer
                //  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url={common.isDarkMode ? lightMap : lightMap}
              /> */}
            {/* {" "}
              {markerArray?.map((marker: any) => {
                return (
                  <Marker
                    key={marker.id}
                    icon={customIcon}
                    position={[
                      parseFloat(marker.address.lat),
                      parseFloat(marker.address.long),
                    ]}
                  >
                    <Popup>{marker.locationName}</Popup>
                  </Marker>
                );
              })}
               */}
          </div>
          {/* ) : ( */}
          {/* <h1>hii</h1> 
            // <MapContainer
            //   center={[map.latlng?.lat, map.latlng?.long]}
            //   zoom={10}
            //   scrollWheelZoom={false}
            //   //ref={mapRef}
            // >
            //   <TileLayer
            //     //  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            //     url={common.isDarkMode ? lightMap : lightMap}
            //   />

            //   <Marker
            //     key={"key"}
            //     icon={customIcon}
            //     position={[map.latlng?.lat, map.latlng?.long]}
            //   ></Marker>
            //   <SetViewOnClick animateRef={animateRef} />
            // </MapContainer>
          // )}*/}
        </Col>
      </Row>
    </>
  );
};
